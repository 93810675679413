import React, { useRef, useState, useEffect } from 'react';

import { animated, useSpring } from 'react-spring';
import moment from 'moment';
import update from 'immutability-helper';

import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Content from 'managers/Content.js';
import { CalloutIndex } from 'structure/Layer.js';
import Demo from 'classes/Demo.js';
import Layer from 'structure/Layer.js';
import PageControl from 'views/PageControl.js';
import PrintContent from 'views/PrintContent.js';
import ProgressBar from 'views/ProgressBar.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

const Panel = ({ children, className, column, headerStyle, index, id, layer, name, onClose, options = {}, style, utils }) => {

    const { buttons, callout, download, hideLastUpdated, loading, maxWidth, onSizeChange, paging, print, removeOverflow, removePadding, rightContent, search, subTitle } = options || {};

    const [animations, setAnimations] = useSpring(() => ({
        top: -50,
        opacity: 0,
        config: { mass: 1, tension: 180, friction: 12 }
    }));
    const contentContainer = useRef(null);
    const [filtersContainer, setFiltersContainer] = useSpring(() => ({
        maxHeight: 0,
        config: { mass: 1, tension: 180, friction: 20 }
    }));
    const [filtersExpanded, setFiltersExpanded] = useState(false);
    const [onHover, setOnHover] = useState(null);
    const [panelState, setPanelState] = useState({
        action: null,
        frame: null
    });
    const [showCallout, setShowCallout] = useState(false);
    const [updated, setUpdated] = useState(moment());

    const onBodyClick = evt => {
        if(evt.target.className.toString().includes('persist-callout')) {
            evt.preventDefault();
            return;
        }
        setShowCallout(false);
    }

    const onDownloadClick = async () => {
        try {
            let url = await download();
            window.open(url);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your downloaded content. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onFiltersClick = () => {
        let { onVisibilityChange } = search.filters;
        setFiltersExpanded(expanded => !expanded);
        if(typeof(onVisibilityChange) === 'function') {
            onVisibilityChange();
        }
    }

    const onFiltersExpandChange = () => {
        document.body.style.overflow = filtersExpanded ? 'hidden' : 'scroll';
        setFiltersContainer({ maxHeight: filtersExpanded ? window.innerHeight / 3 : 0 });
    }

    const onOptionsClick = () => {
        setShowCallout(showCallout => !showCallout);
    }

    const onPanelSizeChange = () => {
        onSizeChange({
            width: contentContainer.current.clientWidth,
            height: contentContainer.current.clientHeight
        })
    }

    const onPrintClick = () => {
        utils.layer.open({
            id: 'print_content',
            Component: PrintContent.bind(this, {
                ...print,
                title: name
            })
        })
    }

    const getButtons = () => {
        let next_buttons = [];
        if(buttons) {
            next_buttons = next_buttons.concat(buttons);
        }
        if(download) {
            next_buttons.push({
                key: 'download',
                title: 'Download',
                style: 'default',
                onClick: onDownloadClick
            })
        }
        if(print) {
            next_buttons.push({
                key: 'print',
                title: 'Print',
                style: 'default',
                onClick: onPrintClick
            })
        }
        return next_buttons;
    }

    const getCalloutButtonItems = () => {
        if(!buttons) {
            return null;
        }
        return buttons.concat([{
            key: 'close',
            title: 'Close',
            style: 'cancel',
            onClick: () => {
                setShowCallout(false);
            }
        }]).map((item, index, items) => {
            return (
                <div
                key={index}
                className={`sheet-item ${window.theme}`}
                onClick={e => {
                    e.preventDefault();
                    setShowCallout(false);
                    if(typeof(item.onClick) === 'function') {
                        item.onClick();
                    }
                }}
                style={{
                    borderTopLeftRadius: index === 0 ? 6 : 0,
                    borderTopRightRadius: index === 0 ? 6 : 0,
                    borderBottomLeftRadius: index === items.length - 1 ? 6 : 0,
                    borderBottomRightRadius: index === items.length - 1 ? 6 : 0,
                    overflow: 'hidden'
                }}>
                    <div
                    className={'persist-callout'}
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: 50,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderTop: index === 0 ? null : `1px solid ${Appearance.colors.divider()}`
                    }}>
                        <span
                        className={'persist-callout'}
                        style={{
                            width: '100%',
                            color: (item.style === 'destructive' || item.style === 'cancel') ? Appearance.colors.text() : Appearance.colors.primary(),
                            fontWeight: '400',
                            fontSize: '13px',
                            textAlign: 'center'
                        }}>{item.title}</span>
                    </div>
                </div>
            )
        })
    }

    const getCalloutButtons = useClicks => {

        if(rightContent) {
            return rightContent;
        }
        if((callout || buttons) && Utils.isMobile()) {
            return (
                <div style={{
                    width: 105
                }}>
                    <AltBadge
                    className={'persist-callout'}
                    onClick={useClicks ? onOptionsClick : null}
                    content={{
                        text: `${showCallout ? 'Hide' : 'Show'} Options`,
                        color: Appearance.colors.grey()
                    }} />
                    {showCallout && (
                        <div style={{
                            ...Appearance.styles.panel(),
                            position: 'absolute',
                            top: 40,
                            right: 0,
                            minWidth: 250,
                            borderRadius: 5,
                            height: 'auto',
                            zIndex: CalloutIndex
                        }}>
                            {callout && (
                                <div
                                className={'persist-callout'}
                                style={{
                                    padding: 15,
                                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                                }}>
                                    {callout}
                                </div>
                            )}
                            {getCalloutButtonItems()}
                        </div>
                    )}
                </div>
            )
        }

        let next_buttons = getButtons();
        if(!next_buttons) {
            return null;
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                {next_buttons.filter(button => {
                    return button.visible !== false;
                }).map((button, index, buttons) => {

                    let color = Appearance.colors.grey();
                    switch(button.style) {
                        case 'default':
                        color = Appearance.colors.primary();
                        break;

                        case 'secondary':
                        color = Appearance.colors.secondary();
                        break;

                        case 'destructive':
                        color = Appearance.colors.red;
                        break;
                    }

                    return (
                        <AltBadge
                        key={index}
                        onClick={button.onClick}
                        content={{
                            text: button.title,
                            color: color
                        }}
                        style={{
                            padding: '5px 16px 5px 16px'
                        }}/>
                    )
                })}
            </div>
        )
    }

    const getUpdatedDate = () => {
        let date = moment(updated);
        if(date.isSame(moment(), 'day')) {
            return date.format('[Today at] h:mma');
        }
        if(date.isSame(moment().subtract(1, 'days'), 'day')) {
            return date.format('[Yesterday at] h:mma');
        }
        return date.format('MMMM Do [at] h:mma');
    }

    const getSearchFields = () => {

        if(!search || !utils) {
            return null;
        }
        let user = utils.user.get();
        let { onChange, placeholder, props } = search;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: 15,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    {typeof(onChange) === 'function' && (
                        <TextField
                        icon={'search'}
                        useDelay={true}
                        placeholder={placeholder || 'Search for something...'}
                        onChange={onChange}
                        containerStyle={{
                            flexGrow: 1
                        }} />
                    )}
                </div>
            </div>
        )
    }

    const runAnimations = async () => {
        try {
            await Utils.sleep(0.2 * index);
            setAnimations({
                top: 0,
                opacity: 1
            })
        } catch(e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        let elements = document.getElementsByClassName('panel');
        Array.from(elements).forEach(el => {
            if(showCallout) {
                el.addEventListener('click', onBodyClick);
                return;
            }
            el.removeEventListener('click', onBodyClick)
        })

    }, [showCallout]);

    useEffect(() => {
        if(contentContainer.current && typeof(onSizeChange) === 'function') {
            onPanelSizeChange();
            window.addEventListener('resize', onPanelSizeChange);
            return () => {
                window.removeEventListener('resize', onPanelSizeChange);
            }
        }
    }, [contentContainer.current]);

    useEffect(() => {
        setUpdated(moment());
    }, [children]);

    useEffect(() => {
        onFiltersExpandChange();
    }, [filtersExpanded]);

    useEffect(() => {
        runAnimations();
    }, []);

    return (
        <animated.div
        className={`panel window ${className || `${column || 'col-12'} p-3`}`}
        style={{
            position: 'relative',
            ...animations
        }}>
            <div style={{
                position: 'relative',
                width: '100%',
                maxWidth: maxWidth,
                paddingTop: name && Utils.isMobile() ? 40 : 0,
                ...style
            }}>
                {/* mobiles panel title */}
                {name && Utils.isMobile() && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 15,
                        right: 15,
                        height: 43,
                        paddingLeft: 12,
                        paddingRight: 12,
                        backgroundColor: Appearance.colors.panelMobileTabBackground(),
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        border: `4px solid ${window.theme === 'dark' ? 'rgba(25,25,25,1)':'white'}`,
                        textAlign: 'center'
                    }}>
                        <span style={{
                            color: Appearance.colors.text(),
                            fontSize: 14,
                            fontWeight: '700',
                            textAlign: 'center',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{name}</span>
                    </div>
                )}

                {/* panel container */}
                <div style={{
                    height: '100%',
                    ...Appearance.styles.panel()
                }}>
                    {/* hide top bar if mobile */}
                    <div style={{
                        ...Appearance.styles.header(),
                        display: Utils.isMobile() || !name ? 'none' : 'block',
                        ...headerStyle
                    }}>
                        <div style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexGrow: 1,
                                alignItems: 'center'
                            }}>
                                <img
                                src={'images/blue-dot-shaded.png'}
                                style={{
                                    width: 30,
                                    height: 30,
                                    objectFit: 'contain',
                                    marginRight: 12
                                }} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        color: Appearance.colors.text(),
                                        fontSize: 16,
                                        fontWeight: 700,
                                        maxWidth: '100%',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        lineHeight: 1.1,
                                        marginBottom: 2
                                    }}>{name}</span>
                                    {!subTitle && (
                                        <span style={{
                                            color: Appearance.colors.subText(),
                                            fontSize: 11,
                                            fontWeight: 600,
                                            maxWidth: '100%',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            lineHeight: 1.1
                                        }}>{`Last Updated: ${getUpdatedDate()}`}</span>
                                    )}
                                    {typeof(subTitle) === 'string' && (
                                        <span style={{
                                            color: Appearance.colors.subText(),
                                            fontSize: 11,
                                            fontWeight: 600,
                                            maxWidth: '100%',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            lineHeight: 1.1
                                        }}>{subTitle}</span>
                                    )}
                                </div>
                            </div>
                            {getCalloutButtons(true)}
                        </div>
                    </div>
                    <div className={'card-body p-0'}>
                        {loading && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0
                            }}>
                                <ProgressBar/>
                            </div>
                        )}
                        {getSearchFields()}
                        <div
                        ref={contentContainer}
                        className={`card-body-content`}
                        style={{
                            padding: removePadding ? 0 : 15,
                            overflowY: removeOverflow === true ? 'visible':'scroll'
                        }}>
                            {children}
                        </div>
                    </div>
                    <PageControl {...paging} />
                </div>
            </div>
        </animated.div>
    )
}
export default Panel;
