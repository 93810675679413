import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import LottieView from 'views/Lottie.js';
import { Spring } from 'react-spring/renderprops';
import Utils from 'files/Utils.js';

const Sidebar = ({ active, children, content, items, onMobileClose, onPress, onSettingsClick, style, user }) => {

    const [openMenus, setOpenMenus] = useState([]);
    const [springToggle, setSpringToggle] = useState(null);

    const getTitle = () => {
        if(user) {
            return user.full_name
        }
    }

    const getSubTitle = () => {
        if(user && user.dealership) {
            return user.dealership.name || 'No Name Provided';
        }
    }

    const getItem = ({ is_active, icon, items, key, onClick, title }) => {
        return (
            <div
            key={key}
            onClick={items ? toggleMenu.bind(this, key) : onClick}
            onMouseEnter={() => setSpringToggle(key)}
            onMouseLeave={() => setSpringToggle(null)}
            className={'cursor-pointer'}
            style={{
                marginBottom: 12,
                paddingLeft: 20,
                paddingRight: Utils.isMobile() ? 20 : 0
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}>
                    <Spring
                    toggle={springToggle === key ? true : false}
                    config={{ mass: 1, tension: 180, friction: 8 }}
                    from={{ transform: 'scale(1)' }}
                    to={{ transform: springToggle === key ? 'scale(1.15)' : 'scale(1.0)' }}>
                    {props => (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 40,
                            height: 40,
                            minWidth: 40,
                            minHeight: 40,
                            padding: 10,
                            borderRadius: 20,
                            overflow: 'hidden',
                            marginRight: 12,
                            backgroundColor: is_active ? Appearance.colors.primary() : Appearance.colors.layerBackground(),
                            boxShadow: '-10px 10px 15px rgba(0,0,0,0.05)',
                            ...props
                        }}>
                            <img
                            src={`images/${icon}${is_active || window.theme === 'dark' ? '-white':''}.png`}
                            style={{
                                objectFit: 'contain',
                                width: '100%',
                                height: '100%'
                            }} />
                        </div>
                    )}
                    </Spring>

                    <span style={{
                        flexGrow: 1,
                        width: '100%',
                        fontWeight: 500,
                        fontSize: 14,
                        color: is_active ? Appearance.colors.primary() : Appearance.colors.text(),
                        paddingRight: 12,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>{title}</span>
                    <div style={{
                        width: 3,
                        height: 25,
                        borderRadius: 1.5,
                        backgroundColor: is_active ? Appearance.colors.primary() : Appearance.colors.transparent,
                        overflow: 'hidden'
                    }} />
                </div>
                {items && openMenus.includes(key) && (
                    <div style={{
                        marginTop: 8,
                        marginLeft: 30,
                        borderRadius: 10,
                        overflow: 'hidden',
                        backgroundColor: Appearance.colors.layerBackground(),
                        boxShadow: '-10px 10px 15px rgba(0,0,0,0.025)'
                    }}>
                        {items.map((item, index) => {
                            return (
                                <a
                                key={item.key}
                                className={`dropdown-item ${window.theme}`}
                                onClick={() => {
                                    setOpenMenus([]);
                                    item.onClick({
                                        action: item.action,
                                        subView: item.key,
                                        view: key
                                    });
                                }}
                                style={{
                                    borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                    fontWeight: 400,
                                    fontSize: 13,
                                    color: item.is_subview_active ? Appearance.colors.primary() : Appearance.colors.text()
                                }}>{item.title}</a>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }

    const toggleMenu = key => {
        if(openMenus.includes(key)) {
            setOpenMenus(openMenus => update(openMenus, {
                $splice: [
                    [openMenus.indexOf(key), 1]
                ]
            }));
            return;
        }
        setOpenMenus(openMenus => update(openMenus, {
            $push: [key]
        }));
    }

    return user ? (
        <div
        key={'sidebar'}
        style={{
            position: 'relative',
            zIndex: 1100,
            backgroundColor: Utils.isMobile() ? Appearance.colors.background() : null
        }}>
            <nav className={'main-navbar navbar align-items-stretch navbar-light flex-md-nowrap p-0 d-lg-none d-md-none d-sm-none d-flex text-left'}>
                <a className={'toggle-sidebar d-sm-inline d-md-none d-lg-none'}>
                    <img
                    src={`images/close-button-${window.theme}-small.png`}
                    onClick={onMobileClose}
                    style={{
                        width: 30,
                        height: 30,
                        objectFit: 'contain'
                    }} />
                </a>
            </nav>
            <div
            className={`nav-wrapper ${window.theme}`}
            style={{
                backgroundColor: Appearance.colors.transparent
            }}>
                <ul className={'nav flex-column py-md-0 d-block'}>
                    <div
                    className={'pt-0 pt-md-3'}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    	paddingTop: 25,
                    	paddingBottom: 25,
                        textAlign: 'center'
                    }}>
                        <LottieView
                        loop={true}
                        autoPlay={true}
                        source={require('files/lottie/logo-desktop.json')}
                        style={{
                            width: 175,
                            height: 175
                        }}/>
                        <span style={{
                            color: Appearance.colors.text(),
                            fontSize: 18,
                            fontWeight: 800
                        }}>{getTitle()}</span>
                        <span style={{
                            color: Appearance.colors.subText(),
                            fontSize: 14,
                            fontWeight: 600
                        }}>{getSubTitle()}</span>
                    </div>
                    {Object.values(content).map((item, index) => {
                        if(item.subViews) {

                            // Show dropdown if more than 1 visible option
                            let items = Object.values(item.subViews);
                            if(items.length >= 1) {
                                return getItem({
                                    ...item,
                                    is_active: active && item.key === active.view,
                                    items: items.map((subView, index) => {
                                        return {
                                            key: subView.key,
                                            title: subView.title,
                                            action: subView.action,
                                            onClick: onPress,
                                            is_subview_active: active && item.key === active.view && active.subView === subView.key,
                                        }
                                    })
                                })
                            }

                            // Hide dropdown if only one option
                            return items[0].panels.length > 0 ? getItem({
                                key: items[0].key,
                                title: item.title,
                                icon: item.icon,
                                is_active: active && active.view === item.key,
                                onClick: onPress.bind(this, {
                                    view: item.key,
                                    subView: items[0].key,
                                    action: item.action
                                })
                            }) : null
                        }

                        return item.panels.length > 0 ? getItem({
                            key: item.key,
                            title: item.title,
                            icon: item.icon,
                            is_active: active && active.view === item.key,
                            onClick: onPress.bind(this, {
                                view: item.key,
                                action: item.action
                            })
                        }) : null
                    })}
                </ul>
                {children}
            </div>
        </div>
    ) : null
}
export default Sidebar;
