import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import update from 'immutability-helper';

import AddressLookupField from 'views/AddressLookupField.js';
import Appearance from 'styles/Appearance';
import Calendar from 'views/Calendar.js';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

const Alert = ({ id, buttons, component, icon, index, message, onClick, onClose, textFields, title, utils }) => {

    const [visible, setVisible] = useState(false);
    const [scale, setScale] = useState(0);
    const [opacity, setOpacity] = useState(0);
    const [alertButtons, setAlertButtons] = useState([]);
    const [alertTextFields, setAlertTextFields] = useState(false);
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const onButtonClick = key => {
        onHideAlert(() => {
            if(alertTextFields) {
                onParseTextFields(key);
                return;
            }
            if(typeof(onClick) === 'function') {
                onClick(key);
            }
        });
    }

    const onGeneralKeyDown = evt => {
        if(evt.keyCode === 27) {
            onHideAlert();
        }
    }

    const onTextFieldKeyDown = evt => {
        if(evt.keyCode === 13) {
            evt.preventDefault();
            onHideAlert(() => {
                onParseTextFields()
            });
        }
    }

    const onHideAlert = callback => {
        setScale(0);
        setOpacity(0);
        setTimeout(() => {
            setVisible(false);
            if(typeof(onClose) === 'function') {
                onClose(id);
            }
            if(typeof(callback) === 'function') {
                callback();
            }
        }, 250);
    }

    const onParseTextFields = key => {
        if(typeof(onClose) === 'function') {
            onClose(id);
        }
        if(key !== 'cancel') {
            if(typeof(onClick) === 'function') {
                let textFieldValues = {};
                alertTextFields.forEach(t => {
                    textFieldValues[t.field.key] = t.value;
                })
                onClick(textFieldValues);
            }
        }
    }

    const onWindowSizeChange = (e) => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    const getWidth = () => {
        if(component) {
            return size.width > 400 ? 400 : (size.width - 30);
        }
        if(alertTextFields && alertTextFields.length > 0) {
            return size.width > 400 ? 400 : (size.width - 30);
        }
        return size.width > 300 ? 300 : (size.width - 30);
    }

    useEffect(() => {
        setAlertTextFields(textFields ? textFields.map(t => {
            return {
                value: null,
                field: t
            }
        }) : null);
    }, [textFields]);

    useEffect(() => {
        setVisible(true);
        setTimeout(() => {
            setScale(index > 3 ? 0 : 1 - (index / 4));
            setOpacity(index === 0 ? 1 : 0);
        }, 250)
    }, [index])

    useEffect(() => {

        // add at least one buttons if no buttons are provided
        let next_buttons = buttons || [{
            key: 'cancel',
            title: 'Okay',
            style: 'cancel'
        }];
        setAlertButtons(next_buttons.filter(button => {
            return button.visible !== false;
        }));

        // add resize listener
        // add keydown listener for escape key to close alert if there is a dismiss/cancel button
        window.addEventListener('resize', onWindowSizeChange);
        if(next_buttons.find(button => button.key === 'cancel')) {
            document.addEventListener('keydown', onGeneralKeyDown);
        }

        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
            document.removeEventListener('keydown', onGeneralKeyDown);
        }
    }, []);

    useEffect(() => {
        // add keydown listener if textfields are present
        // this allows the user to press enter to confirm the data from the textfield
        if(alertTextFields) {
            document.addEventListener('keydown', onTextFieldKeyDown);
            return () => {
                document.removeEventListener('keydown', onTextFieldKeyDown);
            }
        }
    }, [alertTextFields])

    return visible ? (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <VelocityComponent
            easing={[250, 20]}
            duration={500}
            animation={{
                opacity: opacity,
                scale: scale
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.alert(),
                    overflow: 'hidden',
                    maxHeight: size.height - 30,
                    width: getWidth(),
                    alignItems: 'center',
                    textAlign: 'center',
                    zIndex: 9999
                }}>
                    {icon && typeof(icon) === 'object' && (
                        <div style={{
                            marginTop: 15,
                            borderRadius: 25,
                            backgroundColor: icon.background_color || Appearance.colors.primary(),
                            overflow: 'hidden',
                            width: 50,
                            height: 50,
                            border: '3px solid white',
                            boxShadow: window.theme === 'dark' ? '3px 3px 15px rgba(0,0,0,0.25), -3px -3px 15px rgba(255,255,255,0.25)' : '3px 3px 15px #c9c8ca, -3px -3px 15px #ffffff',
                            ...icon.style
                        }}>
                            <img
                            src={icon.path}
                            style={{
                                width: '100%',
                                height: '100%',
                                resizeMode: 'cover'
                            }}/>
                        </div>
                    )}
                    <span style={{
                        display: 'block',
                        color: Appearance.colors.text(),
                        fontSize: 14,
                        fontWeight: '500',
                        paddingTop: icon ? 8:15,
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginBottom: 8
                    }}>{title}</span>

                    <div style={{
                        marginBottom: 15,
                        maxHeight: 500,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        maxWidth: '100%'
                    }}>
                        <span style={{
                            display: 'block',
                            maxWidth: '100%',
                            color: Appearance.colors.subText(),
                            fontSize: 12,
                            fontWeight: '400',
                            paddingLeft: 15,
                            paddingRight: 15
                        }}>{message}</span>
                    </div>

                    {component}
                    {alertTextFields && (
                        <div style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            width: '100%',
                            marginBottom: 15
                        }}>
                            {alertTextFields.map((item, index) => {
                                if(item.field.type === 'address_lookup') {
                                    return (
                                        <AddressLookupField
                                        key={index}
                                        utils={utils}
                                        useDelay={false}
                                        geocode={true}
                                        onChange={place => {
                                            setAlertTextFields(alertTextFields => update(alertTextFields, {
                                                [index]: {
                                                    value: {
                                                        $set: place
                                                    }
                                                }
                                            }))
                                        }} />
                                    )
                                }
                                if(item.field.type === 'date_picker') {
                                    return (
                                        <DateDurationPickerField
                                        key={index}
                                        utils={utils}
                                        overrideAlerts={true}
                                        placeholder={'Choose a date...'}
                                        onChange={date => {
                                            setAlertTextFields(alertTextFields => update(alertTextFields, {
                                                [index]: {
                                                    value: {
                                                        $set: date
                                                    }
                                                }
                                            }))
                                        }} />
                                    )
                                }
                                if(item.field.type === 'list') {
                                    return (
                                        <select
                                        key={index}
                                        className={`custom-select ${window.theme}`}
                                        onChange={e => {
                                            let id = Utils.attributeForKey.select(e, 'id');
                                            let selected = item.field.items.find(prevItem => prevItem.key === parseInt(id));
                                            setAlertTextFields(alertTextFields => update(alertTextFields, {
                                                [index]: {
                                                    value: {
                                                        $set: selected
                                                    }
                                                }
                                            }))
                                        }}>
                                            <option>{'Choose from the list below'}</option>
                                            {item.field.items.map((item, index) => {
                                                return (
                                                    <option key={index} id={item.key}>{item.title}</option>
                                                )
                                            })}
                                        </select>
                                    )
                                }
                                return (
                                    <TextField
                                    key={index}
                                    value={item.field.value}
                                    useDelay={false}
                                    isSecure={item.field.secure}
                                    placeholder={item.field.placeholder}
                                    fieldStyle={{
                                        textAlign: 'center'
                                    }}
                                    onChange={text => {
                                        setAlertTextFields(alertTextFields => update(alertTextFields, {
                                            [index]: {
                                                value: {
                                                    $set: text
                                                }
                                            }
                                        }))
                                    }} />
                                )
                            })}
                        </div>
                    )}

                    {alertButtons.map(item => {
                        return (
                            <div
                            key={item.key}
                            className={`alert-item ${window.theme}`}
                            onClick={() => onButtonClick(item.key)}
                            style={{
                                height: 50,
                                width: '100%'
                            }}>
                                <div style={{
                                    height: 50
                                }}>
                                    <div style={{
                                        width: '100%',
                                        height: 1,
                                        backgroundColor: Appearance.colors.divider()
                                    }}/>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 50
                                    }}>
                                        <span style={{
                                            fontSize: '13px',
                                            fontWeight: '400',
                                            textAlign:'center',
                                            color: (item.style == 'cancel' || item.style == 'destructive') ? Appearance.colors.text() : Appearance.colors.primary()
                                        }}>{item.title}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </VelocityComponent>
        </div>
    ) : null
}

export default Alert;
