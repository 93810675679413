import React from 'react';

const URLHelper = {
    getRequest: (url, props) => {
        let newURL = `${url}?type=${props.type}`;
        for(var i in props) {
            if(i == 'type') {
                continue;
            }
            newURL += `&${i}=${typeof(props[i]) === 'object' ? JSON.stringify(props[i]) : encodeURIComponent(props[i])}`;
        }
        return newURL;
    }
}
export default URLHelper;
