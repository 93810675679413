export const dev_env = false;
export const version = 1;
export const server = dev_env ? 'http://globaldata.local.192.168.7.37.nip.io' : 'https://globaldata.link';

export default {
    dev_env: dev_env,
    version: version,
    server: server,
    url: `${server}/api/v${version}`,
    sockets: dev_env ? `${server}:4000` : `https://sockets.globaldata.link`,
    website: 'https://ghscompanies.com',
    mapbox: 'pk.eyJ1IjoiZ2hzLXJpZGEiLCJhIjoiY2tpcTU0ZW9hMGQzYTJybXYxcGU5NHRrdSJ9.-KPLGweAXcpYfrJJoUB5NA',
    stripe: dev_env ? 'pk_test_51IXdy9G6RZW42dLL51H4CKaukROsRhXUgs9AnLmQgJOiE3R6ZK8LPM7inn6KUBuHET0o8PVhkDNAHyD1Zgtna67a00N5YaAIrD' : 'pk_live_51IXdy9G6RZW42dLLNWUYUavOUd7i4I8XmTwO819lk1cvXHLsQYPEz8nPQHqa0FE9HFiEWLJeP8PRyGtYpRgwaN5g00YlzPewN3'
}
