import React, { useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';
import { VelocityComponent } from 'velocity-react';

const Sheet = ({ title, message, items, onClick, onClose }) => {

    const [sheetItems, setSheetItems] = useState([]);
    const [opacity, setOpacity] = useState(0);
    const [bottom, setBottom] = useState(-500);
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerWidth
    })

    const onWindowSizeChange = (e) => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    useEffect(() => {

        if(!items.find(item => item.key === 'cancel')) {
            items.push({
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            })
        }
        if(items.filter(i => i.visible !== false).length === 0) {
            if(onClose && typeof(onClose) === 'function') {
                onClose();
            }
        }

        setSheetItems(items.filter(i => i.visible !== false));
        setTimeout(() => {
            setOpacity(1);
            setBottom(15);
        }, 250)

        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
        }

    }, [])

    const onItemClick = (key) => {
        closeSheet(() => {
            if(typeof(onClose) === 'function') {
                onClose();
            }
            if(typeof(onClick) === 'function') {
                onClick(key);
            }
        });
    }

    const closeSheetTap = () => {
        closeSheet(() => {
            if(typeof(onClose) === 'function') {
                onClose();
            }
        });
    }

    const closeSheet = (callback) => {
        setOpacity(0);
        setBottom(-500);
        if(typeof(callback) === 'function') {
            setTimeout(() => {
                callback();
            }, 500)
        }
    }

    const getColor = item => {
        if(item.style === 'destructive') {
            return Appearance.colors.red;
        }
        if(item.style === 'cancel') {
            return Appearance.colors.text();
        }
        return Appearance.colors.primary();
    }

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9500,
            display: 'flex',
            justifyContent: 'center',

        }}>
            <VelocityComponent animation={{
                opacity: opacity
            }} duration={500}>
                <div onClick={ closeSheetTap }
                    style={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0
                    }}>
                    <div style={{
                        backgroundColor: Appearance.colors.dim,
                        width: '100%',
                        height: '100%'
                    }} />
                </div>
            </VelocityComponent>

            <VelocityComponent
                easing={[250, 20]}
                duration={750}
                animation={{
                    bottom: bottom
                }}>
                <div style={{
                    position: 'fixed',
                    width: size.width > 300 ? 300 : (size.width - 30),
                    borderRadius: 10,
                    overflow: 'hidden',
                    backgroundColor: Appearance.colors.sheet(),
                    textAlign:'center',
                    zIndex: 9999
                }}>
                    <div style={{
                        width: '100%'
                    }}>
                    {title || message
                        ?
                        <div style={{
                            padding: 15,
                            borderBottomWidth: 1,
                            borderBottomColor: Appearance.colors.divider()
                        }}>
                            {title
                                ?
                                <span style={{
                                    display: 'block',
                                    color: Appearance.colors.text(),
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    marginBottom: message ? 4:0
                                }}>{title}</span>
                                :
                                null
                            }
                            {message
                                ?
                                <span style={{
                                    display: 'block',
                                    color: Appearance.colors.subText(),
                                    fontSize: '12px',
                                    fontWeight: '400'
                                }}>{message}</span>
                                :
                                null
                            }
                        </div>
                        :
                        null}
                    {sheetItems.filter(i => i.visible !== false).map(item => {
                        return (
                            <div key={item.key}
                            className={'sheet-item ' + window.theme}
                            onClick={onItemClick.bind(this, item.key)}>
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '50px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderTop: '1px solid ' + Appearance.colors.divider()}}>

                                    <span style={{
                                        width: '100%',
                                        color: getColor(item),
                                        fontWeight: '400',
                                        fontSize: '13px',
                                        textAlign: 'center'
                                    }}>{item.title}</span>
                                </div>
                            </div>
                        );
                    })}
                    </div>
                </div>
            </VelocityComponent>
         </div>
    )
}
export default Sheet;
