import React from 'react';
import moment from 'moment-timezone';

import Abstract from 'classes/Abstract.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class CallLogClass {

    id = null;
    lead_id = null;
    lead_full_name = null; // lookup field
    start_date = null;
    end_date = null;
    author = null;
    method = null;
    follow_up_start_date = null;
    follow_up_end_date = null;
    complete = null;
    direction = null;
    notes = null;
    assign_to = null;
    created = null;
    created_by = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.lead_id = props.lead_id;
        this.lead_full_name = props.lead_full_name;
        this.start_date = props.start_date ? moment(props.start_date) : null;
        this.end_date = props.end_date ? moment(props.end_date) : null;
        this.author = props.author ? User.create(props.author) : null;
        this.method = props.method;
        this.follow_up_start_date = props.follow_up_start_date ? moment(props.follow_up_start_date) : null;
        this.follow_up_end_date = props.follow_up_end_date ? moment(props.follow_up_end_date) : null;
        this.complete = props.complete;
        this.direction = props.direction;
        this.notes = props.notes;
        this.assign_to = props.assign_to ? User.create(props.assign_to) : null;
        this.created = props.created;
        this.created_by = props.created_by;
        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                start_date: this.start_date,
                end_date: this.end_date,
                method: this.method,
                follow_up_start_date: this.follow_up_start_date,
                follow_up_end_date: this.follow_up_end_date,
                complete: this.complete,
                direction: this.direction,
                assign_to: this.assign_to,
                notes: this.notes
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            if(props.start_date && this.edits.end_date) {
                let seconds = moment(this.edits.end_date).unix() - moment(this.edits.start_date).unix();
                props.end_date = moment(props.start_date).add(seconds, 'seconds');
            }
            if(props.follow_up_start_date && this.edits.follow_up_end_date) {
                let seconds = moment(this.edits.follow_up_end_date).unix() - moment(this.edits.follow_up_start_date).unix();
                props.follow_up_end_date = moment(props.follow_up_start_date).add(seconds, 'seconds');
            }
            this.edits = {
                start_date: props.start_date || this.edits.start_date,
                end_date: props.end_date || this.edits.end_date,
                method: props.method || this.edits.method,
                follow_up_start_date: props.follow_up_start_date || this.edits.follow_up_start_date,
                follow_up_end_date: props.follow_up_end_date || this.edits.follow_up_end_date,
                complete: typeof(props.complete) === 'bool' ? props.complete : this.edits.complete,
                direction: props.direction || this.edits.direction,
                assign_to: props.assign_to || this.edits.assign_to,
                notes: props.notes || this.edits.notes
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.start_date = this.edits.start_date || this.start_date;
            this.end_date = this.edits.end_date || this.end_date;
            this.method = this.edits.method || this.method;
            this.follow_up_start_date = this.edits.follow_up_start_date || this.follow_up_start_date;
            this.follow_up_end_date = this.edits.follow_up_end_date || this.follow_up_end_date;
            this.complete = this.edits.complete || this.complete;
            this.direction = this.edits.direction || this.direction;
            this.assign_to = this.edits.assign_to || this.assign_to;
            this.notes = this.edits.notes || this.notes;
            resolve();
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/dealerships/', {
                    type: 'new_call_log',
                    ...this.toJSON(this.edits),
                    lead_id: this.lead.id,
                    author: utils.user.get().user_id
                })

                this.id = id;
                await this.close();
                utils.content.fetch('call_log');

                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/dealerships/', {
                    type: 'update_call_log',
                    ...this.toJSON(this.edits)
                })

                await this.close();
                utils.content.update(Abstract.create({
                    type: 'call_log',
                    object: this
                }));
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            id: this.id,
            end_date: target.end_date ? moment(target.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
            start_date: target.start_date ? moment(target.start_date).format('YYYY-MM-DD HH:mm:ss') : null,
            author: this.author ? this.author.user_id : null,
            method: target.method,
            follow_up_start_date: target.follow_up_start_date ? moment(target.follow_up_start_date).format('YYYY-MM-DD HH:mm:ss') : null,
            follow_up_end_date: target.follow_up_end_date ? moment(target.follow_up_end_date).format('YYYY-MM-DD HH:mm:ss') : null,
            complete: target.complete,
            direction: target.direction,
            notes: target.notes,
            assign_to: target.assign_to ? target.assign_to.user_id : null
        }
    }
}

export default {
    new: () => new CallLogClass(),
    create: props => new CallLogClass().create(props)
}
