import React from 'react'
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

class UserClass {

    user_id = null;
    username = null;
    dealership = null;
    token = null;
    date = null;
    last_login = null;
    first_name = null;
    last_name = null;
    full_name = null;
    spouse = null;
    level = null;
    address = null;
    location = null;
    email_address = null;
    phone_number = null;
    avatar = null;
    next_demo = null;
    timezone = moment.tz.guess();
    active = true;

    constructor() {
        return this;
    }

	create = (props = {}) => {

		this.user_id = props.user_id;
        this.dealership_id = props.dealership_id;
        this.dealership = props.dealership ? Dealership.create(props.dealership) : null;
		this.username = props.username;
		this.password = props.password;
		this.token = props.token;
		this.date = props.date;
        this.last_login = props.last_login;
		this.first_name = props.first_name;
		this.last_name = props.last_name;
		this.full_name = props.full_name;
        this.spouse = props.spouse;
        this.level = props.level;
		this.address = props.address;
        this.location = props.location ? {
            latitude: props.location.lat,
            longitude: props.location.long
        } : null;
        this.email_address = props.email_address;
		this.phone_number = props.phone_number;
        this.next_demo = props.next_demo ? moment(props.next_demo) : null;
        this.timezone = props.timezone;
		this.avatar = props.avatar;

        return this;
	}

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                username: this.username,
                password: this.password,
                dealership: this.dealership,
                first_name: this.first_name,
                last_name: this.last_name,
                full_name: this.full_name,
                spouse: this.spouse,
                level: this.level,
                location: this.location,
                address: this.address,
                email_address: this.email_address,
                phone_number: this.phone_number,
                level: this.level,
                timezone: this.timezone
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                username: props.username || this.edits.username,
                password: props.password || this.edits.password,
                dealership: props.dealership || this.edits.dealership,
                first_name: props.first_name || this.edits.first_name,
                last_name: props.last_name || this.edits.last_name,
                full_name: `${props.first_name || this.edits.first_name || ''} ${props.last_name || this.edits.last_name || ''}`,
                spouse: props.spouse !== undefined ? props.spouse : this.edits.spouse,
                level: props.level || this.edits.level,
                location: props.address ? {
                    latitude: props.address.location.latitude,
                    longitude: props.address.location.longitude
                } : this.edits.location,
                address: props.address ? {
                    address: props.address.address,
                    city: props.address.city,
                    state: props.address.state,
                    zipcode: props.address.zipcode,
                    country: props.address.country
                } : this.edits.address,
                email_address: props.email_address || this.edits.email_address,
                phone_number: props.phone_number || this.edits.phone_number,
                level: props.level || this.edits.level,
                timezone: props.timezone || this.edits.timezone,
                avatar: props.image ? props.image.data : this.edits.avatar,
                tmpImage: props.image ? props.image : this.edits.tmpImage
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            let target = this.edits || this;
            this.username = target.username || this.username;
            this.dealership = target.dealership || this.dealership;
            this.first_name = target.first_name || this.first_name;
            this.last_name = target.last_name || this.last_name;
            this.full_name = `${target.first_name} ${target.last_name}`;
            this.spouse = target.spouse || this.spouse;
            this.level = target.level || this.level;
            this.location = target.location || this.location;
            this.address = target.address || this.address;
            this.email_address = target.email_address || this.email_address;
            this.phone_number = target.phone_number || this.phone_number;
            this.level = target.level || this.level;
            this.timezone = target.timezone || this.timezone;
            this.avatar = target.avatar || this.avatar;
            resolve(this);
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { user_id } = await Request.post(utils, '/users/', {
                    type: 'new',
                    ...this.toJSON(this.edits)
                });
                await this.close();
                this.user_id = user_id;
                utils.content.fetch('user');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let fetch = this.level !== this.edits.level;
                await Request.post(utils, '/users/', {
                    type: 'update',
                    user_id: this.user_id,
                    ...this.toJSON(this.edits)
                });
                await this.close();

                if(fetch) {
                    utils.content.fetch('user');
                    resolve();
                    return;
                }

                utils.content.update(Abstract.create({
                    type: 'user',
                    object: this
                }));
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            username: target.username,
            password: target.password,
            dealership_id: target.dealership ? target.dealership.id : null,
            first_name: target.first_name,
            last_name: target.last_name,
            full_name: target.full_name,
            spouse: target.spouse ? target.spouse.user_id : null,
            level: target.level,
            address: target.address,
            location: target.location ? {
                lat: target.location.latitude,
                long: target.location.longitude
            } : null,
            email_address: target.email_address,
            phone_number: target.phone_number,
            level: target.level,
            timezone: target.timezone
        }
    }
}

export const fetchUser = async (utils, userID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { user } = await Request.get(utils, '/users/', {
                type: 'details',
                user_id: userID
            });
            resolve(new UserClass().create(user));
        } catch(e){
            reject(e);
        }
    })
}

class UserGroupClass {

    id = null;
    dealership = null;
    title = null;
    description = null;
    date = null;
    group_type = null;
    category = null;
    user_ids = null;
    users = []; // internal use only
    levels = [];
    props = {};
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.dealership = props.dealership ? Dealership.create(props.dealership) : null;
        this.title = props.title;
        this.description = props.description;
        this.date = props.date;
        this.group_type = props.group_type;
        this.category = props.category;
        this.user_ids = props.user_ids;
        this.levels = props.levels;
        this.props = props.props;
        this.active = props.active;
        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                dealership: this.dealership,
                title: this.title,
                description: this.description,
                group_type: this.group_type,
                category: this.category,
                users: this.users,
                levels: this.levels || [],
                props: this.props || {}
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        console.log(props);
        return new Promise(resolve => {
            this.edits = {
                dealership: props.dealership || this.edits.dealership,
                title: props.title || this.edits.title,
                description: props.description || this.edits.description,
                group_type: props.group_type || this.edits.group_type,
                category: props.category || this.edits.category,
                users: props.users || this.edits.users,
                levels: props.levels || this.edits.levels,
                props: props.props || this.edits.props
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            let target = this.edits || this;
            this.dealership = target.dealership || this.dealership;
            this.title = target.title || this.title;
            this.description = target.description || this.description;
            this.group_type = target.group_type || this.group_type;
            this.category = target.category || this.category;
            this.users = target.users || this.users;
            this.levels = target.levels || this.levels;
            this.props = target.props || this.props;
            resolve(this);
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { category, id } = await Request.post(utils, '/users/', {
                    type: 'new_group',
                    ...this.toJSON(this.edits)
                });
                await this.close();
                this.id = id;
                this.category = category;
                utils.content.fetch('user_group');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/users/', {
                    type: 'update_group',
                    id: this.id,
                    ...this.toJSON(this.edits)
                });
                await this.close();

                utils.content.update(Abstract.create({
                    type: 'user_group',
                    object: this
                }));
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            dealership_id: target.dealership ? target.dealership.id : null,
            title: target.title,
            description: target.description,
            group_type: target.group_type ? target.group_type.code : null,
            category: this.id ? target.category.code : target.category,
            user_ids: target.users ? target.users.map(user => user.user_id) : target.user_ids,
            levels: target.levels,
            props: target.props
        }
    }
}

const levels = {
    system_admin: 1,
    admin: 2,
    region_director: 3000,
    division_director: 3250,
    area_director: 3500,
    dealer: 4000,
    booking_coordinator: 4250,
    marketing_director: 4251,
    safety_advisor: 5000,
    safety_associate: 7000,
    customer: 9999
}

export default {
    new: () => new UserClass(),
    get: (utils, userID) => fetchUser(utils, userID),
    create: props => new UserClass().create(props),
    Group: {
        new: () => new UserGroupClass(),
        create: props => new UserGroupClass().create(props),
        types: {
            levels: 1,
            user_ids: 2
        },
        categories: {
            demos: 1,
            demo_requests: 2,
            leads: 3,
            users: 4
        }
    },
    level: levels,
    utils: {
        getLevelText: code => {
            switch(code) {
                case levels.system_admin:
                return 'System Admin';

                case levels.admin:
                return 'Admin';

                case levels.region_director:
                return 'Region Director';

                case levels.division_director:
                return 'Division Director';

                case levels.area_director:
                return 'Area Director';

                case levels.dealer:
                return 'Dealer';

                case levels.booking_coordinator:
                return 'Booking Coordinator';

                case levels.marketing_director:
                return 'Marketing Director';

                case levels.safety_advisor:
                return 'Safety Advisor';

                case levels.safety_associate:
                return 'Safety Associate';

                case levels.customer:
                return 'Customer';

                default:
                return 'Unknown account type';
            }
        }
    }
};
