import React, { useRef, useState, useEffect } from 'react';
import 'styles/main.css';

import { getContent } from 'files/Content.js';
import moment from 'moment-timezone';
import update from 'immutability-helper';
import smoothscroll from 'smoothscroll-polyfill';
import { useHistory } from 'react-router-dom';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import Alert from 'views/Alert.js';
import AlertStack from 'views/AlertStack.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Content from 'managers/Content.js';
import Cookies from 'js-cookie';
import DatePicker, { DualDatePicker } from 'views/DatePicker.js';
import Demo from 'classes/Demo.js';
import DemoRequest from 'classes/DemoRequest.js';
import DesktopNotification from 'views/DesktopNotification.js';
import Events from 'managers/Events.js';
import Layer, { EndIndex, FrontIndex } from 'structure/Layer.js';
import Loader from 'views/Loader.js';
import Login from 'views/Login.js';
import Notification from 'classes/Notification.js';
import NotificationCenter from 'files/NotificationCenter.js';
import Panel from 'structure/Panel.js';
import Lead from 'classes/Lead.js';
import QueryString from 'query-string';
import Request from 'files/Request.js';
import RightSidebar from 'structure/RightSidebar.js';
import Sheet from 'views/Sheet.js';
import Sidebar from 'structure/Sidebar.js';
import Sockets from 'managers/Sockets.js';
import SocketIO from 'socket.io-client';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';
import Views from 'views/Main.js';

const PLATFORM_NAME = 'Global Data';
const ContentManager = Content.new();
const SocketManager = Sockets.new();
const EventManager = Events.new();

const App = () => {

    const groupsRef = useRef(null);
    const history = useHistory();
    const layersRef = useRef(null);
    const programRef = useRef(null);
    const userRef = useRef(null);

    const [active, setActive] = useState({ view: null });
    const [activeProgram, setActiveProgram] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [container, setContainer] = useState({ top: 0, opacity: 1 });
    const [content, setContent] = useState({});
    const [datePicker, setDatePicker] = useState(null);
    const [groups, setGroups] = useState(null);
    const [layerIndex, setLayerIndex] = useState([]);
    const [layers, setLayers] = useState([]);
    const [loader, setLoader] = useState(null);
    const [nonce, setNonce] = useState(moment().unix());
    const [notification, setNotification] = useState(null);
    const [panels, setPanels] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [sheet, setSheet] = useState(null);
    const [sidebar, setSidebar] = useState(-500);
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [theme, setTheme] = useState('light');
    const [user, setUser] = useState(null);

    const utils = {
        alert: {
            show: async props => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            ...props
                        }]
                    }))
                } catch(e) {
                    console.log(e.message)
                }
            },
            showAsync: async props => {
                return new Promise(async (resolve, reject) => {
                    try {
                        if(loader) {
                            await utils.loader.hide();
                        }
                        setAlerts(alerts => update(alerts, {
                            $push: [{
                                id: `${moment().unix()}-${Math.random()}`,
                                onClose: resolve,
                                ...props
                            }]
                        }))
                    } catch(e) {
                        reject(e.message)
                    }
                })
            },
            dev: async props => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            title: 'In Development',
                            message: 'This feature is currently under development and will become available at a later date'
                        }]
                    }))

                } catch(e) {
                    console.log(e.message)
                }
            },
        },
        api: {
            headers: () => {
                return {
                    'Content-Type': 'application/json',
                    'X-Timezone': `TZ ${moment.tz.guess()}`,
                    'X-API': `Version ${API.version}`,
                    ...(userRef.current ? {
                        'Identification': `User ${userRef.current.user_id}`,
                        'Authorization': `Bearer ${userRef.current.token}`
                    } : null)
                }
            }
        },
        content: ContentManager,
        datePicker: {
            show: props => setDatePicker({
                utils: utils,
                id: `${moment().unix()}-${Math.random()}`,
                ...props
            }),
            showDual: props => {
                utils.layer.open({
                    id: 'dual_date_picker_alert',
                    Component: DualDatePicker.bind(this, {
                        ...props,
                        utils: utils
                    })
                })
            }
        },
        dealership: {
            get: () => userRef.current ? userRef.current.dealership : null
        },
        events: EventManager,
        groups: {
            get: () => groupsRef.current,
            apply: (key, group, value) => {
                if(Array.isArray(key)) {
                    for(var i in key) {
                        if(utils.groups.check(group, key[i]) === false) {
                            return getRestrictedText();
                        }
                    }
                    return value;
                }
                if(utils.groups.check(group, key) === false) {
                    return getRestrictedText();
                }
                return value;
            },
            check: (category, type) => {
                if(!groupsRef.current || groupsRef.current.length === 0) {
                    return true;
                }
                let matches = groupsRef.current.filter(prevGroup => prevGroup.category.code === category);
                for(var i in matches) {
                    if(matches[i].props[type] === false) {
                        return false;
                    }
                }
                return true;
            }
        },
        layer: {
            open: layer => onOpenLayer(layer),
            close: layer => onCloseLayer(layer),
            requestClose: id => {
                let closeEvent = new CustomEvent('onLayerAction', {
                    detail: {
                        action: 'close',
                        layerID: id
                    }
                });
                window.dispatchEvent(closeEvent);
            }
        },
        loader: {
            show: async () => {
                return new Promise(resolve => {
                    setLoader(true);
                    setTimeout(resolve, 500)
                })
            },
            hide: async () => {
                return new Promise(resolve => {
                    setLoader(false);
                    setTimeout(resolve, 500)
                })
            }
        },
        notification: {
            show: props => {
                ContentManager.fetch('notification');
                NotificationCenter.notify(utils, props);
                setNotification(props);
            }
        },
        program: {
            get: () => programRef.current,
            set: program => setActiveProgram(program)
        },
        sheet: {
            show: (sheet, callback) => {
                setSheet({ ...sheet, onClick: callback })
            }
        },
        sockets: SocketManager,
        user: {
            get: () => userRef.current
        }
    }

    const onCloseLayer = layerID => {

        setLayerIndex(layerIndex => update(layerIndex, {
            $apply: ids => ids.filter(id => id !== layerID)
        }))
        setLayers(layers => {
            let updatedLayers = update(layers, {
                $apply: layers => layers.map(layer => {
                    if(layer.id === layerID) {
                        layer.visible = false;
                    }
                    return layer;
                })
            });
            let remainingLayers = updatedLayers.filter(layer => {
                return layer.visible !== false
            });
            if(remainingLayers.length === 0) {
                console.log('layers reset');
                return [];
            }
            return updatedLayers;
        })
    }

    const onLayerReposition = ({ id, position }) => {

        let index = layers.findIndex(layer => id === layer.id);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers => update(layers, {
            [index]: {
                position: {
                    $set: position
                }
            }
        }))
    }

    const onLogin = async ({ groups, programs, user }) => {
        try {
            if(!programs) {
                await utils.loader.hide();
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'There was an issue during your account setup. Please speak with your Dealer and have them assign you to a Program',
                    onClick: () => window.location.reload()
                })
                return;
            }

            await utils.loader.show();
            setUser(user);
            setGroups(groups);
            setPrograms(programs);
            setActiveProgram(programs.length > 0 ? programs[0] : null);

            // setup sockets and listeners
            await Utils.sleep(2);
            await SocketManager.connect(utils, user);

            // dealership preference change listeners
            await SocketManager.persistOn('dealerships', `on_update_preferences_${user.dealership.id}`, onUpdateDealershipPreferences);

            // demo change listeners
            await SocketManager.persistOn('demos', 'on_new_demo', onNewDemo);
            await SocketManager.persistOn('demos', 'on_demo_status_change', onUpdateDemo);
            await SocketManager.persistOn('demos', 'on_update_demo', onUpdateDemo);

            // demo request change listeners
            await SocketManager.persistOn('demos', 'on_new_demo_request', onNewDemoRequest);
            await SocketManager.persistOn('demos', 'on_demo_request_status_change', onUpdateDemoRequest);
            await SocketManager.persistOn('demos', 'on_update_demo_request', onUpdateDemoRequest);

            // notifications for specific user and account types
            await SocketManager.persistOn('notifications', `on_notification_${user.user_id}`, onNotification);
            await SocketManager.persistOn('notifications', `on_notification_level_${user.level}`, onNotification);

            // lead change listeners
            await SocketManager.persistOn('leads', 'on_new_lead', onNewLead);
            await SocketManager.persistOn('leads', 'on_lead_status_change', onUpdateLead);
            await SocketManager.persistOn('leads', 'on_update_lead', onUpdateLead);

            // user groups by on user id
            await SocketManager.persistOn('users', `on_new_group_${user.user_id}`, onNewGroup);
            await SocketManager.persistOn('users', `on_update_group_${user.user_id}`, onUpdateGroup);
            await SocketManager.persistOn('users', `on_remove_group_${user.user_id}`, onRemoveGroup);

            // user group listeners based on account type
            await SocketManager.persistOn('users', `on_new_group_level_${user.level}`, onNewGroup);
            await SocketManager.persistOn('users', `on_update_group_level_${user.level}`, onUpdateGroup);
            await SocketManager.persistOn('users', `on_remove_group_level_${user.level}`, onRemoveGroup);

            await Utils.sleep(2);
            await SocketManager.connect(utils, user);

            let content = await getContent(utils);
            setPanels(content);
            setContent(content);
            await utils.loader.hide();

            setContainer({
                top: 0,
                opacity: 1
            });
            setActive({
                view: 'dashboard',
                subView: null
            });
            if(!Utils.isMobile()) {
                setSidebar(0);
            }

        } catch(e) {
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your content. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNavigationPress = ({ action, subView, view }) => {

        if(Utils.isMobile()) {
            setSidebar(-500);
        }

        if(view === active.view) {
            if(!subView || subView === active.subView) {
                return;
            }
        }

        if(view === 'profile') {
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            });
            return;
        }

        if(view === 'logout') {
            utils.alert.show({
                title: 'Logout',
                message: 'Are you sure that you want to logout of your account?',
                buttons: [{
                    key: 'logout',
                    title: 'Logout',
                    style: 'destructive'
                },{
                    key: 'cancel',
                    title: 'Do Not Logout',
                    style: 'default'
                }],
                onClick: async key => {
                    if(key !== 'logout') {
                        return;
                    }
                    setSidebar(-500);
                    setContainer({
                        top: -200,
                        opacity: 0
                    })
                    await Utils.sleep(0.75);
                    setActive({ view: null });
                    setActiveProgram(null);
                    setPrograms(null);
                    setContent({});
                    setPanels([]);
                    setLayers([]);
                    setUser(null);
                }
            })
            return;
        }

        window.scrollTo(0, 0);
        setActive({
            view: view,
            subView: subView
        })
    }

    const onNewDemo = props => {
        try {
            let { demo } = props;
            if(!demo) {
                throw new Error('Demo information not found in socket payload');
                return;
            }
            // run fetch for content listeners and update overview stats for demos
            utils.events.emit('update_overview');
            utils.content.fetch('demo');

        } catch(e) {
            console.log(e.message);
        }
    }

    const onNewDemoRequest = props => {
        try {
            let { demo } = props;
            if(!demo) {
                throw new Error('Demo request information not found in socket payload');
                return;
            }
            // run fetch for content listeners and update overview stats for demo requests
            utils.events.emit('update_overview');
            utils.content.fetch('demo_request');

        } catch(e) {
            console.log(e.message);
        }
    }

    const onNewGroup = ({ group }) => {
        try {
            console.log(`added group ${group.id}`);
            if(!groupsRef.current) {
                return;
            }
            setGroups(update(groupsRef.current, {
                $push: [ group ]
            }));
        } catch(e) {
            console.log(e.message);
        }
    }

    const onNewLead = props => {
        try {
            let { lead } = props;
            if(!lead) {
                throw new Error('Lead information not found in socket payload');
                return;
            }
            // run fetch for content listeners and update overview stats for leads
            utils.events.emit('update_overview');
            utils.content.fetch('lead');

        } catch(e) {
            console.log(e.message);
        }
    }

    const onNotification = data => {
        try {
            let notification = Notification.create(data);
            setNotification(notification);
            utils.content.fetch('notification');
        } catch(e) {
            console.log(e);
        }
    }

    const onOpenLayer = nextLayer => {

        if(layers.find(layer => {
            return layer.id === nextLayer.id && layer.visible !== false
        })) {
            utils.alert.show({
                title: nextLayer.abstract ? nextLayer.abstract.getTitle() : 'Just a Second',
                message: `There is already a window open for ${nextLayer.abstract ? `"${nextLayer.abstract.getTitle()}"` : 'this information'}`
            });
            return;
        }

        setTimeout(() => {
            setLayers(layers => update(layers, {
                $push: [nextLayer]
            }))
            setLayerIndex(layerIndex => update(layerIndex, {
                $unshift: [nextLayer.id]
            }))
        }, 0)
    }

    const onRemoveGroup = ({ group }) => {
        try {
            console.log(`removed group ${group.id}`);
            if(!groupsRef.current) {
                return;
            }
            setGroups(groupsRef.current.filter(prevGroup => {
                return prevGroup.id !== group.id;
            }));
        } catch(e) {
            console.log(e.message);
        }
    }

    const onRestrictedTextClick = evt => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Restricted Information',
            message: 'Your Dealership has set this information as hidden for your account. Please speak with your Dealer if you have questions about this piece of information.'
        })
    }

    const onSetLayerIndex = layerID => {

        let index = layers.findIndex(layer => layer.id === layerID);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers, update(layers, {
            $apply: layers => layers.map(layer => {
                layer.moveToFront = layer.id === layerID;
                return layer;
            })
        }))

        let _index = layerIndex.findIndex(id => id === layerID);
        setLayerIndex(layerIndex, update(layerIndex, {
            $splice: [
                [_index, 1],
                [0, 0, layerID]
            ]
        }))
    }

    const onSetStyleSheetProperties = () => {
        document.body.className = window.theme;
        document.documentElement.style.setProperty('--theme', window.theme);
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());
    }

    const onUpdateDealershipPreferences = () => {
        utils.events.emit('dealership_preferences_update');
    }

    const onUpdateDemo = props => {
        try {
            let { id, demo, status } = props;
            if(!demo && !id && !status) {
                throw new Error('Demo information not found in socket payload');
                return;
            }
            // update overview stats for demo requests
            utils.events.emit('update_overview');

            // update listeners with new target object if applicable
            if(demo) {
                let abstract = Abstract.create({
                    type: 'demo',
                    object: Demo.create(demo)
                });
                utils.content.update(abstract);
            }

        } catch(e) {
            console.log(e.message);
        }
    }

    const onUpdateDemoRequest = props => {
        try {
            let { id, demo, status } = props;
            if(!demo && !id && !status) {
                throw new Error('Demo request information not found in socket payload');
                return;
            }
            // update overview stats for demo requests
            utils.events.emit('update_overview');

            // update listeners with new target object if applicable
            if(demo) {
                let abstract = Abstract.create({
                    type: 'demo_request',
                    object: DemoRequest.create(demo)
                });
                utils.content.update(abstract);
            }

        } catch(e) {
            console.log(e.message);
        }
    }

    const onUpdateGroup = ({ group }) => {
        try {
            console.log(`updated group ${group.id}`);
            if(!groupsRef.current) {
                return;
            }
            setGroups(groupsRef.current.map(prevGroup => {
                return prevGroup.id === group.id ? group : prevGroup;
            }));
        } catch(e) {
            console.log(e.message);
        }
    }

    const onUpdateLead = props => {
        try {
            let { id, lead, status } = props;
            if(!lead && !id && !status) {
                throw new Error('Lead information not found in socket payload');
                return;
            }
            // update overview stats for demo requests
            utils.events.emit('update_overview');

            // update listeners with new target object if applicable
            if(lead) {
                let abstract = Abstract.create({
                    type: 'lead',
                    object: Lead.create(lead)
                });
                utils.content.update(abstract);
            }

        } catch(e) {
            console.log(e.message);
        }
    }

    const onUpdateTheme = evt => {
        window.theme = evt.matches ? 'dark' : 'light';
        onSetStyleSheetProperties();
        setNonce(moment().unix());
    }

    const onWindowSizeChange = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    const getLogin = () => {

        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                padding: 25
            }}>
                <Login
                utils={utils}
                onLogin={onLogin}/>
            </div>
        )
    }

    const getMainContent = () => {

        let panels = getPanels();
        return (
            <div style={{
                backgroundColor: Appearance.colors.background()
            }}>
                {layers.map(({ id, abstract, options, title, visible, Component }, index, layers) => {
                    if(visible === false) {
                        return null; // using filter for visible does not preseve other visible layers
                    }
                    return (
                        <Component
                        key={index}
                        title={title}
                        index={index}
                        utils={utils}
                        abstract={abstract}
                        options={{
                            ...options,
                            index: index,
                            zIndex: (EndIndex - layerIndex.findIndex(indexID => id === indexID)),
                            onClose: onCloseLayer,
                            onSetLayerIndex: onSetLayerIndex,
                            onReposition: onLayerReposition
                        }}/>
                    )
                })}
                <div className={'container-fluid'}>
                    <div className={'row'}>
                        <VelocityComponent
                        easing={[250, 20]}
                        duration={750}
                        delay={250}
                        animation={{
                            left: sidebar
                        }}>
                            <aside
                            className={'main-sidebar col-12 col-md-3 col-lg-2 px-0'}
                            style={{
                                borderWidth: 0,
                                zIndex: '1000'
                            }}>
                                <Sidebar
                                user={user}
                                active={active}
                                content={content}
                                onMobileClose={() => setSidebar(-window.innerWidth)}
                                onPress={onNavigationPress}>
                                    <div className={'d-block d-md-none'}>
                                        <RightSidebar
                                        user={user}
                                        utils={utils}
                                        inline={true}
                                        activeProgram={activeProgram}
                                        programs={programs}/>
                                    </div>
                                </Sidebar>
                            </aside>
                        </VelocityComponent>
                        <main
                        className={`main-content col-sm-12 ${activeProgram ? 'col-md-6 col-lg-8' : 'col-md-9 col-lg-10'} p-0 offset-lg-2 offset-md-3`}
                        style={{
                            zIndex: '900'
                        }}>
                            {getMobileHeader()}
                            <div
                            className={'main-content-container container-fluid px-0 pt-0'}
                            style={{
                                position: 'relative',
                                paddingBottom: 45
                            }}>
                                <VelocityComponent
                                easing={[250, 20]}
                                duration={1000}
                                animation={container}>
                                    <div
                                    className={'row w-100 p-0 m-0'}
                                    style={{
                                        position: 'relative'
                                    }}>
                                        {panels && (
                                            panels.map(({ Component }, index) => (
                                                <Component
                                                key={index}
                                                utils={utils}
                                                index={index} />
                                            ))
                                        )}
                                    </div>
                                </VelocityComponent>
                            </div>
                        </main>

                        <div className={'d-none d-md-block'}>
                            {activeProgram && (
                                <VelocityComponent
                                easing={[250, 20]}
                                duration={750}
                                delay={250}
                                animation={{
                                    right: sidebar
                                }}>
                                    <aside
                                    className={'main-sidebar col-12 col-md-3 col-lg-2 px-0'}
                                    style={{
                                        borderWidth: 0,
                                        zIndex: '1000'
                                    }}>
                                        <RightSidebar
                                        user={user}
                                        utils={utils}
                                        activeProgram={activeProgram}
                                        programs={programs}/>
                                    </aside>
                                </VelocityComponent>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getMobileHeader = () => {

        return (
            <nav className={`main-navbar ${theme} navbar navbar-light sticky-top d-flex d-md-none flex-md-nowrap p-0 w-100 text-center`}>
                <div style={{
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <a
                    href={'#'}
                    className={'nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center'}>
                        <img
                        className={'text-button'}
                        src={`images/navigation-${theme=== 'dark' ? 'white':'dark-grey'}.png`}
                        style={{
                            width: 25,
                            height: 25,
                            objectFit: 'contain'
                        }}
                        onClick={() => setSidebar(0)} />
                    </a>
                    <div className={'nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center'}/>
                </div>
            </nav>
        )
    }

    const getPanels = () => {
        let { view, subView } = active;
        if(!view) {
            return null;
        }
        if(!subView) {
            return panels[view].panels.filter(panel => {
                return panel.visible !== false;
            });
        }
        if(panels[view].subViews) {
            return panels[view].subViews[subView].panels.filter(panel => {
                return panel.visible !== false;
            });
        }
        return panels[view].panels.filter(panel => {
            return panel.visible !== false;
        });
    }

    const getRestrictedText = () => {
        return (
            <img
            className={'text-button'}
            onClick={onRestrictedTextClick}
            src={'images/text-content-hidden.png'}
            style={{
                width: 70,
                height: 20,
                objectFit: 'contain'
            }} />
        )
    }

    const findContent = () => {
        return user ? getMainContent() : getLogin();
    }

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    useEffect(() => {
        groupsRef.current = groups;
    }, [groups]);

    useEffect(() => {
        programRef.current = activeProgram;
        let changEvent = new Event('program_change');
        window.dispatchEvent(changEvent);
    }, [activeProgram]);

    useEffect(() => {
        layersRef.current = layers;
    }, [layers]);

    useEffect(() => {

        let { view, subView } = active;
        if(!view && !subView) {
            return;
        }
        return;
        window.history.pushState({
            detail: {
                view: view,
                subView: subView
            }
        }, PLATFORM_NAME, `/${view}${subView ? `/${subView}` : ''}`)

    }, [active])

    useEffect(() => {

        // setup scroll polyfill and window listeners
        smoothscroll.polyfill();
        window.addEventListener('resize', onWindowSizeChange);
        window.addEventListener('beforeunload', (e) => {
            (e || window.event).returnValue = null;
            return null;
        });

        // theme and theme listeners
        window.theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.style.setProperty('--theme', window.theme);
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', onUpdateTheme);
        onSetStyleSheetProperties();

    }, []);

    return (
        <div
        className={`root-container ${window.theme}`}
        nonce={nonce}
        style={{
            width: '100%',
            height: size.height,
            position: 'relative'
        }}>
            {findContent()}
            <Loader animate={loader}/>

            {datePicker && (
                <DatePicker
                {...datePicker}
                onClose={id => {
                    setDatePicker(null);
                    if(typeof(datePicker.onClose) === 'function') {
                        datePicker.onClose();
                    }
                }} />
            )}
            {sheet && (
                <Sheet
                {...sheet}
                onClose={() => setSheet(null)}/>
            )}
            {notification && (
                <DesktopNotification
                utils={utils}
                notification={notification}
                onClose={() => setNotification(null)}/>
            )}

            <AlertStack>
                {alerts.map((alert, index) => (
                    <Alert {...alert}
                    key={index}
                    utils={utils}
                    index={(alerts.length - 1) - index}
                    onClose={id => {
                        if(typeof(alert.onClose) === 'function') {
                            alert.onClose();
                        }
                        setAlerts(alerts => {
                            return alerts.filter(alert => {
                                return id !== alert.id;
                            });
                        })
                    }} />
                ))}
            </AlertStack>
        </div>
    )
}

export default App;
