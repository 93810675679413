import React, { useState, useEffect } from 'react';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import { FloatingMenuIndex } from 'structure/Layer.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import { UserDetails } from 'managers/Users.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const UserLookupField = ({ containerStyle, hideAvatar, icon, inline, levels, onChange, placeholder, user, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState(value !== null && value !== undefined ? value : '');

    const onValidate = () => {
        return selected ? true : false
    }

    const onUserClick = () => {
        utils.layer.open({
            id: `user_details_${selected.user_id}`,
            abstract: Abstract.create({
                type: 'user',
                object: selected
            }),
            Component: UserDetails
        })
    }

    const onClick = async selectedUser => {

        setResults([]);
        setSelected(selectedUser);
        setText(selectedUser.full_name);

        if(typeof(onChange) === 'function') {
            onChange(selectedUser);
        }
    }

    const onTextChange = text => {

        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        setText(text);
        setLoading(true);
        setSelected(false);

        setTextTimeout(setTimeout(async () => {

            if(text.length < 3) {
                setResults([]);
                setLoading(false);
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
                return;
            }

            try {
                let { users } = await Request.get(utils, '/users/', {
                    type: 'lookup',
                    limit: 10,
                    search_text: text,
                    levels: levels
                })
                setLoading(false);
                setResults(users);

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue searching for user accounts. ${e.message || 'An unknown error occurred'}`
                });
            }

        }, 250));
    }

    const getStyles = () => {
        if(inline === false) {
            return {
                position: 'absolute',
                top: 43,
                left: 0,
                right: 0,
                padding: 0,
                maxHeight: 250,
                overflowY: 'scroll',
                textAlign: 'left',
                borderRadius: 8,
                zIndex: FloatingMenuIndex,
                border: `1px solid rgba(175,175,175,0.25)`,
                backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                boxShadow: window.theme === 'dark' ? '4px 4px 7px rgba(174,174,174,0.25)' : '4px 4px 7px rgba(0,0,0,0.15)'
            }
        }
        return {
            padding: 0,
            overflow: 'hidden',
            marginTop: 8,
            marginBottom: 12,
            borderRadius: 12,
            textAlign: 'left',
            border: `1px solid ${Appearance.colors.divider()}`,
            backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white'
        }
    }

    useEffect(() => {
        setSelected(user);
        setText(user ? user.full_name : '');
    }, [user]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }}>
                {selected && hideAvatar !== true && (
                    <img
                    src={selected.avatar}
                    className={'text-button'}
                    onClick={onUserClick}
                    style={{
                        width: 35,
                        height: 35,
                        objectFit: 'cover',
                        borderRadius: 17.5,
                        overflow: 'hidden',
                        marginRight: 8
                    }}/>
                )}
                <TextField
                value={text}
                icon={icon}
                loading={loading}
                placeholder={placeholder}
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                onChange={onTextChange}
                style={{
                    flexGrow: 1,
                    ...Appearance.textStyles.title()
                }}/>
            </div>
            {results.length > 0
                ?
                <div style={getStyles()}>
                    {results.map((user, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: user.full_name,
                                subTitle: user.phone_number,
                                icon: {
                                    path: user.avatar
                                },
                                onClick: onClick.bind(this, user),
                                style: {
                                    padding: '6px 12px 6px 12px'
                                },
                                singleItem: results.length === 1,
                                firstItem: index === 0,
                                lastItem: index !== results.length - 1,
                                rightContent: (
                                    <img
                                    src={'images/next-arrow-small.png'}
                                    style={{
                                        width: 15,
                                        height: 15,
                                        objectFit: 'contain'
                                    }}/>
                                )
                            })
                        )
                    })}
                </div>
                :
                null
            }
        </div>
    )
}
export default UserLookupField;
