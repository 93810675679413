import React, { useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import LottieView from 'views/Lottie.js';

const NewCalendarEvent = ({ onClick, style }) => {

    const [hover, setHover] = useState(false);
    
    return (
        <div
        className={'cursor-pointer'}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            ...style
        }}>
            {hover && (
                <LottieView
                loop={true}
                autoPlay={true}
                source={require('files/lottie/thank-you-blue.json')}
                style={{
                    width: 50,
                    height: 50
                }} />
            )}
        </div>
    )
}
export default NewCalendarEvent;
