import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import { BookDemoFromLead } from 'managers/Demos.js';
import AddressLookupField from 'views/AddressLookupField.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import CallLog from 'classes/CallLog.js';
import Dealership from 'classes/Dealership.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import EditTarget from 'views/EditTarget.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import LeadTypePickerField from 'views/LeadTypePickerField.js';
import LeadScriptEditor from 'views/LeadScriptEditor.js';
import LeadSubTypePickerField from 'views/LeadSubTypePickerField.js';
import ListField from 'views/ListField.js';
import LottieView from 'views/Lottie.js';
import { Map } from 'views/MapElements.js';
import Panel from 'structure/Panel.js';
import PickerField from 'views/PickerField.js';
import Program from 'classes/Program.js';
import Lead from 'classes/Lead.js';
import LeadLookupField from 'views/LeadLookupField.js';
import Request from 'files/Request.js';
import Scheduler from 'views/Scheduler.js';
import TagLookupField from 'views/TagLookupField.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import UserLookupField from 'views/UserLookupField.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

// Panels
export const CallLogs = ({ index, options, utils }) => {

    const panelID = 'call_logs';
    const limit = 15;

    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onLogClick = log => {
        utils.layer.open({
            id: `call_log_details_${log.id}`,
            abstract: Abstract.create({
                type: 'call_log',
                object: log
            }),
            Component: CallLogDetails
        });
    }

    const onPrintLogs = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { logs, paging } = await Request.get(utils, '/dealerships/', {
                    type: 'call_logs',
                    user_id: utils.user.get().user_id,
                    search_text: searchText,
                    ...props
                })

                setLoading(false);
                resolve(logs.map(log => CallLog.create(log)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const getContent = () => {
        if(logs.length === 0) {
            return (
                Views.entry({
                    title: 'No Call or Emails Found',
                    subTitle: 'No calls or emails were found in the system',
                    singleItem: true,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return logs.map((log, index) => {
                return getFields(log, index)
            })
        }

        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {logs.map((log, index) => {
                        return getFields(log, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (log, index) => {

        let target = log || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: log.author ? log.author.full_name : 'Name not available',
                    subTitle: moment(log.start_date).format('MMMM Do, YYYY [at] h:mma'),
                    badge: [{
                        text: log.direction,
                        color: log.direction === 'outbound' ? Appearance.colors.grey() : Appearance.colors.primary()
                    },{
                        text: log.method,
                        color: Appearance.colors.secondary()
                    }],
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onLogClick.bind(this, log)
                })
            )
        }

        let fields = [{
            key: 'lead_full_name',
            title: 'Lead',
            value: target.lead_full_name
        },{
            key: 'assign_to',
            title: 'Assigned To',
            value: target.assign_to ? target.assign_to.full_name : 'Not Assigned'
        },{
            key: 'start_date',
            title: 'Date',
            value: target.start_date ? moment(target.start_date).format('MMMM Do, YYYY [at] h:mma') : null
        },{
            key: 'method',
            title: 'Method',
            value: target.method ? Utils.ucFirst(target.method) : null
        },{
            key: 'direction',
            title: 'Direction',
            value: (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    maxWidth: 95,
                    textAlign: 'center',
                    border: `1px solid ${target.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green}`,
                    background: Appearance.colors.softGradient(target.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green),
                    borderRadius: 5,
                    overflow: 'hidden'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: 'white',
                        fontWeight: '600',
                        width: '100%'
                    }}>{target.direction ? Utils.ucFirst(target.direction) : null}</span>
                </div>
            )
        }];

        // Headers
        if(!log) {
            return (
                <tr style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{field.title}</span>
                        </td>
                    )
                })}
                </tr>
            )
        }

        // Rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onLogClick.bind(this, log)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintLogs,
            onRenderItem: (item, index, items) => ({
                lead_full_name: item.lead_full_name,
                assign_to: item.assign_to ? item.assign_to.full_name : 'Not Assigned',
                start_date: item.start_date ? moment(item.start_date).format('MMMM Do, YYYY [at] h:mma') : null,
                method: item.method ? Utils.ucFirst(item.method) : null,
                direction: item.direction ? Utils.ucFirst(item.direction) : null
            }),
            headers: [{
                key: 'lead_full_name',
                title: 'Lead'
            },{
                key: 'assign_to',
                title: 'Assigned To'
            },{
                key: 'start_date',
                title: 'Date'
            },{
                key: 'method',
                title: 'Method'
            },{
                key: 'direction',
                title: 'Direction'
            }]
        }
    }

    const fetchLogs = async () => {
        try {
            let { logs, paging } = await Request.get(utils, '/dealerships/', {
                type: 'call_logs',
                user_id: utils.user.get().user_id,
                offset: offset,
                search_text: searchText,
            })

            setLoading(false);
            setPaging(paging);
            setLogs(logs.map(log => CallLog.create(log)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of call logs. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setLoading(true);
        fetchLogs();
    }, [offset, searchText]);


    useEffect(() => {
        utils.content.subscribe(panelID, 'call_log', {
            onFetch: fetchLogs,
            onUpdate: abstract => {
                setLogs(logs => logs.map(prevLog => {
                    return prevLog.id === abstract.getID() ? abstract.object : prevLog;
                }))
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Calls and Emails'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            search: {
                placeholder: 'Search by first or last name...',
                onChange: text => setSearchText(text)
            },
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: nextOffset => setOffset(nextOffset)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const CallLogsCalendar = ({ index, options, utils }) => {

    const panelID = 'call_logs_calendar';
    const [loading, setLoading] = useState(null);
    const [logs, setLogs] = useState([]);
    const [resources, setResources] = useState([]);
    const [targetDate, setTargetDate] = useState(moment());
    const [panelWidth, setPanelWidth] = useState(0);

    const onEventClick = evt => {
        utils.layer.open({
            id: `call_log_details_${evt.log.id}`,
            abstract: Abstract.create({
                type: 'call_log',
                object: evt.log
            }),
            Component: CallLogDetails
        });
    }

    const onLogAdded = data => {
        try {
            if(data.from_user === utils.user.get().user_id) {
                console.log('from user');
                return;
            }
            if(!data.log.assign_to || data.log.assign_to.user_id !== utils.user.get().user_id) {
                console.log('for another user');
                return;
            }

            let { log } = data;
            let nextLogs = [{
                id: log.id,
                start: log.start_date,
                end: log.end_date,
                resourceId: log.assign_to.user_id,
                title: `${Utils.ucFirst(log.direction)} ${log.method === 'phone' ? 'Phone Call' : 'Email'} for ${log.lead_full_name}`,
                bgColor: log.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green,
                log: CallLog.create(log)
            }];
            if(log.follow_up_start_date) {
                nextLogs.push({
                    id: `follow_up_${log.id}`,
                    start: log.follow_up_start_date,
                    end: moment(log.follow_up_start_date).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                    resourceId: log.assign_to.user_id,
                    title: `Follow Up Call for ${log.lead_full_name}`,
                    bgColor: Appearance.colors.grey(),
                    log: CallLog.create(log)
                })
            }
            setLogs(logs => update(logs, {
                $push: nextLogs
            }))

        } catch(e) {
            console.log(e.message);
        }
    }

    const onLogUpdated = data => {
        try {
            setLogs(logs => logs.map(log => {
                if(log.id === data.log.id || log.id === `follow_up_${data.log.id}`) {
                    return {
                        ...log,
                        ...data.log,
                        start: data.log.start_date,
                        end: data.log.end_date,
                        bgColor: data.log.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green
                    }
                }
                return log;
            }))
        } catch(e) {
            console.log(e.message);
        }
    }

    const onLogDeleted = data => {
        try {
            setLogs(logs => logs.filter(log => {
                return log.id !== data.id && log.id !== `follow_up_${data.log.id}`;
            }))
        } catch(e) {
            console.log(e.message);
        }
    }

    const onRenderCallout = ({ evt, title, start, end, color }) => {
        return (
            <div style={{
                maxWidth: 350
            }}>
                {Views.entry({
                    title: title,
                    subTitle: `${moment(start).format('h:mma')} to ${moment(end).format('h:mma')}`,
                    hideIcon: evt.log.assign_to ? false : true,
                    icon: {
                        path: evt.log.assign_to ? evt.log.assign_to.avatar : null
                    },
                    borderBottom: false
                })}
            </div>
        )
    }

    const onRenderEvent = ({ agenda, evt, bgColor }) => {
        return (
            <div
            className={'pinstripes'}
            style={{
                backgroundColor: bgColor,
                borderRadius: 25,
                overflow: 'hidden',
                padding: '6px 12px 6px 12px'
            }}>
                <img
                src={evt.log.method === 'email' ? 'images/email-icon-white-small.png' : 'images/phone-icon-white-small.png'}
                style={{
                    height: 20,
                    width: evt.log.method === 'email' ? 18 : 20,
                    objectFit: 'contain'
                }} />
            </div>
        )
    }

    const onRenderResource = ({ agenda, item, className }) => {
        let resource = resources.find(resource => resource.id === item.slotId);
        return (
            <div
            className={className || ''}
            style={{
                textAlign: 'left',
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: agenda ? 8 : 0,
                paddingBottom: agenda ? 8 : 0
            }}>
                {Views.entry({
                    title: item.slotName,
                    subTitle: resource.user ? resource.user.phone_number : null,
                    hideIcon: resource.user ? false : true,
                    icon: {
                        path: resource.user ? resource.user.avatar : null,
                        imageStyle: {
                            boxShadow: null
                        }
                    },
                    bottomBorder: false,
                    style: {
                        padding: 0
                    }
                })}
            </div>
        )
    }

    const getLogs = user => {
        return logs.filter(entry => {
            if(user.user_id === 'unassigned') {
                return entry.log.assign_to === null;
            }
            return entry.log.assign_to && entry.log.assign_to.user_id === user.user_id;
        })
    }

    const fetchLogs = async () => {
        try {
            let { logs, users } = await Request.get(utils, '/dealerships/', {
                type: 'call_log_calendar',
                start_date: targetDate.format('YYYY-MM-DD'),
                end_date: moment(targetDate).add(7, 'days').format('YYYY-MM-DD')
            });

            let user = utils.user.get();
            setResources([{
                id: user.user_id,
                name: user.full_name,
                user: user
            }]);

            setLogs(logs.reduce((array, log) => {
                if(!log.assign_to && log.assign_to.user_id !== user.user_id) {
                    return array;
                }
                let nextLogs = [{
                    id: log.id,
                    start: log.start_date,
                    end: log.end_date,
                    resourceId: log.assign_to.user_id,
                    title: `${Utils.ucFirst(log.direction)} ${log.method === 'phone' ? 'Phone Call' : 'Email'} for ${log.lead_full_name}`,
                    bgColor: log.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green,
                    log: CallLog.create(log)
                }];
                if(log.follow_up_start_date) {
                    nextLogs.push({
                        id: `follow_up_${log.id}`,
                        start: log.follow_up_start_date,
                        end: log.follow_up_end_date || moment(log.follow_up_start_date).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                        resourceId: log.assign_to.user_id,
                        title: `Follow Up Call for ${log.lead_full_name}`,
                        bgColor: Appearance.colors.grey(),
                        log: CallLog.create(log)
                    })
                }
                return array.concat(nextLogs);
            }, []))

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the calls calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLogs();
    }, [targetDate]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchLogs);
        utils.content.subscribe(panelID, 'call_log', {
            onFetch: fetchLogs,
            onUpdate: fetchLogs
        });

        let dealershipID = utils.dealership.get().id;
        utils.sockets.on('dealerships', `on_add_call_log_${dealershipID}`, onLogAdded);
        utils.sockets.on('dealerships', `on_update_call_log_${dealershipID}`, onLogUpdated);
        utils.sockets.on('dealerships', `on_delete_call_log_${dealershipID}`, onLogDeleted);

        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchLogs);
            utils.sockets.off('dealerships', `on_add_call_log_${dealershipID}`, onLogAdded);
            utils.sockets.off('dealerships', `on_update_call_log_${dealershipID}`, onLogUpdated);
            utils.sockets.off('dealerships', `on_delete_call_log_${dealershipID}`, onLogDeleted);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        utils={utils}
        name={'Call Assignments'}
        options={{
            loading: loading,
            onSizeChange: ({ width, height }) => setPanelWidth(width - 30)
        }}>
            <div style={{
                overflow: 'hidden',
                width: '100%'
            }}>
                <Scheduler
                minuteStep={10}
                width={panelWidth}
                utils={utils}
                events={logs}
                resources={resources}
                onDateChange={date => setTargetDate(date)}
                onClick={onEventClick}
                onRenderEvent={onRenderEvent}
                onRenderCallout={onRenderCallout}
                onRenderResource={onRenderResource}
                options={{
                    creatable: false,
                    startResizable: false,
                    endResizable: false,
                    movable: false,
                    crossResourceMove: false
                }} />
            </div>
        </Panel>
    )
}

export const Leads = ({ index, options, utils }) => {

    const panelID = 'leads';
    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [leads, setLeads] = useState([]);

    const getFields = (lead, index) => {

        let target = lead || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: utils.groups.apply([ 'first_name', 'last_name' ], User.Group.categories.leads, target.full_name),
                    subTitle: utils.groups.apply('phone_number', User.Group.categories.leads, target.phone_number),
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onLeadClick.bind(this, lead)
                })
            )
        }

        let fields = [{
            key: 'full_name',
            title: 'Full Name',
            value: utils.groups.apply([ 'first_name', 'last_name' ], User.Group.categories.leads, target.full_name)
        },{
            key: 'address',
            title: 'Address',
            value: utils.groups.apply('address', User.Group.categories.leads, Utils.formatAddress(target.address))
        },{
            key: 'phone_number',
            title: 'Phone',
            value: utils.groups.apply('phone_number', User.Group.categories.leads, target.phone_number)
        },{
            key: 'status',
            title: 'Status',
            value: utils.groups.apply('status', User.Group.categories.leads, getLeadStatus(target))
        }];

        // Headers
        if(!lead) {
            return (
                <tr style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{field.title}</span>
                        </td>
                    )
                })}
                </tr>
            )
        }

        // Rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onLeadClick.bind(this, lead)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const fetchLeads = async () => {
        try {
            setLoading(true);
            let { leads, paging } = await Request.get(utils, '/leads/', {
                type: 'all',
                limit: 10,
                offset: offset
            });

            setLoading(false);
            setPaging(paging);
            setSearchOffset(0);
            setLeads(leads.map(lead => Lead.create(lead)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the leads list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const searchLeads = async () => {
        if(!searchText) {
            return;
        }
        try {
            setLoading(true);
            let { leads, paging } = await Request.get(utils, '/leads/', {
                type: 'lookup_for_associate',
                limit: 10,
                offset: searchOffset,
                search_text: searchText
            });

            setLoading(false);
            setOffset(0);
            setPaging(paging);
            setLeads(leads.map(lead => Lead.create(lead)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the leads list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onLeadClick = lead => {
        utils.layer.open({
            id: `lead_details_${lead.id}`,
            abstract: Abstract.create({
                type: 'lead',
                object: lead
            }),
            Component: LeadDetails
        })
    }

    const onNewLead = () => {

        let lead = Lead.new();
        lead.enrollment_user = utils.user.get();
        lead.program_credit = utils.program.get();

        utils.layer.open({
            id: 'new_lead',
            abstract: Abstract.create({
                type: 'lead',
                object: lead
            }),
            Component: AddEditLead.bind(this, {
                isNewTarget: true
            })
        })
    }

    const getContent = () => {
        if(leads.length === 0) {
            return (
                Views.entry({
                    title: 'No Leads Found',
                    subTitle: 'No leads were found in the system',
                    singleItem: true,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return leads.map((lead, index) => {
                return getFields(lead, index)
            })
        }

        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {leads.map((lead, index) => {
                        return getFields(lead, index)
                    })}
                </tbody>
            </table>
        )
    }

    useEffect(() => {
        fetchLeads();
    }, [offset]);

    useEffect(() => {
        searchLeads();
    }, [searchOffset]);

    useEffect(() => {
        if(searchText) {
            searchLeads();
        } else {
            fetchLeads();
        }
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'program_change', fetchLeads);
        utils.content.subscribe(panelID, [ 'lead' ], {
            onFetch: fetchLeads,
            onUpdate: abstract => {
                setLeads(leads => {
                    return leads.map(lead => {
                        return lead.id === abstract.getID() ? abstract.object : lead
                    })
                })
            }
        });
        return () => {
            utils.events.off(panelID, 'program_change');
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Leads'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            search: {
                placeholder: 'Search by Lead name...',
                onChange: text => setSearchText(text)
            },
            buttons: [{
                key: 'new',
                title: 'New Lead',
                style: 'default',
                onClick: onNewLead
            }],
            paging: {
                data: paging,
                limit: 10,
                offset: searchText ? searchOffset : offset,
                onClick: nextOffset => {
                    if(searchText) {
                        setSearchOffset(nextOffset);
                        return;
                    }
                    setOffset(nextOffset);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                overflow: 'hidden'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const LeadLocations = ({ index, options, utils }) => {

    const panelID = 'lead_locations';
    const filterContainer = useRef(null);
    const [loading, setLoading] = useState(null);
    const [locationData, setLocationData] = useState(null);
    const [region, setRegion] = useState(null);

    const onLeadClick = async props => {
        try {
            let lead = await Lead.get(utils, props.id);
            utils.layer.open({
                id: `lead_details_${lead.id}`,
                abstract: Abstract.create({
                    type: 'lead',
                    object: lead
                }),
                Component: LeadDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this Lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getFeatures = () => {
        if(!locationData) {
            return null;
        }
        return {
            id: 'lead_locations',
            region: region,
            onClick: onLeadClick,
            icons: [{
                key: 'location-icon-grey',
                path: 'images/location-icon-grey.png'
            }],
            data: locationData,
            layer: {
                type: 'symbol',
                layout: {
                    'icon-size': 0.2,
                    'icon-anchor': 'center',
                    'icon-image': 'location-icon-grey',
                    'icon-allow-overlap': true
                },
                paint: {
                    'icon-color': [ 'get', 'color' ]
                }
            },
            onHover: feature => {
                try {
                    let { id, full_name, address } = feature.properties;
                    return {
                        title: full_name,
                        subTitle: address
                    }
                } catch(e) {
                    console.log(e.message);
                }
            }
        }
    }

    const fetchLocations = async () => {
        try {
            let { data, region } = await Request.get(utils, '/leads/', {
                type: 'locations'
            });
            setRegion(region);
            setLocationData(data);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the Leads map. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLocations();
        utils.events.on(panelID, 'dealership_change', fetchLocations);
        return () => {
            utils.events.off(panelID, 'dealership_change');
        }

    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Locations'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
        }}>
            <Map
            isZoomEnabled={true}
            isScrollEnabled={true}
            useShadows={false}
            features={getFeatures()}
            style={{
                width: '100%',
                height: 350,
                border: `1px solid ${Appearance.colors.divider()}`
            }}/>
        </Panel>
    )
}

// Layers
export const AddEditCallLog = ({ isNewTarget, lead }, { abstract, options, utils }) => {

    const layerID = isNewTarget ? `new_call_log_${lead.id}` : `edit_call_log_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [callLog, setCallLog] = useState(null);

    const onDoneClick = async () => {

        // valdiate fields
        let items = getFields().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined;
        });
        if(required) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please fill out the "${required.title}" before moving on`
            });
            return;
        }

        try {
            if(isNewTarget) {
                setLoading('done');
                await Utils.sleep(1);
                await abstract.object.submit(utils);

                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `Your new call has been saved for this Lead`,
                    onClick: () => setLayerState('close')
                });
                return;
            }

            setLoading('done');
            await Utils.sleep(1);
            await abstract.object.update(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The call for this Lead has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this call. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props)
            setCallLog(edits);
        } catch(e) {
            console.log(e.message);
        }
    }

    const getFields = () => {

        if(!callLog) {
            return [];
        }

        let items = [{
            key: 'date',
            title: 'Date and Time',
            items: [{
                key: 'start_date',
                title: 'Call Date and Time',
                description: 'When did this call occur?',
                component: 'date_duration_picker',
                value: callLog.start_date,
                onChange: date => onUpdateTarget({ start_date: date })
            },{
                key: 'end_date',
                title: 'Duration',
                description: 'How long was this call?',
                component: 'duration_picker',
                visible: callLog.start_date ? true : false,
                value: callLog.end_date ? moment(callLog.end_date).unix() - moment(callLog.start_date).unix() > 0 : false,
                onChange: seconds => {
                    onUpdateTarget({ end_date: moment(callLog.start_date).add(seconds, 'seconds') })
                },
                props: {
                    seconds: callLog.end_date ? moment(callLog.end_date).unix() - moment(callLog.start_date).unix() : null
                }
            }]
        },{
            key: 'follow_up_date',
            title: 'Follow Up Date and Time',
            items: [{
                key: 'follow_up_start_date',
                required: false,
                title: 'Call Date and Time',
                description: 'When would you like to schedule a follow up call? Follow up calls are not required.',
                component: 'date_duration_picker',
                value: callLog.follow_up_start_date,
                onChange: date => onUpdateTarget({ follow_up_date: date })
            },{
                key: 'follow_up_end_date',
                required: false,
                title: 'Duration',
                description: 'How much time would you like to allocate for the follow up call?',
                component: 'duration_picker',
                visible: callLog.follow_up_start_date ? true : false,
                value: callLog.follow_up_end_date ? moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix() > 0 : false,
                onChange: seconds => {
                    onUpdateTarget({ follow_up_end_date: moment(callLog.follow_up_start_date).add(seconds, 'seconds') })
                },
                props: {
                    seconds: callLog.follow_up_end_date ? moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix() : null
                }
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'direction',
                title: 'Direction',
                description: 'Did you make initial the outbound conversation or did the lead initiate the inbound conversation?',
                component: 'list',
                value: callLog.direction ? Utils.ucFirst(callLog.direction) : null,
                onChange: item => onUpdateTarget({ direction: item ? item.code : null }),
                items: [{
                    code: 'inbound',
                    title: 'Inbound'
                },{
                    code: 'outbound',
                    title: 'Outbound'
                }]
            },{
                key: 'method',
                title: 'Method',
                description: 'How did you contact this lead?',
                component: 'list',
                value: callLog.method ? Utils.ucFirst(callLog.method) : null,
                onChange: item => onUpdateTarget({ method: item ? item.code : null }),
                items: [{
                    code: 'email',
                    title: 'Email'
                },{
                    code: 'phone',
                    title: 'Phone'
                }]
            }]
        },{
            key: 'additional',
            title: 'Additional Information',
            items: [{
                key: 'assign_to',
                title: 'Assign To',
                required: false,
                description: 'Would you like to assign this call to someone in your Dealership?',
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ assign_to: user }),
                value: callLog.assign_to,
                props: {
                    levels: [
                        User.level.region_director,
                        User.level.division_director,
                        User.level.area_director,
                        User.level.dealer,
                        User.level.safety_advisor,
                        User.level.safety_associate
                    ]
                }
            },{
                key: 'notes',
                title: 'Notes',
                required: false,
                description: 'Add any information in this space that has not already been covered in the fields above.',
                component: 'textview',
                value: callLog.notes,
                onChange: text => onUpdateTarget({ notes: text }),
            }]
        }]

        return items;
    }

    const setupTarget = async () => {
        try {
            let edits = await abstract.object.open();
            setCallLog(edits);

            if(isNewTarget) {
                abstract.object.lead = lead;
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the ${isNewTarget ? 'creation' : 'editing'} process. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New Call or Email` : `Editing ${abstract.getTitle()}`}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            sizing: 'medium',
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditLead = ({ isNewTarget, onAddLead }, { abstract, options, utils }) => {

    const layerID = isNewTarget ? `new_lead` : `edit_lead_${abstract.getID()}`;
    const [affiliate, setAffiliate] = useState(abstract.object.affiliate);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [homeownerStatusCodes, setHomeownerStatusCodes] = useState([]);
    const [maritalStatusCodes, setMaritalStatusCodes] = useState([]);
    const [occupationalStatusCodes, setOccupationalStatusCodes] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [lead, setLead] = useState(null);
    const [scripts, setScripts] = useState([]);

    const onDoneClick = async props => {
        try {
            setLoading('done');
            await Utils.sleep(1);
            await validateRequiredFields(getFields);

            // create new target
            if(isNewTarget) {
                await abstract.object.submit(utils, props);
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `The Lead for ${abstract.object.full_name} has been created`,
                    onClick: () => setLayerState('close')
                });

                if(typeof(onAddLead) === 'function') {
                    onAddLead(abstract.object);
                }
                return;
            }

            // update target
            await abstract.object.update(utils);
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The Lead for ${abstract.object.full_name} has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            // check for do not call list conflicts
            setLoading(false);
            if(e.code === 409) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `There was an issue creating this Lead. ${e.message || 'An unknown error occurred'}`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Continue',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Add Lead',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onDoneClick({
                                ...props,
                                ...e.response.duplicate === true && {
                                    duplicate_override: true
                                },
                                ...e.response.do_not_call === true && {
                                    do_not_call_override: true
                                }
                            });
                            return;
                        }
                    }
                })
                return;
            }
            // fallback to standard request error
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this Lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = async props => {
        try {
            if(props.affiliate) {
                setAffiliate(props.affiliate);
            }
            let edits = await abstract.object.set(props);
            setLead(edits);

        } catch(e) {
            console.log(e.message);
        }
    }

    const getFields = () => {

        if(!lead) {
            return [];
        }

        let items = [{
            key: 'customer',
            title: 'Customer',
            items: [{
                key: 'first_name',
                title: 'First Name',
                description: 'The first name for the primary customer on this lead',
                component: 'textfield',
                value: lead.first_name,
                onChange: text => onUpdateTarget({ first_name: text })
            },{
                key: 'last_name',
                title: 'Last Name',
                description: 'The last name for the primary customer on this lead',
                component: 'textfield',
                value: lead.last_name,
                onChange: text => onUpdateTarget({ last_name: text })
            },{
                key: 'phone_number',
                required: !lead.email_address && !lead.address ? true : false,
                title: 'Phone Number',
                description: 'The phone number for the primary customer on this lead. Each lead must provide a phone number, email address, OR physical address. Please provide all three fields if the information is available.',
                component: 'textfield',
                value: lead.phone_number,
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: {
                    format: 'phone_number'
                }
            },{
                key: 'email_address',
                required: !lead.phone_number && !lead.address ? true : false,
                title: 'Email Address',
                description: 'The email address for the primary customer on this lead. Each lead must provide a phone number, email address, OR physical address. Please provide all three fields if the information is available.',
                component: 'textfield',
                value: lead.email_address,
                onChange: text => onUpdateTarget({ email_address: text }),
                props: {
                    format: 'email'
                }
            },{
                key: 'address',
                required: !lead.phone_number && !lead.email_address ? true : false,
                title: 'Physical Address',
                description: 'The physical address for the primary customer on this lead. Each lead must provide a phone number, email address, OR physical address. Please provide all three fields if the information is available.',
                component: 'address_lookup',
                value: lead.address,
                onChange: response => onUpdateTarget(response)
            },{
                key: 'homeowner_status',
                required: false,
                title: 'Homeowner Status',
                description: `What is the status of this customer's home ownership? You can skip this field if you do not know this information.`,
                component: 'list',
                value: lead.homeowner_status ? lead.homeowner_status.text : null,
                items: homeownerStatusCodes,
                onChange: item => onUpdateTarget({ homeowner_status: item })
            },{
                key: 'marital_status',
                required: false,
                title: 'Marital Status',
                description: `What is the status of this customer's relationship with their spouse or partner? You can skip this field if you do not know this information.`,
                component: 'list',
                value: lead.marital_status ? lead.marital_status.text : null,
                items: maritalStatusCodes,
                onChange: item => onUpdateTarget({ marital_status: item })
            },{
                key: 'occupational_status',
                required: false,
                title: 'Occupational Status',
                description: `What is the status of this customer's employement? You can skip this field if you do not know this information.`,
                component: 'list',
                value: lead.occupational_status ? lead.occupational_status.text : null,
                items: occupationalStatusCodes,
                onChange: item => onUpdateTarget({ occupational_status: item })
            }]
        },{
            key: 'spouse',
            title: 'Spouse',
            items: [{
                key: 'spouse_first_name',
                required: false,
                title: 'First Name',
                description: 'The first name for the secondary customer on this lead',
                component: 'textfield',
                value: lead.spouse_first_name,
                onChange: text => onUpdateTarget({ spouse_first_name: text })
            },{
                key: 'spouse_last_name',
                required: false,
                title: 'Last Name',
                description: 'The last name for the secondary customer on this lead',
                component: 'textfield',
                value: lead.spouse_last_name,
                onChange: text => onUpdateTarget({ spouse_last_name: text })
            },{
                key: 'spouse_phone_number',
                required: false,
                title: 'Phone Number',
                description: 'The phone number for the secondary customer on this lead. Leave this field blank if the primary and secondary customer share the same phone number.',
                component: 'textfield',
                value: lead.spouse_phone_number,
                onChange: text => onUpdateTarget({ spouse_phone_number: text }),
                props: {
                    format: 'phone_number',
                }
            },{
                key: 'spouse_email_address',
                required: false,
                title: 'Email Address',
                description: 'The email address for the secondary customer on this lead. Leave this field blank if the primary and secondary customer share the same email address.',
                component: 'textfield',
                value: lead.spouse_email_address,
                onChange: text => onUpdateTarget({ spouse_email_address: text }),
                props: {
                    format: 'email'
                }
            }]
        },{
            key: 'lead',
            title: 'About this Lead',
            visible: utils.user.get().level !== User.level.safety_associate,
            items: [{
                key: 'lead_type',
                title: 'Type',
                description: 'Choosing a lead type helps us sort your leads into specific categories.',
                component: 'lead_type_picker',
                value: lead.lead_type ? lead.lead_type.text : null,
                onChange: lead_type => onUpdateTarget({ lead_type: lead_type })
            },{
                key: 'lead_sub_type',
                required: false,
                title: 'Sub-Type',
                description: 'Choosing a sub lead type helps us sort your leads into deeper, more specific categories.',
                component: 'lead_sub_type_picker',
                value: lead.lead_sub_type ? lead.lead_sub_type.text : null,
                onChange: lead_sub_type => onUpdateTarget({ lead_sub_type: lead_sub_type })
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'tags',
                title: 'Tags',
                required: false,
                description: 'Attaching one or more tags to a lead can help organize your Deealership\'s lead catalog. Type a word and press enter when you are done',
                component: 'tag_lookup',
                value: lead.tags,
                onChange: tags => onUpdateTarget({ tags: tags })
            },{
                key: 'notes',
                title: 'Notes',
                required: false,
                description: 'Use this area to attach any information that has not already been covered.',
                component: 'textview',
                value: lead.notes,
                onChange: text => onUpdateTarget({ notes: text })
            }]
        }]

        return items;
    }

    const setupTarget = async () => {
        try {

            let {
                affiliate, default_lead_type, default_script, homeowner_status_codes, marital_status_codes, occupational_status_codes, scripts
            } = await Request.get(utils, '/leads/', {
                type: 'edit_targets',
                id: abstract.getID()
            });

            setAffiliate(affiliate);
            setHomeownerStatusCodes(homeowner_status_codes);
            setMaritalStatusCodes(marital_status_codes);
            setOccupationalStatusCodes(occupational_status_codes);
            setScripts(scripts);

            // set dealership based on "isNewTarget" status
            let edits = await abstract.object.open();
            if(abstract.object.dealership_id) {
                let dealership = await Dealership.get(utils, abstract.object.dealership_id);
                edits = await abstract.object.set({ dealership: dealership });
            }
            if(isNewTarget) {
                edits = await abstract.object.set({ dealership: utils.dealership.get() });
                if(default_lead_type || default_script) {
                    edits = await abstract.object.set({
                        lead_script: default_script,
                        lead_type: default_lead_type
                    })
                }
            }
            setLead(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this lead. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New Lead` : `Editing ${abstract.getTitle()}`}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            sizing: 'medium'
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const CallLogDetails = ({ abstract, options, utils }) => {

    const layerID = `call_log_details_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const getFields = () => {

        let callLog = abstract.object;
        let items = [{
            key: 'date',
            title: 'Date and Time',
            items: [{
                key: 'start_date',
                title: 'Call Date and Time',
                value: callLog.start_date ? moment(callLog.start_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'duration',
                title: 'Duration',
                value: callLog.start_date && callLog.end_date ? Utils.parseDuration(moment(callLog.end_date).unix() - moment(callLog.start_date).unix()) : null,
                visible: callLog.end_date ? true : false
            }]
        },{
            key: 'date',
            title: 'Follow Up Date and Time',
            items: [{
                key: 'follow_up_start_date',
                title: 'Call Date and Time',
                value: callLog.follow_up_start_date ? moment(callLog.follow_up_start_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'duration',
                title: 'Duration',
                value: callLog.follow_up_start_date && callLog.follow_up_end_date ? Utils.parseDuration(moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix()) : null,
                visible: callLog.follow_up_end_date ? true : false
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'direction',
                title: 'Direction',
                value: callLog.direction ? Utils.ucFirst(callLog.direction) : null
            },{
                key: 'method',
                title: 'Method',
                value: callLog.method ? Utils.ucFirst(callLog.method) : null
            }]
        },{
            key: 'additional',
            title: 'Additional Information',
            items: [{
                key: 'assign_to',
                title: 'Assignment',
                value: callLog.assign_to ? callLog.assign_to.full_name : null
            },{
                key: 'notes',
                title: 'Notes',
                value: callLog.notes
            }]
        }]

        return items;
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_call_log_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditCallLog.bind(this, {
                isNewTarget: false,
                lead: abstract.object.lead
            })
        })
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'delete') {
                onDeleteCallLog();
                return;
            }
        })
    }

    const onDeleteCallLog = () => {
        utils.alert.show({
            title: 'Delete Call',
            message: 'Are you sure that you want to delete this call? This action can not be undone',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCallLogConfirm();
                }
            }
        })
    }

    const onDeleteCallLogConfirm = async () => {
        try {

            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/demos/', {
                type: 'delete_call_log',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('call_log');

            utils.alert.show({
                title: 'All Done!',
                message: `This ${abstract.object.method === 'email' ? 'email' : 'phone call'} has been deleted`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this ${abstract.object.method === 'email' ? 'email' : 'phone call'}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    return (
        <Layer
        id={layerID}
        title={`Details for ${abstract.getTitle()}`}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>
            <FieldMapper fields={getFields()} />
        </Layer>
    )
}

export const LeadLeads = ({ abstract, options, utils }) => {

    const layerID = `lead_leads_${abstract.getID()}`;
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [leads, setLeads] = useState([]);

    const onLeadClick = lead => {
        utils.layer.open({
            id: `lead_details_${lead.id}`,
            abstract: Abstract.create({
                type: 'lead',
                object: lead
            }),
            Component: LeadDetails
        })
    }

    const onAddNewLead = () => {

        let lead = Lead.new();
        lead.affiliate = abstract.object;

        utils.layer.open({
            id: 'new_lead',
            abstract: Abstract.create({
                type: 'lead',
                object: lead
            }),
            Component: AddEditLead.bind(this, {
                isNewTarget: true,
                onAddLead: nextLead => {
                    setLeads(leads => update(leads, {
                        $unshift: [nextLead]
                    }))
                }
            })
        })
    }

    const fetchLeads = async () => {
        try {
            let { leads } = await Request.get(utils, '/leads/', {
                type: 'leads',
                id: abstract.getID()
            })
            setLoading(false);
            setLeads(leads.map(lead => Lead.create(lead)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the leads for this Lead. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {

        fetchLeads();
        utils.content.subscribe(layerID, [ 'lead' ], {
            onFetch: fetchLeads,
            onUpdate: fetchLeads
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
        id={layerID}
        title={`Leads from ${abstract.getTitle()}`}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'new',
            text: 'Add a Lead',
            color: 'primary',
            onClick: onAddNewLead
        }]}>
            {leads.length > 0
                ?
                <div style={{
                    borderRadius: 10,
                    border: `1px solid ${Appearance.colors.divider()}`
                }}>
                    {leads.map((lead, index) => {

                        let badge = getLeadStatus(lead);
                        return (
                            Views.entry({
                                key: index,
                                title: lead.full_name,
                                subTitle: `Added ${moment(lead.added).format('MMMM Do, YYYY [at] h:mma')}`,
                                badge: badge ? {
                                    text: badge.title,
                                    color: badge.color
                                } : null,
                                hideIcon: true,
                                singleItem: leads.length === 1,
                                firstItem: index === 0,
                                lastItem: index === leads.length - 1,
                                bottomBorder: true,
                                onClick: onLeadClick.bind(this, lead)
                            })
                        )
                    })}
                </div>
                :
                loading
                    ?
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: 75
                    }}>
                        <LottieView
                        loop={true}
                        autoPlay={true}
                        source={window.theme === 'dark' ? 'files/lottie/dots-white.json' : 'files/lottie/dots-grey.json'}
                        style={{
                            width: 50,
                            height: 50
                        }}/>
                    </div>
                    :
                    <div style={{
                        borderRadius: 10,
                        border: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        {Views.entry({
                            title: 'No Leads Found',
                            subTitle: 'No leads were found in the system',
                            singleItem: true,
                            hideIcon: true
                        })}
                    </div>
            }
        </Layer>
    )
}

export const LeadCallLogs = ({ abstract, options, utils }) => {

    const layerID = `lead_call_logs_${abstract.getID()}`;
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [lead, setLead] = useState(abstract.object);
    const [callLogs, setCallLogs] = useState([]);

    const getStatus = () => {
        if(abstract.object.status !== Lead.status.do_not_call) {
            return null;
        }
        return (
            <div style={{
                ...Appearance.styles.panel(),
                marginBottom: 12
            }}>
                {Views.entry({
                    title: 'Do Not Call',
                    subTitle: 'This Lead has been marked as "Do Not Call"',
                    icon: {
                        path: 'images/do-not-call-red.png'
                    },
                    singleItem: true
                })}
            </div>
        )
    }

    const onNewCallLog = () => {
        utils.layer.open({
            id: `new_call_log_${abstract.getID()}`,
            abstract: Abstract.create({
                type: 'call_log',
                object: CallLog.new()
            }),
            Component: AddEditCallLog.bind(this, {
                isNewTarget: true,
                lead: abstract.object
            })
        })
    }

    const fetchCallLogs = async () => {
        try {
            let { call_logs } = await Request.get(utils, '/leads/', {
                type: 'call_logs',
                id: abstract.getID()
            })
            setLoading(false);
            setCallLogs(call_logs.map(log => CallLog.create(log)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the call logs for this lead. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'do_not_call',
                title: abstract.object.status === Lead.status.do_not_call ? 'Remove Do Not Call Tag' : 'Mark as Do Not Call',
                style: abstract.object.status === Lead.status.do_not_call ? 'default' : 'destructive'
            }]
        }, key => {
            if(key === 'do_not_call') {
                onSetDoNotCall();
                return;
            }
        })
    }

    const onSetDoNotCall = () => {
        utils.alert.show({
            title: abstract.object.status === Lead.status.do_not_call ? 'Remove Do Not Call Tag' : 'Mark as Do Not Call',
            message: `Are you sure that you want to ${abstract.object.status === Lead.status.do_not_call ? 'remove the "Do Not Call" tag from this Lead' : 'mark this Lead as "Do Not Call"'}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: abstract.object.status === Lead.status.do_not_call ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: abstract.object.status === Lead.status.do_not_call ? 'cancel' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetDoNotCallConfirm();
                    return;
                }
            }
        })
    }

    const onSetDoNotCallConfirm = async () => {
        try {

            setLoading(true);
            await Utils.sleep(1);
            let { status } = await Request.post(utils, '/leads/', {
                type: 'set_status',
                id: abstract.getID(),
                status: abstract.object.status === Lead.status.do_not_call ? Lead.status.new : Lead.status.do_not_call
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This Lead has been ${abstract.object.status === Lead.status.do_not_call ? 'un-tagged as "Do Not Call"' : 'has been tagged as "Do Not Call"'}`
            })

            abstract.object.status = status;
            utils.content.update(abstract);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this Lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCallLogClick = log => {
        utils.sheet.show({
            items: [{
                key: 'view',
                title: 'View',
                style: 'default'
            },{
                key: 'edit',
                title: 'Edit',
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {

            if(key === 'view') {
                utils.layer.open({
                    id: `call_log_details_${log.id}`,
                    abstract: Abstract.create({
                        type: 'call_log',
                        object: log
                    }),
                    Component: CallLogDetails
                });
                return;
            }

            if(key === 'edit') {
                utils.layer.open({
                    id: `edit_call_log_${abstract.getID()}`,
                    abstract: Abstract.create({
                        type: 'call_log',
                        object: log
                    }),
                    Component: AddEditCallLog.bind(this, {
                        isNewTarget: false,
                        lead: abstract.object
                    })
                })
                return;
            }

            if(key === 'delete') {
                onDeleteCallLog(log);
                return;
            }
        })
    }

    const onDeleteCallLog = log => {
        utils.alert.show({
            title: 'Delete Call',
            message: 'Are you sure that you want to delete this call? This action can not be undone',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCallLogConfirm(log);
                }
            }
        })
    }

    const onDeleteCallLogConfirm = async log => {
        try {

            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/demos/', {
                type: 'delete_call_log',
                id: log.id
            });

            setLoading(false);
            utils.content.fetch('call_log');

            utils.alert.show({
                title: 'All Done!',
                message: 'This call log has been deleted',
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this call log. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {

        fetchCallLogs();
        utils.content.subscribe(layerID, [ 'call_log', 'lead' ], {
            onFetch: fetchCallLogs,
            onUpdate: abstract => {
                switch(abstract.type) {
                    case 'call_log':
                    setCallLogs(callLogs => {
                        return callLogs.map(callLog => {
                            return callLog.id === abstract.getID() ? abstract.object : callLog
                        })
                    })
                    break;

                    case 'lead':
                    setLead(lead => {
                        return lead.id === abstract.getID() ? abstract.object : lead
                    });
                    break;
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
        id={layerID}
        title={`Call Logs for Lead #${abstract.getID()}`}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'new',
            text: 'New Call',
            color: 'primary',
            onClick: onNewCallLog
        }]}>
            {callLogs.length > 0
                ?
                <div>
                    {getStatus()}
                    <div style={{
                        borderRadius: 10,
                        border: `1px solid ${Appearance.colors.divider()}`
                    }}>
                    {callLogs.map((log, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: log.author ? log.author.full_name : 'Name not available',
                                subTitle: moment(log.date).format('MMMM Do, YYYY [at] h:mma'),
                                badge: [{
                                    text: log.direction,
                                    color: log.direction === 'outbound' ? Appearance.colors.grey() : Appearance.colors.primary()
                                },{
                                    text: log.method,
                                    color: Appearance.colors.secondary()
                                }],
                                hideIcon: true,
                                singleItem: callLogs.length === 1,
                                firstItem: index === 0,
                                lastItem: index === callLogs.length - 1,
                                bottomBorder: true,
                                onClick: onCallLogClick.bind(this, log)
                            })
                        )
                    })}
                    </div>
                </div>
                :
                loading
                    ?
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: 75
                    }}>
                        <LottieView
                        loop={true}
                        autoPlay={true}
                        source={window.theme === 'dark' ? 'files/lottie/dots-white.json' : 'files/lottie/dots-grey.json'}
                        style={{
                            width: 50,
                            height: 50
                        }}/>
                    </div>
                    :
                    <div style={{
                        borderRadius: 10,
                        border: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        {Views.entry({
                            title: 'No Call Logs Found',
                            subTitle: 'No call logs were found in the system',
                            singleItem: true,
                            hideIcon: true
                        })}
                    </div>
            }
        </Layer>
    )
}

export const LeadDetails = ({ abstract, options, utils }) => {

    const layerID = `lead_details_${abstract.getID()}`;
    const [customerResponse, setCustomerResponse] = useState(null);
    const [dealership, setDealership] = useState(abstract.object.dealership);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [lead, setLead] = useState(abstract.object);
    const [tags, setTags] = useState(null);

    const onArchiveClick = () => {
        utils.alert.show({
            title: `Archive List`,
            message: `This Lead was added to your Archive list. This means that this Lead will not show up on any Dealership lead lists or reports.`,
            buttons: [{
                key: 'unarchive',
                title: 'Unarchive',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'default'
            }],
            onClick: async key => {
                if(key === 'unarchive') {
                    try {
                        await Utils.sleep(0.5);
                        onSetArchiveStatus();
                    } catch(e) {
                        console.log(e.message);
                    }
                    return;
                }
            }
        })
    }

    const onBookDemoClick = () => {
        utils.layer.open({
            id: `book_demo_${abstract.getID()}`,
            abstract: abstract,
            Component: BookDemoFromLead.bind(this, {})
        })
    }

    const onDoNotCall = () => {
        utils.alert.show({
            title: `${abstract.object.status.code === Lead.status.do_not_call ? 'Remove from' : 'Add to'} Do Not Call List`,
            message: `Are you sure that you want to ${abstract.object.status.code === Lead.status.do_not_call ? 'remove this Lead from' : 'add this Lead to'} the Do Not Call List?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDoNotCallConfrim();
                    return;
                }
            }
        })
    }

    const onDoNotCallClick = () => {
        utils.alert.show({
            title: `Do Not Call List`,
            message: `This Lead was added to your Do Not Call list. This means that this Lead will not show up on any Dealership lead lists or reports and should not be contacted.`,
            buttons: [{
                key: 'remove',
                title: 'Remove from Do Not Call',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'default'
            }],
            onClick: async key => {
                if(key === 'remove') {
                    try {
                        await Utils.sleep(0.5);
                        onDoNotCall();
                    } catch(e) {
                        console.log(e.message);
                    }
                    return;
                }
            }
        })
    }

    const onDoNotCallConfrim = async () => {
        try {
            setLoading('options');
            await Utils.sleep(1);

            let next_status = abstract.object.status.code === Lead.status.do_not_call ? Lead.status.new : Lead.status.do_not_call;
            let { status } = await Request.post(utils, '/leads/', {
                type: 'set_status',
                id: abstract.getID(),
                status: next_status
            });

            setLoading(false);
            abstract.object.status = status;
            setLead(abstract.object);

            utils.content.fetch('lead');
            utils.alert.show({
                title: 'All Done!',
                message: `This Lead has been ${status.code === Lead.status.do_not_call ? 'added to' : 'removed from'} the Do Not Call List`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding thins Lead to the Do Not Call List. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDisqualify = () => {
        utils.alert.show({
            title: 'Disqualify Lead',
            message: 'Are you sure that you want to disqualify this Lead?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Disqualify',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDisqualifyConfirm();
                }
            }
        });
    }

    const onDisqualifyConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(1);

            let { status } = await Request.post(utils, '/leads/', {
                type: 'set_status',
                id: abstract.getID(),
                status: Lead.status.disqualified
            })

            setLoading(false);
            abstract.object.status = status;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: 'This Lead has been disqualified'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this Lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_lead_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditLead.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onLeadScriptClick = () => {
        utils.layer.open({
            id: `lead_script_editor_${lead.id}`,
            Component: LeadScriptEditor.bind(this, {
                utils: utils,
                lead: lead,
                value: lead.lead_script.text
            })
        })
    }

    const onOptionsClick = () => {

        utils.sheet.show({
            items: [{
                key: 'call_logs',
                title: 'Call Logs',
                style: 'default'
            },{
                key: 'edit',
                title: 'Edit',
                style: 'default'
            }]
        }, key => {
            if(key === 'edit') {
                onEditClick();
                return;
            }
            if(key === 'call_logs') {
                utils.layer.open({
                    id: `lead_call_logs_${abstract.getID()}`,
                    abstract: abstract,
                    Component: LeadCallLogs
                });
                return;
            }
        })
    }

    const onPriorityClick = () => {
        utils.alert.show({
            title: `Priority List`,
            message: `This Lead was added to your Priority list. Leads marked as a priority will have a blue dot next to them when shown with other leads. You can change this by editing the Lead and making a new selection for the area labeled "Priority".`,
            buttons: [{
                key: 'edit',
                title: 'Edit Lead',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'edit') {
                    onEditClick();
                    return;
                }
            }
        })
    }

    const onSetArchiveStatus = () => {
        utils.alert.show({
            title: `${lead.active ? 'Archive' : 'Unarchive'} Lead`,
            message: `Are you sure that you want to ${lead.active ? 'archive' : 'unarchive'} this Lead? ${lead.active ? 'This Lead will no longer show up within your pool of leads' : 'This Lead will be added back to your pool of leads'}.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: lead.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: lead.active ? 'default' : 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetArchiveStatusConfrim();
                    return;
                }
            }
        })
    }

    const onSetArchiveStatusConfrim = async () => {
        try {

            setLoading('options');
            await Utils.sleep(1);

            let status = !abstract.object.active;
            await Request.post(utils, '/leads/', {
                type: 'set_archive_status',
                id: abstract.getID(),
                active: status
            });

            abstract.object.active = status;
            utils.content.update(abstract);

            setLoading(false);
            setLead(abstract.object);

            utils.content.fetch('lead')
            utils.alert.show({
                title: 'All Done!',
                message: `This Lead has been ${status ? 'unarchived' : 'archived'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the archive status for this Lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        if(lead.dealership_id !== utils.dealership.get().id) {
            return null;
        }
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            loading: loading === 'options',
            onClick: onOptionsClick
        },{
            key: 'book_demo',
            text: 'Set Demo',
            color: 'primary',
            loading: loading === 'book_demo',
            onClick: onBookDemoClick
        }]
    }

    const getFields = () => {

        if(!lead) {
            return null;
        }

        let items = [{
            key: 'customer',
            title: 'Customer',
            items: [{
                key: 'id',
                title: 'ID',
                value: lead.id
            },{
                key: 'first_name',
                title: 'First Name',
                value: lead.first_name
            },{
                key: 'last_name',
                title: 'Last Name',
                value: lead.last_name
            },{
                key: 'phone_number',
                title: 'Phone Number',
                value: lead.phone_number
            },{
                key: 'email_address',
                title: 'Email Address',
                value: lead.email_address
            },{
                key: 'address',
                title: 'Physical Address',
                value: lead.address ? Utils.formatAddress(lead.address) : null
            },{
                key: 'homeowner_status',
                title: 'Homeowner Status',
                value: lead.homeowner_status ? lead.homeowner_status.text : null
            },{
                key: 'marital_status',
                title: 'Marital Status',
                value: lead.marital_status ? lead.marital_status.text : null
            },{
                key: 'occupational_status',
                title: 'Occupational Status',
                value: lead.occupational_status ? lead.occupational_status.text : null
            }]
        },{
            key: 'spouse',
            title: 'Spouse',
            items: [{
                key: 'spouse_first_name',
                title: 'First Name',
                value: lead.spouse_first_name
            },{
                key: 'spouse_last_name',
                title: 'Last Name',
                value: lead.spouse_last_name
            },{
                key: 'spouse_phone_number',
                title: 'Phone Number',
                value: lead.spouse_phone_number
            },{
                key: 'spouse_email_address',
                title: 'Email Address',
                value: lead.spouse_email_address
            }]
        }];

        if(customerResponse) {
            items = items.concat([{
                key: 'customer_response',
                title: 'Questionnaire',
                items: [{
                    key: 'date',
                    title: 'Date Submitted',
                    value: moment(lead.date).format('MMMM Do, YYYY [at] h:mma')
                }].concat(customerResponse.map((item, index) => {
                    return {
                        key: index,
                        title: item.title,
                        value: item.answer
                    }
                }))
            }])
        }

        items = items.concat([{
            key: 'location',
            title: 'Location',
            visible: lead.address && lead.location ? true : false,
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                value: lead.location,
                isValid: Utils.isAddressComplete(lead.address)
            },{
                key: 'address',
                title: Utils.isAddressComplete(lead.address) ? 'Address' : 'Approximate Location',
                value: lead.address ? Utils.formatAddress(lead.address) : null
            },{
                key: 'maps',
                title: 'Directions',
                visible: Utils.isAddressComplete(lead.address),
                button: {
                    text: 'Click to View',
                    color: 'primary',
                    onClick: () => {
                        let address = Utils.formatAddress(lead.address);
                        window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`)
                    }
                }
            }]
        },{
            key: 'lead',
            title: 'About this Lead',
            items: [{
                key: 'lead_type',
                title: 'Type',
                value: lead.lead_type ? lead.lead_type.text : null
            },{
                key: 'lead_sub_type',
                title: 'Sub-Type',
                value: lead.lead_sub_type ? lead.lead_sub_type.text : null
            },{
                key: 'lead_type',
                title: 'Script',
                value: lead.lead_script ? lead.lead_script.title : null,
                onClick: lead.lead_script ? onLeadScriptClick : null
            }]
        },{
            key: 'users',
            title: 'Users and Credit',
            items: [{
                key: 'user',
                title: 'Lead Credit',
                selected: lead.user,
                value: lead.user ? lead.user.full_name : null
            },{
                key: 'marketing_director_user',
                title: 'Marketing Director',
                value: lead.marketing_director_user ? lead.marketing_director_user.full_name : null
            },{
                key: 'enrollment_user',
                title: 'Survey Credit',
                selected: lead.enrollment_user,
                value: lead.enrollment_user ? lead.enrollment_user.full_name : null
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'archived',
                title: 'Archived',
                value: lead.active ? 'No' : 'Yes'
            },{
                key: 'dealership',
                visible: utils.user.get().level <= User.level.admin,
                title: 'Dealership',
                value: dealership ? dealership.name : null
            },{
                key: 'affiliate',
                title: 'Referred by Other Lead',
                value: lead.affiliate ? lead.affiliate.full_name : 'No'
            },{
                key: 'program_credit',
                title: 'Receieved through Program',
                selected: lead.program_credit,
                value: lead.program_credit ? lead.program_credit.name : 'No'
            },{
                key: 'out_of_service_area',
                title: 'Out of Service Area',
                value: lead.out_of_service_area ? 'Yes' : 'No'
            },{
                key: 'priority',
                title: 'Priority',
                value: lead.priority ? 'Yes' : 'No'
            },{
                key: 'tags',
                title: 'Tags',
                value: tags ? Utils.oxfordImplode(tags.map(tag => tag.text)) : null
            },{
                key: 'status',
                title: 'Status',
                value: lead.status ? lead.status.text : null
            },{
                key: 'notes',
                title: 'Notes',
                value: lead.notes
            }]
        }]);

        return items;
    }

    const getHeaderBanner = () => {

        let dealershipID = utils.dealership.get().id;
        let fields = [{
            key: 'archived',
            title: 'Archived',
            value: lead.active !== true,
            color: Appearance.colors.red,
            message: 'This Lead has been added to the Archive list',
            onClick: onArchiveClick
        },{
            key: 'do_not_call',
            title: 'Do Not Call',
            value: lead.status.code === Lead.status.do_not_call,
            color: Appearance.colors.darkRed,
            message: 'This Lead has been added to the Do Not Call list',
            onClick: onDoNotCallClick
        },{
            key: 'priority',
            title: 'Priority',
            value: lead.priority === true,
            color: Appearance.colors.primary(),
            message: 'This Lead has been marked as a Dealership priority',
            onClick: onPriorityClick
        }];

        if(!fields.find(field => field.value === true)) {
            return null;
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 12,
                width: '100%'
            }}>
                {fields.filter(field => {
                    return field.value === true;
                }).map((field, index, fields) => (
                    <div
                    key={index}
                    className={'text-button'}
                    onClick={field.onClick}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderRadius: 10,
                        overflow: 'hidden',
                        border: `2px solid ${field.color}`,
                        background: Appearance.colors.softGradient(field.color),
                        marginBottom: index === fields.length - 1 ? 12 : 8,
                        padding: '6px 10px 6px 10px',
                        width: '100%'
                    }}>
                        <img
                        src={'images/alert-icon-white-small.png'}
                        style={{
                            width: 30,
                            height: 30,
                            minWidth: 30,
                            minHeight: 30,
                            marginRight: 8
                        }} />
                        <div style={{
                            display: 'flex',
                            flexDirection:' column',
                            flexGrow: 1
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                color: 'white',
                                fontWeight: 800
                            }}>{field.title}</span>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                color: 'white',
                                fontWeight: 600
                            }}>{field.message}</span>
                        </div>
                        <img
                        src={'images/next-arrow-white-small.png'}
                        style={{
                            width: 12,
                            height: 12,
                            objectFit: 'contain',
                            marginLeft: 8
                        }} />
                    </div>
                ))}
            </div>
        )
    }

    const fetchCustomerResponse = async () => {
        if(!lead.customer_response) {
            return;
        }
        try {
            let { responses } = await Request.get(utils, '/leads/', {
                type: 'customer_response_details',
                id: lead.customer_response.id
            });
            setCustomerResponse(responses);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the questionnaire for this Lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchDealership = async () => {
        try {
            if(!abstract.object.dealership) {
                let dealership = await Dealership.get(utils, abstract.object.dealership_id);
                abstract.object.dealership = dealership;
                setDealership(dealership);
            }
        } catch(e) {
            console.log(e.message);
        }
    }

    const fetchTags = async () => {
        if(!abstract.object.tag_ids || abstract.object.tag_ids.length === 0) {
            return;
        }
        try {
            let { tags } = await Request.get(utils, '/leads/', {
                type: 'tags',
                id: lead.id
            });
            abstract.object.tags = tags;
            setTags(tags);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the questionnaire for this Lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {

        fetchTags();
        fetchDealership();
        fetchCustomerResponse();

        utils.content.subscribe(layerID, 'lead', {
            onFetch: () => {
                fetchDealership();
                fetchCustomerResponse();
            },
            onSetDealership: (next_abstract, dealership) => {
                if(next_abstract.getID() === abstract.getID()) {
                    setLead(next_abstract.object);
                    setDealership(dealership);
                }
            },
            onUpdate: abstract => {
                setLead(lead => {
                    return lead.id === abstract.getID() ? abstract.object : lead;
                });
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={abstract.getTitle()}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState
        }}
        buttons={getButtons()}>
            {getHeaderBanner()}
            <FieldMapper
            utils={utils}
            fields={getFields()}
            group={User.Group.categories.leads}/>
        </Layer>
    )
}

export const LeadLookup = ({ onAddLead }, { abstract, options, utils }) => {

    const layerID = 'lead_lookup';
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [lead, setLead] = useState(null);

    const onDoneClick = async () => {
        try {
            if(!lead) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `Please select a Lead before moving on...`
                });
                return;
            }
            setLayerState('close');
            await Utils.sleep(0.5);
            if(typeof(onAddLead) === 'function') {
                onAddLead(lead);
            }
        } catch(e) {
            console.log(e.message);
        }
    }

    return (
        <Layer
        id={layerID}
        title={'Search for a Lead'}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Done',
            color: lead ? 'primary' : 'dark',
            onClick: onDoneClick
        }]}>
            <LeadLookupField
            utils={utils}
            icon={'search'}
            placeholder={'Search by first or last name...'}
            onChange={lead => setLead(lead)} />
        </Layer>
    )
}

export const SetLeadStatus = ({ abstract, options, utils }) => {

    const layerID = `set_lead_status_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [items, setItems] = useState([]);

    const setupItems = () => {
        setItems([{
            key: 'new',
            title: 'New',
            status: Lead.status.new
        },{
            key: 'do_not_call',
            title: 'Do Not Call',
            status: Lead.status.do_not_call
        },{
            key: 'disqualified',
            title: 'Disqualified',
            status: Lead.status.disqualified
        },{
            key: 'booked',
            title: 'Booked',
            status: Lead.status.booked
        },{
            key: 'cancelled',
            title: 'Cancelled',
            status: Lead.status.cancelled
        },{
            key: 'reschedule',
            title: 'Reschedule',
            status: Lead.status.reschedule
        },{
            key: 'no_show',
            title: 'No Show',
            status: Lead.status.no_show
        },{
            key: 'completed',
            title: 'Completed',
            status: Lead.status.completed
        },{
            key: 'sold',
            title: 'Sold',
            status: Lead.status.sold
        }]);
    }

    const onSetStatus = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let { status } = await Request.post(utils, '/leads/', {
                type: 'set_status',
                id: abstract.getID(),
                status: selectedStatus
            })

            setLoading(false);
            abstract.object.status = status;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: 'The status for this Lead has been updated',
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this Lead. ${e.message || 'An unknown error occurred'}`,
            })
        }
    }

    useEffect(() => {
        setupItems();
    }, [])

    return (
        <Layer
        id={layerID}
        title={'Set Lead Status'}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Save Changes',
            color: selectedStatus ? 'primary' : 'dark',
            onClick: onSetStatus
        }]}>
            <select
            className={`custom-select ${window.theme}`}
            defaultValue={'Choose a status...'}
            onChange={e => {
                let id = Utils.attributeForKey.select(e, 'id');
                setSelectedStatus(parseInt(id));
            }}
            style={{
                width: '100%'
            }}>
                <option disabled={true}>{'Choose a status...'}</option>
                {items.map((item, index) => {
                    return (
                        <option key={index} id={item.status}>{item.title}</option>
                    )
                })}
            </select>
        </Layer>
    )
}

// Components
export const getLeadType = lead => {
    if(!lead || !lead.lead_type) {
        return null;
    }
    return lead.lead_type.text;
}

export const getLeadStatus = lead => {
    if(!lead || !lead.status) {
        return null;
    }

    let match = Lead.styles.status.find(entry => entry.status === lead.status.code);
    if(!match) {
        return null;
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            maxWidth: 95,
            textAlign: 'center',
            border: `1px solid ${match.color}`,
            background: Appearance.colors.softGradient(match.color),
            borderRadius: 5,
            overflow: 'hidden'
        }}>
            <span style={{
                ...Appearance.textStyles.subTitle(),
                color: 'white',
                fontWeight: '600',
                width: '100%'
            }}>{match.title}</span>
        </div>
    )
}

export const getLeadScore = lead => {
    if(!lead || !lead.lead_score) {
        return null;
    }

    let color = Appearance.colors.grey();
    switch(lead.lead_score) {
        case 'A':
        color = Appearance.colors.green;
        break;

        case 'B':
        color = Appearance.colors.yellow;
        break;

        case 'C':
        color = Appearance.colors.orange;
        break;

        case 'D':
        color = Appearance.colors.grey();
        break;

        case 'F':
        color = Appearance.colors.red;
        break;
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            maxWidth: 95,
            textAlign: 'center',
            border: `1px solid ${color}`,
            background: Appearance.colors.softGradient(color),
            borderRadius: 5,
            overflow: 'hidden'
        }}>
            <span style={{
                ...Appearance.textStyles.subTitle(),
                color: 'white',
                fontWeight: '600',
                width: '100%'
            }}>{lead.lead_score}</span>
        </div>
    )
}
