import React, { useState, useEffect } from 'react';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import { FloatingMenuIndex } from 'structure/Layer.js';
import { LeadDetails } from 'managers/Leads.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const LeadLookupField = ({ containerStyle, icon, inline, onChange, placeholder, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState(value !== null && value !== undefined ? value : '');

    const onValidate = () => {
        return selected ? true : false
    }

    const onClick = async selectedLead => {

        setResults([]);
        setSelected(selectedLead);
        setText(selectedLead.full_name);

        if(typeof(onChange) === 'function') {
            onChange(selectedLead);
        }
    }

    const onTextChange = text => {

        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        setText(text);
        setLoading(true);
        setSelected(false);

        setTextTimeout(setTimeout(async () => {

            if(text.length < 3) {
                setResults([]);
                setLoading(false);
                return;
            }

            try {
                let { leads } = await Request.get(utils, '/leads/', {
                    type: 'lookup',
                    limit: 100,
                    search_text: text
                })
                setLoading(false);
                setResults(leads);

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue searching for Leads. ${e.message || 'An unknown error occurred'}`
                });
            }

        }, 250));
    }

    const getStyles = () => {
        if(inline === false) {
            return {
                position: 'absolute',
                top: 43,
                left: 0,
                right: 0,
                padding: 0,
                maxHeight: 250,
                overflowY: 'scroll',
                textAlign: 'left',
                borderRadius: 8,
                zIndex: FloatingMenuIndex,
                border: `1px solid rgba(175,175,175,0.25)`,
                backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                boxShadow: window.theme === 'dark' ? '4px 4px 7px rgba(174,174,174,0.25)' : '4px 4px 7px rgba(0,0,0,0.15)'
            }
        }
        return {
            padding: 0,
            overflow: 'hidden',
            marginTop: 8,
            marginBottom: 12,
            borderRadius: 12,
            textAlign: 'left',
            border: `1px solid ${Appearance.colors.divider()}`,
            backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white'
        }
    }

    useEffect(() => {
        if(value) {
            setSelected(value);
            setText(value ? value.full_name : '');
        }
    }, [value]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <TextField
            value={text}
            icon={icon || 'search'}
            loading={loading}
            placeholder={placeholder}
            autoComplete={false}
            autoCorrect={false}
            autoCapitalize={'sentences'}
            onChange={onTextChange}
            style={{
                flexGrow: 1,
                ...Appearance.textStyles.title()
            }}/>
            {results.length > 0 && (
                <div style={getStyles()}>
                    {results.map((lead, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: lead.full_name,
                                subTitle: lead.phone_number,
                                hideIcon: true,
                                onClick: onClick.bind(this, lead),
                                style: {
                                    padding: '6px 12px 6px 12px'
                                },
                                singleItem: results.length === 1,
                                firstItem: index === 0,
                                lastItem: index !== results.length - 1,
                                rightContent: (
                                    <img
                                    src={'images/next-arrow-small.png'}
                                    style={{
                                        width: 15,
                                        height: 15,
                                        objectFit: 'contain'
                                    }}/>
                                )
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default LeadLookupField;
