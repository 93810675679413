import React from 'react';
import API from 'files/api.js';
import URLHelper from 'files/URLHelper.js';

const Request = {
    get: async (utils, url, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                let program = utils.program.get();
                let dealership = utils.dealership.get();
                props = {
                    program_id: program ? program.id : null,
                    dealership_id: dealership ? dealership.id : null,
                    ...props // props can overwrite dealership id if needed
                }

                let response = await fetch(URLHelper.getRequest(`/api/v${API.version}${url}`, props), {
                    method: 'GET',
                    withCredentials: true,
                    headers: utils.api.headers()
                });

                if(response.status !== 200) {
                    let text = await response.text();
                    throw new Error(text || 'An unknown error occurred');
                    return;
                }

                let json = await response.json();
                resolve(json);

            } catch(e) {
                reject(e);
            }
        })
    },
    post: async (utils, url, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                let program = utils.program.get();
                let dealership = utils.dealership.get();
                props = {
                    program_id: program ? program.id : null,
                    dealership_id: dealership ? dealership.id : null,
                    ...props // props can overwrite dealership id if needed
                }

                let response = await fetch(`/api/v${API.version}${url}`, {
                    method: 'POST',
                    withCredentials: true,
                    headers: utils.api.headers(),
                    body: JSON.stringify(props)
                });

                // check for conflicts and allow user to respond
                if(response.status === 409) {
                    let json = await response.json();
                    let error = new Error();
                    error.code = response.status;
                    error.message = json.message;
                    error.response = json;
                    throw error;
                    return;
                }

                if(response.status !== 200) {
                    let text = await response.text();
                    throw new Error(text || 'An unknown error occurred');
                    return;
                }

                let json = await response.json();
                resolve(json);

            } catch(e) {
                reject(e);
            }
        })
    }
}
export default Request;
