import React from 'react';
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';

class FeedbackClass {

    constructor() {
        return this;
    }

    create = (props = {}) => {
        return this;
    }
}

class TemplateClass {

    id = null;
    dealership = null;
    dealership_id = null;
    title = null;
    description = null;
    date = null;
    props = {};
    questions = [];
    is_default = false;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.dealership = props.dealership ? Dealership.create(props.dealership) : null;
        this.dealership_id = props.dealership_id;
        this.title = props.title;
        this.description = props.description;
        this.date = props.date ? moment(props.date) : null;
        this.props = props.props || {};
        this.questions = props.questions ? props.questions.map(question => new TemplateQuestionClass().create(question)) : [];
        this.is_default = props.is_default;
        this.active = props.active;
        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                dealership: this.dealership,
                title: this.title,
                description: this.description,
                props: this.props,
                questions: this.questions
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                dealership: props.dealership || this.edits.dealership,
                title: props.title || this.edits.title,
                description: props.description || this.edits.description,
                props: props.props || this.edits.props,
                questions: props.questions || this.edits.questions
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            let target = this.edits || this;
            this.dealership = target.dealership || this.dealership;
            this.title = target.title || this.title;
            this.description = target.description || this.description;
            this.props = target.props || this.props;
            this.questions = target.questions || this.questions;
            resolve(this);
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id, questions } = await Request.post(utils, '/dealerships/', {
                    type: 'new_feedback_template',
                    ...this.toJSON(this.edits)
                });
                await this.close();
                this.id = id;
                this.questions = questions ? questions.map(question => new TemplateQuestionClass().create(question)) : [];

                utils.content.fetch('feedback_template');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { questions } = await Request.post(utils, '/dealerships/', {
                    type: 'update_feedback_template',
                    id: this.id,
                    ...this.toJSON(this.edits)
                });
                await this.close();
                this.questions = questions ? questions.map(question => new TemplateQuestionClass().create(question)) : [];

                utils.content.update(Abstract.create({
                    type: 'feedback_template',
                    object: this
                }));
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            dealership_id: target.dealership ? target.dealership.id : null,
            title: target.title,
            description: target.description,
            props: target.props,
            questions: target.questions ? target.questions.map(question => {
                return {
                    id: question.id,
                    required: question.required,
                    type: question.type,
                    title: question.title,
                    props: question.props,
                    order_index: question.order_index,
                    dealership_id: question.dealership_id
                }
            }) : null
        }
    }
}

class TemplateQuestionClass {

    id = null;
    required = null;
    type = null;
    title = null;
    props = {};
    dealership_id = null;
    order_index = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.required = props.required;
        this.type = props.type;
        this.title = props.title;
        this.props = props.props || {};
        this.dealership_id = props.dealership_id;
        this.order_index = props.order_index;
        return this;
    }
}

class FeedbackResponseClass {

    id = null;
    title = null;
    full_name = null;
    start_date = null;
    demo_id = null;
    dealership_id = null;
    template_id = null;
    date = null;
    props = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.full_name = props.full_name;
        this.start_date = props.start_date ? moment(props.start_date) : null;
        this.demo_id = props.demo_id;
        this.dealership_id = props.dealership_id;
        this.template_id = props.template_id;
        this.date = props.date ? moment(props.date) : null;
        this.props = props.props;
        return this;
    }
}

export default {
    new: () => new FeedbackClass(),
    create: props => new FeedbackClass().create(props),
    Template: {
        new: () => new TemplateClass(),
        create: props => new TemplateClass().create(props),
        Question: {
            new: () => new TemplateQuestionClass(),
            create: props => new TemplateQuestionClass().create(props),
            type: {
                list: 1,
                checkboxes: 2,
                picker: 3,
                textfield: 4,
                textview: 5
            }
        }
    },
    Response: {
        new: () => new FeedbackResponseClass(),
        create: props => new FeedbackResponseClass().create(props),
    }
}
