import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Appearance from 'styles/Appearance.js';
import TextField from 'views/TextField.js';

const DualDatePickerField = (props) => {

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    const onFieldClick = field => {
        props.utils.datePicker.show({
            date: field === 'start' ? selectedStartDate : selectedEndDate,
            ...props,
            onDateChange: date => onDateChange(field, date),
            ...(props.blockStyle ? {
                highlightDates: props.highlightDates || [selectedStartDate, selectedEndDate]
            } : null)
        })
    }

    const onDateChange = (field, date) => {

        if(field === 'start') {
            setSelectedStartDate(date);
            if(typeof(props.onStartDateChange) === 'function') {
                props.onStartDateChange(moment(date));
            }
        }
        if(field === 'end') {
            setSelectedEndDate(date);
            if(typeof(props.onEndDateChange) === 'function') {
                props.onEndDateChange(moment(date));
            }
        }
        runBlockStyle(field, date);
    }

    const runBlockStyle = (field, date) => {
        if(!props.blockStyle) {
            return;
        }
        switch(props.blockStyle) {
            case 'week':

            let startDate = moment(date).startOf('week');
            let endDate = moment(date).endOf('week');

            setSelectedStartDate(startDate)
            setSelectedEndDate(endDate);

            if(typeof(props.onStartDateChange) === 'function') {
                props.onStartDateChange(startDate);
            }
            if(typeof(props.onEndDateChange) === 'function') {
                props.onEndDateChange(endDate);
            }
            break;
        }
    }

    useEffect(() => {
        setSelectedStartDate(props.selectedStartDate ? moment(props.selectedStartDate) : null);
        setSelectedEndDate(props.selectedEndDate ? moment(props.selectedEndDate) : null);
    }, [props.selectedStartDate, props.selectedEndDate]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: 'auto',
            ...props.style
        }}>
            <div
            onClick={onFieldClick.bind(this, 'start')}
            className={`dummy-field ${window.theme} cursor-pointer`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'relative',
                minWidth: 150,
                height: 35,
                border: `1px solid ${Appearance.colors.divider()}`,
                backgroundColor: Appearance.colors.textField(),
                ...props.style
            }}>
                <span style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: Appearance.colors.text(),
                    textAlign: 'center',
                    width: '100%'
                }}>{selectedStartDate ? moment(selectedStartDate).format(props.dateFormat || 'MMMM Do, YYYY') : 'Start Date'}</span>
            </div>
            <img
            src={'images/next-arrow-grey.png'}
            style={{
                width: 35,
                height: 35,
                minWidth: 35,
                minHeight: 35,
                padding: 10,
                objectFit: 'contain'
            }} />
            <div
            onClick={onFieldClick.bind(this, 'end')}
            className={`dummy-field ${window.theme} cursor-pointer`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'relative',
                minWidth: 150,
                height: 35,
                border: `1px solid ${Appearance.colors.divider()}`,
                backgroundColor: Appearance.colors.textField(),
                ...props.style
            }}>
                <span style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: Appearance.colors.text(),
                    textAlign: 'center',
                    width: '100%'
                }}>{selectedEndDate ? moment(selectedEndDate).format(props.dateFormat || 'MMMM Do, YYYY') : 'End Date'}</span>
            </div>
        </div>
    )
}
export default DualDatePickerField;
