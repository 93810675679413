class ContentManager {

    subscribers = {};

    fetch = type => {
        Object.values(this.subscribers).forEach(subscriber => {
            if((Array.isArray(subscriber.type) ? subscriber.type.includes(type) : subscriber.type === type) && subscriber.callbacks.onFetchContent && typeof(subscriber.callbacks.onFetchContent) === 'function') {
                subscriber.callbacks.onFetchContent(type);
            }
        })
    }
    set = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {
            if((Array.isArray(subscriber.type) ? subscriber.type.includes(abstract.type) : subscriber.type === abstract.type) && subscriber.callbacks.onSetContent && typeof(subscriber.callbacks.onSetContent) === 'function') {
                subscriber.callbacks.onSetContent(abstract);
            }
        })
    }
    exists = async (type, id) => {
        return new Promise(async (resolve, reject) => {
            try {
                let subscribers = Object.values(this.subscribers).filter(subscriber => {
                    return typeof(subscriber.callbacks.onContentExists) === 'function'
                    && (Array.isArray(subscriber.type) ? subscriber.type.includes(type) : subscriber.type === type);
                });

                if(subscribers.length === 0) {
                    resolve(null);
                }
                for(var i in subscribers) {
                    let object = await subscribers[i].callbacks.onContentExists(type, id);
                    if(object) {
                        resolve(object);
                        return;
                    }
                }
                resolve(null);

            } catch(e) {
                reject(e);
            }
        })
        return this;
    }
    update = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {
            if((Array.isArray(subscriber.type) ? subscriber.type.includes(abstract.type) : subscriber.type === abstract.type) && subscriber.callbacks.onUpdateContent && typeof(subscriber.callbacks.onUpdateContent) === 'function') {
                subscriber.callbacks.onUpdateContent(abstract);
            }
        })
    }
    upsert = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {
            if((Array.isArray(subscriber.type) ? subscriber.type.includes(abstract.type) : subscriber.type === abstract.type) && subscriber.callbacks.onUpsertContent && typeof(subscriber.callbacks.onUpsertContent) === 'function') {
                subscriber.callbacks.onUpsertContent(abstract);
            }
        })
        return this;
    }
    refresh = type => {
        Object.values(this.subscribers).forEach(subscriber => {
            if((Array.isArray(subscriber.type) ? subscriber.type.includes(type) : subscriber.type === type) && subscriber.callbacks.onRefreshContent && typeof(subscriber.callbacks.onRefreshContent) === 'function') {
                subscriber.callbacks.onRefreshContent(type);
            }
        })
    }
    remove = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {
            if((Array.isArray(subscriber.type) ? subscriber.type.includes(abstract.type) : subscriber.type === abstract.type) && subscriber.callbacks.onRemoveContent && typeof(subscriber.callbacks.onRemoveContent) === 'function') {
                subscriber.callbacks.onRemoveContent(abstract);
            }
        })
    }

    subscribe = (id, type, callbacks) => {
        this.subscribers[id] = {
            type: type,
            callbacks: {
                ...{ onAddContent: callbacks.onAdd },
                ...{ onSetContent: callbacks.onSet },
                ...{ onFetchContent: callbacks.onFetch },
                ...{ onRemoveContent: callbacks.onRemove },
                ...{ onUpdateContent: callbacks.onUpdate },
                ...{ onUpsertContent: callbacks.onUpsert },
                ...{ onContentExists: callbacks.onExists }
            }
        };
    }

    unsubscribe = (id) => {
        delete this.subscribers[id];
    }
}
export default {
    new: () => new ContentManager()
};
