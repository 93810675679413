import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Appearance from 'styles/Appearance.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';
import Utils from 'files/Utils.js';

const AddressLookupField = ({ address, containerStyle, icon, geocode, onChange, placeholder, showValidation, utils, value }) => {

    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState('');

    const onValidate = () => {
        return selected ? true : false
    }

    const onClick = async place => {

        setResults([]);
        setSelected(place);
        setText(place.address);
        setSession(uuidv4());

        if(geocode === true)  {
            try {
                setLoading(true);
                let result = await Utils.geocode(utils, place.location);

                setLoading(false);
                if(typeof(onChange) === 'function') {
                    onChange({
                        ...place,
                        ...result
                    });
                }

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue locating the information for this address. ${e.message || 'An unknown error occurred'}`
                });
            }
            return;
        }

        if(typeof(onChange) === 'function') {
            onChange(place);
        }
    }

    const onTextChange = text => {

        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        setText(text);
        setLoading(true);
        setSelected(false);

        setTextTimeout(setTimeout(async () => {

            if(text.length < 3) {
                setResults([]);
                setLoading(false);
                return;
            }

            try {
                let { results } = await Utils.addressLookup(utils, text, session);
                setLoading(false);
                setResults(results);

            } catch(e) {

                console.log(e.message);
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue searching for your location. ${e.message || 'An unknown error occurred'}`
                });
            }

        }, 250));
    }

    useEffect(() => {
        if(address) {
            setSelected(address);
        }
    }, [address]);

    useEffect(() => {
        if(value && typeof(value) === 'string') {
            setText(value);
            return;
        }
        if(value && typeof(value) === 'object') {
            setSelected(value);
            setText(Utils.formatAddress(value));
            return;
        }
    }, [value]);

    useEffect(() => {
        setSession(uuidv4());
    }, [])

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <TextField
            value={text}
            icon={icon || 'house'}
            loading={loading}
            onValidate={showValidation ? onValidate : null}
            placeholder={placeholder}
            autoComplete={false}
            autoCorrect={false}
            autoCapitalize={'sentences'}
            onChange={onTextChange}
            style={{
                flexGrow: 1,
                ...Appearance.textStyles.title()
            }}/>
            {results.length > 0
                ?
                <div
                style={{
                    padding: 0,
                    overflow: 'hidden',
                    marginTop: 8,
                    marginBottom: 12,
                    borderRadius: 12,
                    textAlign: 'left',
                    border: `1px solid ${Appearance.colors.divider()}`,
                    backgroundColor: Appearance.colors.textField()
                }}>
                    {results.map((location, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: location.name,
                                subTitle: location.address,
                                hideIcon: true,
                                onClick: onClick.bind(this, location),
                                style: {
                                    padding: '6px 12px 6px 12px'
                                },
                                singleItem: results.length === 1,
                                firstItem: index === 0,
                                lastItem: index !== results.length - 1,
                                rightContent: (
                                    <img
                                    src={'images/next-arrow-small.png'}
                                    style={{
                                        width: 15,
                                        height: 15,
                                        objectFit: 'contain'
                                    }}/>
                                )
                            })
                        )
                    })}
                </div>
                :
                null
            }
        </div>
    )
}
export default AddressLookupField;
