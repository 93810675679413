import React from 'react';
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Dealership from 'classes/Dealership.js';
import Program from 'classes/Program.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class LeadClass {

    id = null;
    dealership = null;
    first_name = null;
    last_name = null;
    full_name = null;
    phone_number = null;
    email_address = null;
    spouse_first_name = null;
    spouse_last_name = null;
    spouse_phone_number = null;
    spouse_email_address = null;
    address = null;
    location = null;
    dealership_id = null;
    lead_type = null;
    lead_sub_type = null;
    lead_script = null;
    notes = null;
    user = null;
    enrollment_user = null;
    marketing_director_user = null;
    created = null;
    timezone = null;
    status = null;
    program = null;
    opportunity = null;
    priority = null;
    program_credit = null;
    homeowner_status = null;
    marital_status = null;
    occupational_status = null;
    out_of_service_area = false;
    active = null;
    affiliate = null;
    transfer = null;
    tags = null;
    tag_ids = null;
    customer_response = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.first_name = props.first_name;
        this.last_name = props.last_name;
        this.full_name = props.full_name;
        this.phone_number = props.phone_number;
        this.email_address = props.email_address;
        this.spouse_first_name = props.spouse_first_name;
        this.spouse_last_name = props.spouse_last_name;
        this.spouse_phone_number = props.spouse_phone_number;
        this.spouse_email_address = props.spouse_email_address;
        this.address = props.address;
        this.location = props.location ? {
            latitude: props.location.lat || props.location.latitude,
            longitude: props.location.long || props.location.longitude
        } : null;
        this.dealership_id = props.dealership_id;
        this.lead_type = props.lead_type;
        this.lead_sub_type = props.lead_sub_type;
        this.lead_script = props.lead_script;
        this.notes = props.notes;
        this.user = props.user;
        this.enrollment_user = props.enrollment_user ? User.create(props.enrollment_user) : null;
        this.marketing_director_user = props.marketing_director_user ? User.create(props.marketing_director_user) : null;
        this.created = props.created;
        this.timezone = props.timezone;
        this.status = props.status;
        this.program = props.program;
        this.opportunity = props.opportunity;
        this.priority = props.priority;
        this.program_credit = props.program_credit ? Program.create(props.program_credit) : null;
        this.homeowner_status = props.homeowner_status;
        this.marital_status = props.marital_status;
        this.occupational_status = props.occupational_status;
        this.out_of_service_area = props.out_of_service_area;
        this.active = props.active;
        this.transfer = props.transfer;
        this.affiliate = props.affiliate ? new LeadClass().create(props.affiliate) : null;
        this.tag_ids = props.tags;
        this.customer_response = props.customer_response ? new CustomerResponseClass().create(props.customer_response) : null;

        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                dealership: this.dealership,
                first_name: this.first_name,
                last_name: this.last_name,
                full_name: this.full_name,
                email_address: this.email_address,
                phone_number: this.phone_number,
                spouse_first_name: this.spouse_first_name,
                spouse_last_name: this.spouse_last_name,
                spouse_email_address: this.spouse_email_address,
                spouse_phone_number: this.spouse_phone_number,
                address: this.address,
                location: this.location,
                user: this.user,
                enrollment_user: this.enrollment_user,
                marketing_director_user: this.marketing_director_user,
                affiliate: this.affiliate,
                program_credit: this.program_credit,
                lead_type: this.lead_type,
                lead_sub_type: this.lead_sub_type,
                lead_script: this.lead_script,
                homeowner_status: this.homeowner_status,
                marital_status: this.marital_status,
                occupational_status: this.occupational_status,
                priority: this.priority,
                out_of_service_area: this.out_of_service_area,
                tags: this.tags,
                notes: this.notes
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                dealership: props.dealership || this.edits.dealership,
                first_name: props.first_name || this.edits.first_name,
                last_name: props.last_name || this.edits.last_name,
                email_address: props.email_address || this.edits.email_address,
                phone_number: props.phone_number || this.edits.phone_number,
                spouse_first_name: props.spouse_first_name || this.edits.spouse_first_name,
                spouse_last_name: props.spouse_last_name || this.edits.spouse_last_name,
                spouse_email_address: props.spouse_email_address || this.edits.spouse_email_address,
                spouse_phone_number: props.spouse_phone_number || this.edits.spouse_phone_number,
                address: props.address !== undefined ? props.address : this.edits.address,
                location: props.location !== undefined ? props.location : this.edits.location,
                user: props.user !== undefined ? props.user : this.edits.user,
                enrollment_user: props.enrollment_user !== undefined ? props.enrollment_user : this.edits.enrollment_user,
                marketing_director_user: props.marketing_director_user !== undefined ? props.marketing_director_user : this.edits.marketing_director_user,
                affiliate: props.affiliate !== undefined ? props.affiliate : this.edits.affiliate,
                program_credit: props.program_credit !== undefined ? props.program_credit : this.edits.program_credit,
                lead_type: props.lead_type !== undefined ? props.lead_type : this.edits.lead_type,
                lead_sub_type: props.lead_sub_type !== undefined ? props.lead_sub_type : this.edits.lead_sub_type,
                lead_script: props.lead_script !== undefined ? props.lead_script : this.edits.lead_script,
                homeowner_status: props.homeowner_status !== undefined ? props.homeowner_status : this.edits.homeowner_status,
                marital_status: props.marital_status !== undefined ? props.marital_status : this.edits.marital_status,
                occupational_status: props.occupational_status !== undefined ? props.occupational_status : this.edits.occupational_status,
                priority: props.priority !== undefined ? props.priority : this.edits.priority,
                out_of_service_area: props.out_of_service_area !== undefined ? props.out_of_service_area : this.edits.out_of_service_area,
                tags: props.tags || this.edits.tags,
                notes: props.notes || this.edits.notes
            }
            if(this.edits.first_name && this.edits.last_name) {
                this.edits.full_name = `${this.edits.first_name} ${this.edits.last_name}`;
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.dealership = this.edits.dealership || this.dealership;
            this.first_name = this.edits.first_name || this.first_name;
            this.last_name = this.edits.last_name || this.last_name;
            this.full_name = this.edits.full_name || this.full_name;
            this.email_address = this.edits.email_address || this.email_address;
            this.phone_number = this.edits.phone_number || this.phone_number;
            this.spouse_first_name = this.edits.spouse_first_name || this.spouse_first_name;
            this.spouse_last_name = this.edits.spouse_last_name || this.spouse_last_name;
            this.spouse_email_address = this.edits.spouse_email_address || this.spouse_email_address;
            this.spouse_phone_number = this.edits.spouse_phone_number || this.spouse_phone_number;
            this.address = this.edits.address || this.address;
            this.location = this.edits.location || this.location;
            this.user = this.edits.user || this.user;
            this.enrollment_user = this.edits.enrollment_user !== undefined ? this.edits.enrollment_user : this.enrollment_user;
            this.marketing_director_user = this.editsmarketing_director_user || this.marketing_director_user;
            this.affiliate = this.edits.affiliate !== undefined ? this.edits.affiliate : this.affiliate;
            this.program_credit = this.edits.program_credit !== undefined ? this.edits.program_credit : this.program_credit;
            this.lead_type = this.edits.lead_type !== undefined ? this.edits.lead_type : this.lead_type;
            this.lead_sub_type = this.edits.lead_sub_type !== undefined ? this.edits.lead_sub_type : this.lead_sub_type;
            this.lead_script = this.edits.lead_script !== undefined ? this.edits.lead_script : this.lead_script;
            this.homeowner_status = this.edits.homeowner_status !== undefined ? this.edits.homeowner_status : this.homeowner_status;
            this.marital_status = this.edits.marital_status !== undefined ? this.edits.marital_status : this.marital_status;
            this.occupational_status = this.edits.occupational_status !== undefined ? this.edits.occupational_status : this.occupational_status;
            this.priority = this.edits.priority !== undefined ? this.edits.priority : this.priority;
            this.out_of_service_area = this.edits.out_of_service_area !== undefined ? this.edits.out_of_service_area : this.out_of_service_area;
            this.tags = this.edits.tags || this.tags;
            this.notes = this.edits.notes || this.notes;
            resolve();
        })
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/leads/', {
                    type: 'new',
                    ...this.toJSON(this.edits),
                    ...props
                })

                this.id = id;
                await this.close();
                utils.content.fetch('lead');

                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/leads/', {
                    type: 'update',
                    id: this.id,
                    ...this.toJSON(this.edits)
                })

                await this.close();
                utils.content.fetch('demo');
                utils.content.update(Abstract.create({
                    type: 'lead',
                    object: this
                }));
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            id: this.id,
            dealership_id: target.dealership ? target.dealership.id : null,
            first_name: target.first_name,
            last_name: target.last_name,
            email_address: target.email_address,
            phone_number: target.phone_number,
            spouse_first_name: target.spouse_first_name,
            spouse_last_name: target.spouse_last_name,
            spouse_email_address: target.spouse_email_address,
            spouse_phone_number: target.spouse_phone_number,
            address: target.address,
            location: target.location,
            user_id: target.user ? target.user.user_id : null,
            enrollment_user: target.enrollment_user ? target.enrollment_user.user_id : null,
            marketing_director_user: target.marketing_director_user ? target.marketing_director_user.user_id : null,
            affiliate: target.affiliate ? target.affiliate.id : null,
            program_credit: target.program_credit ? target.program_credit.id : null,
            lead_type: target.lead_type ? target.lead_type.id : null,
            lead_sub_type: target.lead_sub_type ? target.lead_sub_type.code : null,
            lead_script: target.lead_script ? target.lead_script.code : null,
            homeowner_status: target.homeowner_status ? target.homeowner_status.code : null,
            marital_status: target.marital_status ? target.marital_status.code : null,
            occupational_status: target.occupational_status ? target.occupational_status.code : null,
            priority: target.priority,
            out_of_service_area: target.out_of_service_area,
            tags: target.tags ? target.tags.map(tag => tag.id) : null,
            notes: target.notes
        }
    }
}

class LeadProgramClass {

    id = null;
    lead_id = null;
    program = null;
    start_date = null;
    end_date = null;
    completed = null;
    funded = null;
    private = null;
    status = null;
    notes = null;
    avatar = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.lead_id = props.lead_id;
        this.program = props.program ? Program.create(props.program) : null;
        this.start_date = props.start_date;
        this.end_date = props.end_date;
        this.completed = props.completed;
        this.funded = props.funded;
        this.private = props.private;
        this.status = props.status;
        this.notes = props.notes;
        this.avatar = props.avatar;
        return this;
    }
}

class CustomerResponseClass {

    id = null;
    program_id = null;
    lead_id = null;
    date = null;
    props = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.program_id = props.program_id;
        this.lead_id = props.lead_id;
        this.date = props.date ? moment(props.date) : null;
        this.props = props.props;
        return this;
    }
}

class TransferClass {

    id = null;
    user = null;
    lead = null;
    from_dealership = null;
    to_dealership = null;
    date = null;
    status = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.user = props.user ? User.create(props.user) : null;
        this.lead = props.lead ? new LeadClass().create(props.lead) : null;
        this.from_dealership = props.from_dealership ? Dealership.create(props.from_dealership) : null;
        this.to_dealership = props.to_dealership ? Dealership.create(props.to_dealership) : null;
        this.date = props.date ? moment(props.date) : null;
        this.status = props.status;
        return this;
    }
}

const fetchLead = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { lead } = await Request.get(utils, '/leads/', {
                type: 'details',
                id: id
            });
            let obj = new LeadClass().create(lead);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    })
}

const statusCodes = {
    pending: -1,
    new: 1,
    set: 100,
    assigned: 125,
    confirmed: 150,
    sale: 200,
    recall: 250,
    do_not_call: 275,
    did_not_sell: 300,
    not_home: 400,
    not_interested: 450,
    incomplete: 500,
    wrong_number: 525,
    left_voicemail: 550,
    rescheduled: 700,
    cancelled: 800,
    turndown: 900,
    unqualified: 1000
};

const statusCodeEntries = [{
    key: statusCodes.new,
    title: 'New',
    color: '#003A5D',
    status: statusCodes.new
},{
    key: statusCodes.pending,
    title: 'Pending',
    color: Appearance.colors.lighterGrey,
    status: statusCodes.pending
},{
    key: statusCodes.confirmed,
    title: 'Confirmed',
    color: Appearance.colors.primary(),
    status: statusCodes.confirmed
},{
    key: statusCodes.assigned,
    title: 'Assigned',
    color: Appearance.colors.tertiary(),
    status: statusCodes.assigned
},{
    key: statusCodes.set,
    title: 'Set',
    color: Appearance.colors.secondary(),
    status: statusCodes.set
},{
    key: statusCodes.sale,
    title: 'Sale',
    color: '#6ABF4B',
    status: statusCodes.sale
},{
    key: statusCodes.recall,
    title: 'Recall',
    color: Appearance.colors.lightGrey,
    status: statusCodes.recall
},{
    key: statusCodes.do_not_call,
    title: 'Do Not Call',
    color: Appearance.colors.darkRed,
    status: statusCodes.do_not_call
},{
    key: statusCodes.did_not_sell,
    title: 'Did Not Sell',
    color: Appearance.colors.orange,
    status: statusCodes.did_not_sell
},{
    key: statusCodes.not_home,
    title: 'Not Home',
    color: Appearance.colors.lightGrey,
    status: statusCodes.not_home
},{
    key: statusCodes.not_interested,
    title: 'Not Interested',
    color: Appearance.colors.darkGrey,
    status: statusCodes.not_interested
},{
    key: statusCodes.incomplete,
    title: 'Incomplete',
    color: Appearance.colors.yellow,
    status: statusCodes.incomplete
},{
    key: statusCodes.wrong_number,
    title: 'Wrong Number',
    color: Appearance.colors.lightGrey,
    status: statusCodes.wrong_number
},{
    key: statusCodes.left_voicemail,
    title: 'Left Voicemail',
    color: Appearance.colors.lightGrey,
    status: statusCodes.left_voicemail
},{
    key: statusCodes.rescheduled,
    title: 'Rescheduled',
    color: '#7193BB',
    status: statusCodes.rescheduled
},{
    key: statusCodes.cancelled,
    title: 'Cancelled',
    color: Appearance.colors.red,
    status: statusCodes.cancelled
},{
    key: statusCodes.turndown,
    title: 'Turndown',
    color: Appearance.colors.darkGrey,
    status: statusCodes.turndown
},{
    key: statusCodes.unqualified,
    title: 'Unqualified',
    color: Appearance.colors.darkRed,
    status: statusCodes.unqualified
}];

export default {
    new: () => new LeadClass(),
    get: (utils, id) => fetchLead(utils, id),
    create: props => new LeadClass().create(props),
    status: statusCodes,
    styles: {
        status: statusCodeEntries,
        colorForStatus: code => {
            let match = statusCodeEntries.find(entry => entry.status === code);
            return match ? match.color : Appearance.colors.grey();
        }
    },
    Transfer: {
        new: () => new TransferClass(),
        create: props => new TransferClass().create(props),
        status: {
            in_progress: 1,
            accepted: 2,
            declined: 3,
            inactive: 4
        }
    },
    Program: {
        new: () => new LeadProgramClass(),
        create: props => new LeadProgramClass().create(props),
        status: {
            in_progress: 1,
            completed: 2,
            funded: 3,
            revoked: 4
        }
    }
}
