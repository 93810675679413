import React from 'react';
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class DealershipClass {

    id = null;
    name = null;
    address = null;
    location = null;
    dealer = null;
    timezone = null;
    active = null;
    avatar = null;

    cosntructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.address = props.address;
        this.location = props.location ? {
            latitude: props.location.lat,
            longitude: props.location.long
        } : null;
        this.dealer = props.dealer ? User.create(props.dealer) : null;
        this.timezone = props.timezone;
        this.active = props.active;
        this.avatar = props.avatar;
        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                name: this.name,
                address: this.address,
                location: this.location,
                dealer: this.dealer,
                timezone: this.timezone
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                name: props.name || this.edits.name,
                address: props.address ? {
                    address: props.address.address,
                    city: props.address.city,
                    state: props.address.state,
                    zipcode: props.address.zipcode,
                    country: props.address.country
                } : this.edits.address,
                location: props.address ? {
                    latitude: props.address.location.latitude,
                    longitude: props.address.location.longitude
                } : this.edits.location,
                dealer: props.dealer || this.edits.dealer,
                timezone: props.timezone || this.edits.timezone
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.name = this.edits.name || this.name;
            this.address = this.edits.address || this.address;
            this.location = this.edits.location || this.location;
            this.dealer = this.edits.dealer || this.dealer;
            this.timezone = this.edits.timezone || this.timezone;
            resolve();
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/dealerships/', {
                    type: 'new',
                    ...this.toJSON(this.edits)
                })

                this.id = id;
                await this.close();
                utils.content.fetch('dealership');

                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/dealerships/', {
                    type: 'update',
                    ...this.toJSON(this.edits)
                })

                await this.close();
                utils.content.update(Abstract.create({
                    type: 'dealership',
                    object: this
                }));
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            id: this.id,
            name: target.name,
            address: target.address,
            location: target.location,
            dealer_user_id: target.dealer ? target.dealer.user_id : null,
            timezone: target.timezone
        }
    }
}

class TemplateClass {

    id = null;
    dealership = null;
    dealership_id = null;
    title = null;
    description = null;
    date = null;
    props = {};
    questions = [];
    is_program = null;
    used_by = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.dealership = props.dealership ? new DealershipClass().create(props.dealership) : null;
        this.dealership_id = props.dealership_id;
        this.title = props.title;
        this.description = props.description;
        this.date = props.date ? moment(props.date) : null;
        this.props = props.props || {};
        this.questions = props.questions ? props.questions.map(question => new TemplateQuestionClass().create(question)) : [];
        this.is_program = props.is_program;
        this.used_by = props.used_by || 0;
        this.active = props.active;
        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                dealership: this.dealership,
                title: this.title,
                description: this.description,
                props: this.props,
                questions: this.questions,
                is_program: this.is_program
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                dealership: props.dealership || this.edits.dealership,
                title: props.title || this.edits.title,
                description: props.description || this.edits.description,
                props: props.props || this.edits.props,
                questions: props.questions || this.edits.questions,
                is_program: typeof(props.is_program) === 'boolean' ? props.is_program : this.edits.is_program
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            let target = this.edits || this;
            this.dealership = target.dealership || this.dealership;
            this.title = target.title || this.title;
            this.description = target.description || this.description;
            this.props = target.props || this.props;
            this.questions = target.questions || this.questions;
            this.is_program = typeof(target.is_program) === 'boolean' ? target.is_program : this.is_program;
            resolve(this);
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id, questions } = await Request.post(utils, '/dealerships/', {
                    type: 'new_template',
                    ...this.toJSON(this.edits)
                });
                await this.close();
                this.id = id;
                this.questions = questions ? questions.map(question => new TemplateQuestionClass().create(question)) : [];

                utils.content.fetch('template');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { questions } = await Request.post(utils, '/dealerships/', {
                    type: 'update_template',
                    id: this.id,
                    ...this.toJSON(this.edits)
                });
                await this.close();
                this.questions = questions ? questions.map(question => new TemplateQuestionClass().create(question)) : [];

                utils.content.update(Abstract.create({
                    type: 'template',
                    object: this
                }));
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            dealership_id: target.dealership ? target.dealership.id : null,
            title: target.title,
            description: target.description,
            props: target.props,
            is_program: target.is_program,
            questions: target.questions ? target.questions.map(question => {
                return {
                    id: question.id,
                    required: question.required,
                    type: question.type,
                    title: question.title,
                    props: question.props,
                    order_index: question.order_index,
                    dealership_id: question.dealership_id
                }
            }) : null
        }
    }
}

class TemplateQuestionClass {

    id = null;
    required = null;
    type = null;
    title = null;
    props = {};
    dealership_id = null;
    order_index = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.required = props.required;
        this.type = props.type;
        this.title = props.title;
        this.props = props.props || {};
        this.dealership_id = props.dealership_id;
        this.order_index = props.order_index;
        return this;
    }
}

export const fetchDealership = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { dealership } = await Request.get(utils, '/dealerships/', {
                type: 'details',
                id: id
            });
            resolve(new DealershipClass().create(dealership));
        } catch(e){
            reject(e);
        }
    })
}

export default {
    new: () => new DealershipClass(),
    get: (utils, id) => fetchDealership(utils, id),
    create: props => new DealershipClass().create(props),
    Template: {
        new: () => new TemplateClass(),
        create: props => new TemplateClass().create(props),
        Question: {
            new: () => new TemplateQuestionClass(),
            create: props => new TemplateQuestionClass().create(props),
            type: {
                list: 1,
                checkboxes: 2,
                picker: 3,
                textfield: 4,
                textview: 5
            }
        }
    }
}
