import React, { useRef, useState, useEffect } from 'react';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import Layer from 'structure/Layer.js';
import TextView from 'views/TextView.js';
import Utils from 'files/Utils.js';

export const config = {
    fontSize: 12
}

export const getKeywords = args => {

    const { editable, lead, utils } = args;
    const getResponseValue = key => {
        let questions = {
            age: 1,
            marital_status: 2,
            home_ownership: 3,
            employement: 4
        }
        if(!lead || !lead.customer_response || !questions[key]) {
            return null;
        }
        let question = lead.customer_response.props.find(question => {
            return question.id === questions[key];
        });
        return question ? question.value : null;
    }

    return [{
        key: '[DEALERSHIP_NAME]',
        title: 'Dealership Name',
        category: 'dealership',
        description: `the dealership's name`,
        value: utils.dealership.get().name
    },{
        key: '[DEALERSHIP_ADDRESS]',
        title: 'Dealership Physical Address',
        category: 'dealership',
        description: `the dealership's physical address`,
        value: utils.dealership.get().address ? Utils.formatAddress(utils.dealership.get().address) : `${editable ? '':'Unknown '}Dealership Address`
    },{
        key: '[FIRST_NAME]',
        title: 'First Name',
        category: 'user',
        description: 'the first name for the presenter of the script',
        value: utils.user.get().first_name
    },{
        key: '[LAST_NAME]',
        title: 'Last Name',
        category: 'user',
        description: 'the last name for the presenter of the script',
        value: utils.user.get().last_name
    },{
        key: '[FULL_NAME]',
        title: 'Full Name',
        category: 'user',
        description: 'the first and last name for the presenter of the script',
        value: utils.user.get().full_name
    },{
        key: '[ADDRESS]',
        title: 'Physical Address',
        category: 'user',
        description: 'the physical address for the presenter of the script',
        value: Utils.formatAddress(utils.user.get().address) || `${editable ? '':'Unknown '}Address`
    },{
        key: '[EMAIL_ADDRESS]',
        title: 'Email Address',
        category: 'user',
        description: 'the email address for the presenter of the script',
        value: utils.user.get().email_address || `${editable ? '':'Unknown '}Email Address`
    },{
        key: '[PHONE_NUMBER]',
        title: 'Phone Number',
        category: 'user',
        description: 'the phone number for the presenter of the script',
        value: utils.user.get().phone_number || `${editable ? '':'Unknown '}Phone Number`
    },{
        key: '[LEAD_FIRST_NAME]',
        title: 'First Name',
        category: 'lead',
        description: 'the first name for the Lead',
        value: lead ? lead.first_name : `${editable ? '':'Unknown '}Lead First Name`
    },{
        key: '[LEAD_LAST_NAME]',
        title: 'Last Name',
        category: 'lead',
        description: 'the last name for the Lead',
        value: lead ? lead.last_name : `${editable ? '':'Unknown '}Lead Last Name`
    },{
        key: '[LEAD_FULL_NAME]',
        title: 'Full Name',
        category: 'lead',
        description: 'the first and last name for the Lead',
        value: lead ? lead.full_name : `${editable ? '':'Unknown '}Lead Full Name`
    },{
        key: '[LEAD_SPOUSE_FIRST_NAME]',
        title: 'Spouse First Name',
        category: 'lead',
        description: 'the first name for the Lead spouse if available',
        value: lead && lead.spouse_first_name ? lead.spouse_first_name : `${editable ? '':'Unknown '}Lead Spouse First Name`
    },{
        key: '[LEAD_SPOUSE_LAST_NAME]',
        title: 'Spouse Last Name',
        category: 'lead',
        description: 'the last name for the Lead spouse if available',
        value: lead && lead.spouse_last_name ? lead.spouse_last_name : `${editable ? '':'Unknown '}Lead Spouse Last Name`
    },{
        key: '[LEAD_SPOUSE_FULL_NAME]',
        title: 'Spouse Full Name',
        category: 'lead',
        description: 'the first and last name for the Lead spouse if available',
        value: lead && lead.spouse_first_name && lead.spouse_last_name ? `${lead.spouse_first_name} ${lead.spouse_last_name}` : `${editable ? '':'Unknown '}Lead Spouse Full Name`
    },{
        key: '[LEAD_ADDRESS]',
        title: 'Physical Address',
        category: 'lead',
        description: 'the physical address for the Lead',
        value: lead ? Utils.formatAddress(lead.address) : `${editable ? '':'Unknown '}Lead Address`
    },{
        key: '[LEAD_EMAIL_ADDRESS]',
        title: 'Email Address',
        category: 'lead',
        description: 'the email address for the Lead',
        value: lead ? lead.email_address : `${editable ? '':'Unknown '}Lead Email Address`
    },{
        key: '[LEAD_PHONE_NUMBER]',
        title: 'Phone Number',
        category: 'lead',
        description: 'the phone number for the Lead',
        value: lead ? lead.phone_number : `${editable ? '':'Unknown '}Lead Phone Number`
    },{
        key: '[LEAD_AGE]',
        title: 'Age',
        category: 'lead',
        description: 'the age for the Lead',
        value: getResponseValue('age') || `${editable ? '':'Unknown '}Lead Age`
    },{
        key: '[LEAD_MARITAL_STATUS]',
        title: 'Marital Status',
        category: 'lead',
        description: 'the marital status for the Lead',
        value: getResponseValue('marital_status') || `${editable ? '':'Unknown '}Lead Marital Status`
    },{
        key: '[LEAD_HOME_OWNERSHIP]',
        title: 'Home Ownership',
        category: 'lead',
        description: 'the home ownership status for the Lead',
        value: getResponseValue('home_ownership') || `${editable ? '':'Unknown '}Lead Home Ownership`
    },{
        key: '[LEAD_EMPLOYEMENT_STATUS]',
        title: 'Employement',
        category: 'lead',
        description: 'the employement status for the Lead',
        value: getResponseValue('employement') || `${editable ? '':'Unknown '}Lead Employement`
    }]
};

export const formatLeadScriptContent = args => {

    const { lead, text, utils } = args;
    const onKeywordClick = keyword => {
        utils.alert.show({
            title: 'Smart Keyword',
            message: `The term "${keyword.key}" automatically fills in ${keyword.description}`
        });
    }

    let tag = null;
    let word = null;

    let target = text.replaceAll('\n', '<br>');
    return [ ...target ].reduce((array, character, index) => {

        // html tag detection
        if(character === '<') {
            tag = [character]
        }
        if(tag && ![ '<', '>' ].includes(character)) {
            tag.push(character);
        }
        if(tag && character === '>') {
            let term = tag.concat([character]).join('');
            tag = null;
            switch(term) {
                case '<br>':
                array.splice(array.length - (term.length - 1), term.length);
                array.push(
                    <br key={index} />
                );
                return array;
            }
        }

        // word detection
        if(character === '[') {
            word = [character];
        }
        if(word && ![ '[', ']' ].includes(character)) {
            word.push(character);
        }
        if(word && character === ']') {
            let term = word.concat([character]).join('');
            word = null;
            let keyword = getKeywords(args).find(keyword => {
                return keyword.key === term;
            })
            if(keyword) {
                array.splice(array.length - (term.length - 1), term.length);
                array.push((
                    <span
                    key={index}
                    className={'text-button'}
                    onClick={onKeywordClick.bind(this, keyword)}
                    style={{
                        whiteSpace: 'nowrap',
                        fontSize: config.fontSize,
                        fontWeight: 700,
                        color: Appearance.colors.primary(),
                    }}>{keyword.value}</span>
                ))
                return array;
            }
        }

        // Standard character
        array.push(
            <span key={index}>{character}</span>
        );
        return array;

    }, []);
}

const LeadScriptEditor = ({ onChange, lead, value, style }, { index, options, utils }) => {

    const layerID = lead ? `lead_script_editor_${lead.id}` : 'lead_script_editor';
    const fieldRef = useRef(null);
    const controlsRef = useRef(null);
    const editable = lead ? false : true;

    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState(window.innerHeight);
    const [active, setActive] = useState(false)
    const [layerState, setLayerState] = useState(null);
    const [text, setText] = useState(value || '');

    const onLearnMoreClick = () => {
        utils.alert.show({
            title: 'About Lead Scripts',
            message: 'Lead scripts are personalized to each Lead in your Dealership. Using Smart Keywords you can automatically insert information about yourself, the Lead, or your Dealership. You can choose a keyword to insert into your script by selecting a keyword from one of the dropdown menus or by typing in the keyword yourself'
        });
    }

    const onSelectKeyword = evt => {
        let id = Utils.attributeForKey.select(evt, 'id');
        let cursor = fieldRef.current.selectionStart || text.length;
        setText(text => {
            return `${text.slice(0, cursor)} ${id} ${text.slice(cursor)}`;
        });
    }

    const onDoneClick = () => {
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(text);
        }
    }

    const onWindowSizeChange = () => {
        setHeight(window.innerHeight);
    }

    const getButtons = () => {
        if(lead) {
            return null;
        }
        return [{
            key: 'about',
            text: 'Learn More',
            color: 'secondary',
            onClick: onLearnMoreClick
        },{
            key: 'about',
            text: 'Done',
            color: 'primary',
            onClick: onDoneClick
        }]
    }

    const getHeight = key => {
        if(editable === false) {
            return window.innerHeight / 1.5;
        }
        let controls = controlsRef.current ? controlsRef.current.clientHeight : 0;
        return ((height - 60 - controls) / 5) * (key === 'preview' ? 1.5 : 2)
    }

    const getSelectMenu = key => {
        let label = null;
        switch(key) {
            case 'dealership':
            label = 'Dealership';
            break;

            case 'lead':
            label = 'Leads';
            break;

            case 'user':
            label = 'Script Presenter';
            break;

            default:
            return null;
        }

        return (
            <select
            value={label}
            className={`custom-select ${window.theme}`}
            onChange={onSelectKeyword}
            style={{
                marginRight: 8
            }}>
                <option disabled={true}>{label}</option>
                {getKeywords({
                    utils: utils,
                    editable: editable,
                    lead: lead
                }).filter(keyword => {
                    return keyword.category === key;
                }).map((keyword, index) => {
                    return (
                        <option key={index} id={keyword.key}>{keyword.title}</option>
                    )
                })}
            </select>
        )
    }

    useEffect(() => {
        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, [])

    return (
        <Layer
        id={layerID}
        utils={utils}
        index={index}
        title={lead ? `Lead Script for ${lead.full_name}` : 'Lead Script Editor'}
        buttons={getButtons()}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}>
            {text.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: editable ? 12 : 0
                }}>
                    {editable && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            padding: '8px 12px 8px 12px',
                            backgroundColor: Appearance.colors.primary()
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                color: 'white'
                            }}>{'Script Preview'}</span>
                        </div>
                    )}
                    <div style={{
                        maxHeight: getHeight('preview'),
                        overflowY: 'scroll',
                        padding: '8px 12px 8px 12px',
                        fontSize: config.fontSize,
                        fontWeight: 500,
                        color: Appearance.colors.text()
                    }}>
                        {formatLeadScriptContent({
                            editable: editable,
                            lead: lead,
                            text: text,
                            utils: utils
                        })}
                    </div>
                </div>
            )}

            {editable && (
                <>
                <div
                ref={controlsRef}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginBottom: 8
                }}>
                    {getSelectMenu('dealership')}
                    {getSelectMenu('lead')}
                    {getSelectMenu('user')}
                </div>
                <TextView
                ref={fieldRef}
                value={text}
                placeholder={'Type your script here...'}
                onChange={text => setText(text)}
                fieldStyle={{
                    height: getHeight('textview')
                }}/>
                </>
            )}
        </Layer>
    )
}

export default LeadScriptEditor;
