import React from 'react';

import API from 'files/api.js';
import SocketIO from 'socket.io-client';
import Utils from 'files/Utils.js';

class SocketManagerClass {

    auth = null;
    sockets = null;
    subscribers = {};
    targets = [ 'dealerships', 'demos', 'notifications', 'leads', 'system', 'users' ];

    constructor() {
        return this;
    }

    connect = async (utils, user) => {
        return new Promise(async (resolve, reject) => {
            this.auth = {
                user_id: user.user_id,
                token: user.token,
                version: API.version
            };

            let sockets = {
                main: new SocketIO(API.sockets, {
                    forceNew: false,
                    jsonp: false,
                    transports: [ 'websocket' ],
                    autoConnect: true,
                    query: { auth: JSON.stringify(this.auth) }
                })
            };

            sockets.main.on('error', (e) => {
                let error = new Error('Your device has lost connection to our live update server');
                reject(error);
            })

            sockets.main.on('connect_timeout', (e) => {
                let error = new Error('The request to connect to our live update server has timed-out');
                reject(error);
            })

            sockets.main.on('connect_error', (e) => {
                let error = new Error('We ran into an issue while trying to connect to our live update server');
                reject(error);
            })

            sockets.main.on('disconnect', (e) => {
                let error = new Error('Your device has lost connection to our live update server and we were unable to automatically reconnect');
                reject(error);
            })

            sockets.main.on('connect', async () => {
                this.targets.forEach(target => {
                    sockets[target] = sockets.main.io.socket(`/${target}`, {
                        forceNew: true,
                        jsonp: false,
                        transports: ['websocket'],
                        connect: true,
                        query: { auth: JSON.stringify(this.auth) }
                    });
                })

                this.sockets = sockets;
                resolve();
            });
        })
    }

    emit = async (socketKey, key, data) => {
        return new Promise((resolve, reject) => {
            try {
                if(!this.sockets[socketKey]) {
                    throw new Error(`${Utils.ucFirst(socketKey)} live update channel is not available`);
                    return
                }
                if(this.sockets[socketKey].connected !== true) {
                    throw new Error(`Your device has lost connection to our live update server`);
                    return;
                }
                if(!key || !data) {
                    throw new Error(`Missing required parameters for socket emit`);
                    return;
                }
                this.sockets[socketKey].emit(key, {
                    ...data,
                    auth: this.auth
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    notify = (key, props) => {
        Object.values(this.subscribers).forEach(({ id, callbacks }) => {
            if(typeof(callbacks[key]) === 'function') {
                callbacks[key](props);
            }
        })
    }

    off = async (socketKey, key, callback) => {
        return new Promise((resolve, reject) => {
            try {
                if(!this.sockets[socketKey]) {
                    throw new Error(`${Utils.ucFirst(socketKey)} live update channel is not available`);
                    return
                }
                if(this.sockets[socketKey].connected !== true) {
                    throw new Error(`Your device has lost connection to our live update server`);
                    return;
                }
                if(!key || !callback) {
                    throw new Error(`Missing required parameters for socket off`);
                    return;
                }
                this.sockets[socketKey].off(key, callback);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    on = async (socketKey, key, callback) => {
        return new Promise((resolve, reject) => {
            try {
                if(!this.sockets[socketKey]) {
                    throw new Error(`${Utils.ucFirst(socketKey)} live update channel is not available`);
                    return
                }
                if(this.sockets[socketKey].connected !== true) {
                    throw new Error(`Your device has lost connection to our live update server`);
                    return;
                }
                if(!key || !callback) {
                    throw new Error(`Missing required parameters for socket on`);
                    return;
                }
                this.sockets[socketKey].on(key, callback);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    persistEmit = async (socketKey, key, data) => {
        try {
            await this.emit(socketKey, key, data);
        } catch(e) {
            console.log(`waiting for ${socketKey}`);
            setTimeout(() => {
                this.persistEmit(socketKey, key, data);
            }, 500);
        }
    }

    persistOn = async (socketKey, key, callback) => {
        try {
            await this.on(socketKey, key, callback);
        } catch(e) {
            console.log(`waiting for ${socketKey}`);
            setTimeout(() => {
                this.persistOn(socketKey, key, callback);
            }, 500);
        }
    }

    subscribe = (id, callbacks) => {
        this.subscribers[id] = {
            id: id,
            callbacks: callbacks
        };
        return this;
    }

    unsubscribe = id => {
        if(!this.subscribers[id]) {
            return;
        }
        delete this.subscribers[id];
    }
}

export default {
    new: () => new SocketManagerClass()
};
