import React, { useRef, useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import ListField from 'views/ListField.js';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

const LeadSubTypePickerField = ({ containerStyle, defaultValue, onChange, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const onNewLeadType = () => {
        utils.alert.show({
            title: 'New Lead Sub-Type',
            message: 'What would you like to call this lead sub-type?',
            textFields: [{
                key: 'title',
                placeholder: 'Title'
            }],
            buttons: [{
                key: 'save',
                title: 'Save',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }],
            onClick: response => {
                if(response.title) {
                    onNewLeadTypeConfirm(response.title);
                    return;
                }
            }
        })
    }

    const onNewLeadTypeConfirm = async title => {
        try {

            setLoading(true);
            await Utils.sleep(1);

            let { id } = await Request.post(utils, '/dealerships/', {
                type: 'new_lead_sub_type',
                text: title
            });

            setLoading(false);
            fetchLeadSubTypes();
            utils.content.fetch('lead_sub_type');
            utils.alert.show({
                title: 'All Done!',
                message: `Your "${title}" Lead Sub-Type has been created and added to your list of Lead Sub-Types`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your lead sub-type. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getAccessoryButton = () => {
        if(loading === true) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 50,
                    height: 25,
                    minWidth: 50,
                    minHeight: 25,
                    marginLeft: 12,
                    backgroundColor: Appearance.colors.primary(),
                    borderRadius: 12.5,
                    overflow: 'hidden'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={require('files/lottie/dots-white.json')}
                    style={{
                        width: 20,
                        height: 15,
                        marginBottom: 2
                    }}/>
                </div>
            )
        }

        return (
            <div style={{
                width: 25,
                height: 25,
                minWidth: 25,
                minHeight: 25,
                marginLeft: 12
            }}>
                <img
                className={'text-button'}
                src={'images/plus-button-blue-small.png'}
                onClick={onNewLeadType}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                }} />
            </div>
        )
    }

    const getItems = () => {
        if(items.length === 0) {
            return [{
                key: null,
                title: 'No Sub-Types Found',
                disabled: true
            }]
        }
        return items;
    }

    const fetchLeadSubTypes = async () => {
        try {
            let { lead_sub_types } = await Request.get(utils, '/dealerships/', {
                type: 'lead_sub_types'
            });
            setItems(lead_sub_types);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the lead sub types list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLeadSubTypes();
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            ...containerStyle
        }}>
            <ListField
            utils={utils}
            value={value}
            defaultValue={defaultValue}
            items={getItems()}
            onChange={onChange} />
            {getAccessoryButton()}
        </div>
    )
}
export default LeadSubTypePickerField;
