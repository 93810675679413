import React from 'react';

import Abstract from 'classes/Abstract.js';
import Panel from 'structure/Panel.js';
import User from 'classes/User.js';

import { CallLogs, Leads, CallLogsCalendar, LeadLocations } from 'managers/Leads.js';
import { DemosCalendar, DemosList, DemoRequestsList, DemoLocations } from 'managers/Demos.js';
import { AllNotifications, UserAvailabilityCalendar, WelcomeOverview } from 'managers/Users.js';

export const getContent = async utils => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve({
                dashboard: {
                    key: 'dashboard',
                    title: 'Dashboard',
                    icon: 'navigation-icon-dashboard',
                    panels: [{
                        key: 'welcome_overview',
                        title: 'Welcome',
                        Component: WelcomeOverview
                    },{
                        key: 'all_leads',
                        title: 'Leads',
                        Component: Leads
                    },{
                        key: 'demos_calendar',
                        title: 'Demos Calendar',
                        Component: DemosCalendar
                    }]
                },
                demos: {
                    key: 'demos',
                    title: 'Demos',
                    icon: 'navigation-icon-demos',
                    panels: [{
                        key: 'demo_locations',
                        title: 'Locations',
                        Component: DemoLocations
                    },{
                        key: 'all_demos',
                        title: 'All Demos',
                        Component: DemosList
                    },{
                        key: 'all_demo_requests',
                        title: 'All Demo Requests',
                        Component: DemoRequestsList
                    }]
                },
                leads: {
                    key: 'leads',
                    title: 'Leads',
                    icon: 'navigation-icon-leads',
                    subViews: {
                        browse: {
                            key: 'browse',
                            title: 'Browse',
                            panels: [{
                                key: 'lead_locations',
                                title: 'Locations',
                                Component: LeadLocations
                            },{
                                key: 'leads',
                                title: 'Leads',
                                Component: Leads
                            }]
                        },
                        calls: {
                            key: 'calls',
                            title: 'Calls',
                            panels: [{
                                key: 'call_logs_calendar',
                                title: 'Calls',
                                Component: CallLogsCalendar
                            },{
                                key: 'call_logs',
                                title: 'Call Logs',
                                Component: CallLogs
                            }]
                        }
                    }
                },
                notifications: {
                    key: 'notifications',
                    title: 'Notifications',
                    icon: 'navigation-icon-notifications',
                    panels: [{
                        key: 'all_notifications',
                        Component: AllNotifications
                    }]
                },
                profile: {
                    key: 'profile',
                    title: 'Profile',
                    icon: 'navigation-icon-profile',
                    panels: [{
                        key: 'blank',
                        Component: BlankPanel
                    }]
                },
                logout: {
                    key: 'logout',
                    title: 'Logout',
                    icon: 'navigation-icon-logout',
                    panels: [{
                        key: 'blank',
                        Component: BlankPanel
                    }]
                }
            })
        } catch(e) {
            reject(e);
        }
    })
}

const BlankPanel = props => {
    return <Panel panelID={'blank'} {...props}/>
}
