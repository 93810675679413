import React from 'react';
import moment from 'moment-timezone';

import Lead from 'classes/Lead.js';
import User from 'classes/User.js';

class DemoRequestClass {

    id = null;
    lead = null;
    dealership_id = null;
    date = null;
    timezone = null;
    requested_by_user = null;
    created = null;
    status = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.lead = props.lead ? Lead.create(props.lead) : null;
        this.dealership_id = props.dealership_id;
        this.date = props.date ? moment(props.date) : null;
        this.timezone = props.timezone;
        this.requested_by_user = props.requested_by_user ? User.create(props.requested_by_user) : null;
        this.created = props.created ? moment(props.created) : null;
        this.status = props.status;

        return this;
    }
}

export default {
    new: () => new DemoRequestClass(),
    create: props => new DemoRequestClass().create(props),
}
