import React, { useRef, useState, useEffect } from 'react';

import moment from 'moment';
import update from 'immutability-helper';
import 'react-circular-progressbar/dist/styles.css';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import LottieView from 'views/Lottie.js';
import RadialProgressCircle from 'views/RadialProgressCircle.js';
import Request from 'files/Request.js';
import { Spring } from 'react-spring/renderprops';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const RightSidebar = ({ activeProgram, inline, programs, user, utils }) => {

    const viewID = 'right_sidebar';
    const containerRef = useRef(0);
    const [containerWidth, setContainerWidth] = useState(50);
    const [target, setTarget] = useState(null);
    const [enrollment, setEnrollment] = useState(null);
    const [copyText, setCopyText] = useState('Copy URL');

    const onDownloadProgramQRCode = async url => {
        try {
            let link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.download = 'qr_code.png';
            document.body.appendChild(link);
            link.click();

            await Utils.sleep(1);
            document.body.removeChild(link);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue downloading your Program link. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onShareProgramURL = async () => {
        try {
            let { url } = await fetchPublicURL();
            let textArea = document.createElement('textarea');
            textArea.value = url;
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.position = 'fixed';
            textArea.style.opacity = 0;
            document.body.appendChild(textArea);

            textArea.focus();
            textArea.select();

            let copied = document.execCommand('copy');
            document.body.removeChild(textArea);
            if(!copied) {
                throw new Error('Your browser denied the copy request');
            }
            setCopyText('Copied!');
            await Utils.sleep(2);
            setCopyText('Copy URL');

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue copying your Program link. ${(typeof(e) === 'string' ? e : e.message) || 'An unknown error occurred'}`
            })
        }
    }

    const onShareProgramQRCode = async () => {
        try {
            let { qr_code } = await fetchPublicURL();
            utils.alert.show({
                title: 'All Done!',
                message: `You can scan the QR Code below to open the Program or you can download the QR Code to share with others`,
                component: (
                    <div style={{
                        padding: 12,
                        paddingTop: 0
                    }}>
                        <img
                        src={qr_code}
                        style={{
                            width: 250,
                            height: 250,
                            objectFit: 'contain',
                            borderRadius: 10,
                            overflow: 'hidden'
                        }} />
                    </div>
                ),
                buttons: [{
                    key: 'download',
                    title: 'Download QR Code',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'download') {
                        onDownloadProgramQRCode(qr_code);
                        return;
                    }
                }
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue copying your Program link. ${(typeof(e) === 'string' ? e : e.message) || 'An unknown error occurred'}`
            })
        }
    }

    const onSizeChange = () => {
        setContainerWidth(containerRef.current.clientWidth);
    }

    const getProgressColor = () => {
        let value = enrollment.percent;
        if(value <= 10) {
            return Appearance.colors.red;
        }
        if(value <= 25) {
            return Appearance.colors.yellow;
        }
        if(value <= 50) {
            return Appearance.colors.orange;
        }
        return Appearance.colors.green;
    }

    const getDemoRadialProgressColors = () => {
        let value = target.demos.percent;
        if(value <= 10) {
            return [ Appearance.colors.red, '#FB9286' ];
        }
        if(value <= 25) {
            return [ Appearance.colors.yellow, '#F7DE79' ];
        }
        if(value <= 50) {
            return [ Appearance.colors.orange, '#F7B476' ];
        }
        return [ Appearance.colors.green, '#A5DEA0' ];
    }

    const getPrograms = () => {
        if(!activeProgram) {
            return null;
        }
        return (
            <div style={{
                width: '100%',
                padding: 15,
                marginBottom: 15,
                backgroundColor: Appearance.colors.layerBackground(),
                borderRadius: 10,
                boxShadow: '5px 5px 15px rgba(0,0,0,0.05)'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}>
                    <img
                    src={activeProgram.avatar}
                    style={{
                        width: 60,
                        height: 60,
                        objectFit: 'contain',
                        borderRadius: '50%',
                        marginBottom: 8
                    }} />
                    <span style={{
                        display: 'block',
                        color: Appearance.colors.text(),
                        fontSize: 18,
                        fontWeight: 800,
                        marginBottom: 4
                    }}>{activeProgram.name}</span>
                    <span style={{
                        display: 'block',
                        color: Appearance.colors.subText(),
                        fontSize: 12,
                        fontWeight: 500
                    }}>{activeProgram.description}</span>

                    <select
                    value={activeProgram.name}
                    className={`custom-select ${window.theme}`}
                    onChange={e => {
                        let id = parseInt(Utils.attributeForKey.select(e, 'id'));
                        let program = programs.find(prevProgram => id === prevProgram.id);
                        if(program) {
                            utils.program.set(program);
                        }
                    }}
                    style={{
                        width: '100%',
                        marginTop: 12
                    }}>
                        <option disabled={true}>{'View Other Programs...'}</option>
                        {programs.map((program, index) => {
                            return (
                                <option key={index} id={program.id}>{program.name}</option>
                            )
                        })}
                    </select>

                    {activeProgram && activeProgram.allow_sharing && (
                        <div
                        className={'row p-0 mx-0 mb-0'}
                        style={{
                            width: '100%',
                            marginTop: 12
                        }}>
                            <div className={'col-12 col-lg-6 p-1'}>
                                <Button
                                label={copyText}
                                type={'transition'}
                                color={'primary'}
                                onClick={onShareProgramURL}
                                transition={{
                                    icon: 'copy-icon-white.png'
                                }}
                                style={{
                                    width: '100%'
                                }}/>
                            </div>
                            <div className={'col-12 col-lg-6 p-1'}>
                                <Button
                                label={'Get QR Code'}
                                type={'transition'}
                                color={'secondary'}
                                onClick={onShareProgramQRCode}
                                transition={{
                                    icon: 'qr-code-icon-white.png'
                                }}
                                style={{
                                    width: '100%'
                                }}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const getTimeFrame = () => {
        if(!enrollment || !enrollment.end_date) {
            return null;
        }
        return (
            <div style={{
                padding: 15,
                marginBottom: 15,
                backgroundColor: Appearance.colors.layerBackground(),
                borderRadius: 10,
                boxShadow: '5px 5px 15px rgba(0,0,0,0.05)'
            }}>
                <div style={{
                    padding: 15
                }}>
                    <div style={{
                        position: 'relative',
                        backgroundColor: Appearance.colors.divider(),
                        borderRadius: 12.5,
                        height: 25,
                        width: '100%',
                        overflow: 'hidden'
                    }}>
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            height: '100%',
                            width: `${enrollment.percent}%`,
                            borderRadius: 20,
                            background: Appearance.colors.softGradient(getProgressColor())
                        }} />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: 18,
                        fontWeight: 800
                    }}>{`${enrollment.remaining} ${enrollment.remaining == 1 ? 'Day':'Days'} Remaining`}</span>
                    <span style={{
                        color: Appearance.colors.subText(),
                        fontSize: 12,
                        fontWeight: 500
                    }}>{`Started on ${moment(enrollment.start_date).format('MMMM Do, YYYY')}`}</span>
                </div>
            </div>
        )
    }

    const getDemosProgress = () => {
        if(!target || !target.demos || !target.demos.goal) {
            return null;
        }
        let fields = [{
            key: 'qualified',
            title: 'Qualified Demos',
            value: target.demos.qualified
        },{
            key: 'sold',
            title: 'Sold Demos',
            value: target.demos.sold
        },{
            key: 'total',
            title: 'Demos Goal',
            value: target.demos.goal
        }]
        return (
            <div style={{
                padding: 15,
                marginBottom: 15,
                backgroundColor: Appearance.colors.layerBackground(),
                borderRadius: 10,
                boxShadow: '5px 5px 15px rgba(0,0,0,0.05)'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <RadialProgressCircle
                    value={target.demos.percent}
                    size={containerWidth}
                    colors={getDemoRadialProgressColors()}>
                        <span style={{
                            color: Appearance.colors.text(),
                            fontSize: 22,
                            fontWeight: 800,
                            lineHeight: 1.25
                        }}>{`${target.demos.percent}%`}</span>
                        <span style={{
                            color: Appearance.colors.subText(),
                            fontSize: 13,
                            fontWeight: 500,
                            lineHeight: 1
                        }}>{'Completed'}</span>
                    </RadialProgressCircle>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 15
                }}>
                    {fields.map((field, index) => {
                        return (
                            <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                width: '33%'
                            }}>
                                <span style={{
                                    color: Appearance.colors.text(),
                                    fontSize: 18,
                                    fontWeight: 800,
                                }}>{field.value}</span>
                                <span style={{
                                    color: Appearance.colors.subText(),
                                    fontSize: 12,
                                    fontWeight: 500,
                                }}>{field.title}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const getStyles = () => {
        if(inline) {
            return {
                position: 'relative',
                padding: 15
            }
        }
        return {
            position: 'relative',
            zIndex: 1100,
            overflowY: 'scroll',
            padding: 15,
            paddingLeft: 0,
            maxHeight: '100vh'
        }
    }

    const fetchProgramStats = async () => {
        if(!activeProgram) {
            return;
        }
        try {
            let { target, enrollment } = await Request.get(utils, '/programs/', {
                type: 'stats_for_associate',
                program_id: activeProgram.id
            });
            setTarget(target);
            setEnrollment(enrollment);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the stats for the "${activeProgram.name}" Program. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchPublicURL = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/programs/', {
                    type: 'public_url',
                    program_id: utils.program.get().id,
                    enrollment_user_id: utils.user.get().user_id
                });
                resolve(response);

            } catch(e) {
                reject(e);
            }
        })
    }

    useEffect(() => {
        if(containerRef.current) {
            onSizeChange();
        }
    }, [containerRef]);

    useEffect(() => {
        fetchProgramStats();
    }, [activeProgram]);

    useEffect(() => {
        utils.content.subscribe(viewID, [ 'demo', 'demo_request', 'lead' ], {
            onFetch: fetchProgramStats,
            onUpdate: fetchProgramStats
        });
        return () => {
            utils.content.unsubscribe(viewID);
        }
    })

    return (
        <div
        ref={containerRef}
        style={getStyles()}>
            {getPrograms()}
            {getTimeFrame()}
            {getDemosProgress()}
        </div>
    )
}
export default RightSidebar;
