import React from 'react';
import moment from 'moment';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import LottieView from 'views/Lottie.js';
import Utils from 'files/Utils.js';

export const AltBadge = ({ className, containerClass, content, style, onClick }) => {

    const Component = ({ color, index, text }) => {
        return text ? (
            <div
            key={index}
            className={onClick ? 'text-button':''}
            onClick={onClick}
            style={{
                display: 'flex',
                padding: '2px 10px 2px 10px',
                borderRadius: 20,
                backgroundColor: Utils.hexToRGBA(color, 0.25),
                alignItems: 'center',
                textAlign: 'center',
                marginRight: 8,
                position: 'relative',
                top: -0.5,
                ...style
            }}>
                <span
                className={className}
                style={{
                    fontSize: 8,
                    color: color,
                    fontWeight: '800',
                    width: '100%'
                }}>{text.toUpperCase()}</span>
            </div>
        ) : null
    }

    return Array.isArray(content) ?
        content.map((props, index) => {
            let { text, color, tag } = props;
            if(!text || !color) {
                return;
            }
            return (
                <Component
                key={index}
                {...props} />
            )
        })
        :
        content && content.text && content.color
            ?
            <Component
            {...content} />
            :
            null
}

const getEntryBorderRadius = props => {
    if(props.singleItem) {
        return {
            borderRadius: 7
        };
    }
    if(props.lastItem) {
        return {
            borderBottomLeftRadius: 7,
            borderBottomRightRadius: 7
        }
    }
    if(props.firstItem) {
        return {
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
            borderBottom: `1px solid ${Appearance.colors.divider()}`
        }
    }
    return props.bottomBorder ? {
        borderBottom: `1px solid ${Appearance.colors.divider()}`
    } : null;
}

const Views = {
    entry: props => {
        return (
            <div
            key={props.key}
            onClick={props.onClick}
            style={{
                overflow: 'hidden'
            }}>
                <div className={`${props.onClick ? `view-entry ${window.theme}` : ''}`}
                style={{
                    padding: '8px 10px 8px 10px',
                    ...getEntryBorderRadius(props),
                    ...props.style
                }}>
                    <div className={props.className || ''} style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        position: 'relative'
                    }}>
                        {typeof(props.icon) === 'function'
                            ?
                            props.icon()
                            :
                            props.hideIcon !== true
                                ?
                                <div style={{
                                    position: 'relative',
                                    ...(props.icon ? props.icon.style : null)
                                }}>
                                    {props.loading
                                        ?
                                        <div style={{
                                            ...Appearance.icons.standard(),
                                            minWidth: Appearance.icons.standard().width,
                                            minHeight: Appearance.icons.standard().height,
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            borderRadius: '50%',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            ...(props.icon ? props.icon.imageStyle : null)
                                        }}>
                                            <LottieView
                                            loop={true}
                                            autoPlay={true}
                                            source={require('files/lottie/dots-white.json')}
                                            style={{
                                                width: 15,
                                                height: 15,
                                                paddingBottom: 2
                                            }}/>
                                        </div>
                                        :
                                        <img src={props.icon && props.icon.path ? props.icon.path : 'images/star-icon-clear.png'}
                                        className={props.icon && props.icon.onClick ? 'text-button' : ''}
                                        onClick={props.icon ? props.icon.onClick : null}
                                        style={{
                                            ...Appearance.icons.standard(),
                                            minWidth: Appearance.icons.standard().width,
                                            minHeight: Appearance.icons.standard().height,
                                            ...(props.icon ? props.icon.imageStyle : null)
                                        }}/>
                                    }
                                    {props.icon && props.icon.badge && props.icon.badge.text
                                        ?
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            position: 'absolute',
                                            top: -2,
                                            right: -6,
                                            width: 15,
                                            height: 15,
                                            borderRadius: '50%',
                                            padding: 4,
                                            overflow: 'hidden',
                                            backgroundColor: props.icon.badge.color || Appearance.colors.primary()
                                        }}>
                                            <span style={{
                                                color: 'white',
                                                fontSize: 9,
                                                fontWeight: '600'
                                            }}>{props.icon.badge.text}</span>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                        }
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            width: '100%',
                            paddingLeft: props.hideIcon === true ? 0 : 12
                        }}>
                            {props.badge && (Array.isArray(props.badge) || props.badge.color)
                                ?
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.title(),
                                        ...(props.textStyles ? props.textStyles.title : null),
                                        paddingRight: 8,
                                        ...(props.style ? props.style.title : null)
                                    }}>{props.title}</span>
                                    <AltBadge content={props.badge} />
                                </div>
                                :
                                <span style={Appearance.textStyles.title()}>{props.title}</span>
                            }
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                ...(props.textStyles ? props.textStyles.subTitle : null),
                                maxWidth: `calc(100% - ${Appearance.icons.standard().width}px)`,
                                ...(props.style ? props.style.subTitle : null)
                            }}>{props.subTitle}</span>
                            {props.supportingTitle
                                ?
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    ...(props.textStyles ? props.textStyles.supportingTitle : null),
                                    maxWidth: `calc(100% - ${Appearance.icons.standard().width}px)`,
                                    ...(props.style ? props.style.supportingTitle : null)
                                }}>{props.supportingTitle}</span>
                                :
                                null
                            }
                        </div>
                        {props.onClick && (
                            <img
                            src={'images/next-arrow-grey-small.png'}
                            style={{
                                width: 12,
                                height: 12,
                                objectFit: 'contain',
                                marginLeft: 8,
                                opacity: 0.75
                            }} />
                        )}
                    </div>
                    {props.contextMenu}
                </div>
            </div>
        )
    }
}
export default Views;
