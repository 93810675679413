import React, { useRef, useState, useEffect } from 'react';

import '@pathofdev/react-tag-input/build/index.css';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import ReactTagInput from '@pathofdev/react-tag-input';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const TagLookupField = ({ defaultTags, icon, onChange, placeholder, style, utils, value }) => {

    const [tags, setTags] = useState(defaultTags || []);
    const [pendingTags, setPendingTags] = useState(null);

    const onConvertTags = async () => {
        if(!pendingTags || pendingTags.length === 0) {
            return;
        }
        try {
            let { tags } = await Request.post(utils, '/dealerships/', {
                type: 'create_tags',
                tags: pendingTags
            });
            if(tags.length > 0) {
                setTags(tags);
            }

        } catch(e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        onConvertTags();
    }, [pendingTags]);

    useEffect(() => {
        if(typeof(onChange) === 'function') {
            onChange(tags.filter(tag => {
                return typeof(tag) !== 'string';
            }));
        }
    }, [tags]);

    return (
        <div style={{
            width: '100%',
            ...style
        }}>
            <ReactTagInput
            removeOnBackspace={true}
            placeholder={placeholder || 'Type and press enter'}
            tags={tags.map(tag => Utils.ucFirst(tag.text))}
            onChange={nextTags => {
                setPendingTags(nextTags.filter(tag => typeof(tag) === 'string'));
                setTags(prevTags => {
                    return prevTags.filter(prevTag => nextTags.includes(prevTag.text));
                });
            }}
            validator={value => {
                return tags.find(tag => tag.text === value) ? false : true;
            }}/>
        </div>
    )
}
export default TagLookupField;
