import React from 'react';
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';

class ProgramClass {

    id = null;
    dealership = null;
    dealership_id = null;
    name = null;
    description = null;
    active = null;
    end_type = null;
    duration = null;
    end_date = null;
    end_days = null;
    end_date_type = null;
    props = {};
    text = null;
    template = null;
    created = null;
    privacy = null;
    allow_sharing = false;
    registered = null;
    avatar = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.dealership = props.dealership ? Dealership.create(props.dealership) : null;
        this.dealership_id = props.dealership_id;
        this.name = props.name;
        this.description = props.description;
        this.active = props.active;
        this.end_type = props.end_type;
        this.duration = props.duration;
        this.end_date = props.end_date ? moment(props.end_date) : null;
        this.end_days = props.end_days;
        this.props = props.props || {};
        this.template = props.template;
        this.created = props.created;
        this.privacy = props.privacy;
        this.allow_sharing = props.allow_sharing;
        this.template = props.template ? Dealership.Template.create(props.template) : null;
        this.registered = props.registered || 0;
        this.avatar = props.avatar;

        if(this.end_date) {
            this.end_date_type = 'date';
        }
        if(this.end_days) {
            this.end_date_type = 'days';
        }
        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                name: this.name,
                description: this.description,
                dealership: this.dealership,
                end_date: this.end_date,
                end_days: this.end_days,
                end_date_type: this.end_date_type,
                props: this.props,
                template: this.template,
                privacy: this.privacy,
                allow_sharing: this.allow_sharing,
                template: this.template
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                name: props.name || this.edits.name,
                description: props.description || this.edits.description,
                dealership: props.dealership || this.edits.dealership,
                end_date: props.end_date !== undefined ? props.end_date : this.edits.end_date,
                end_days: props.end_days !== undefined ? props.end_days : this.edits.end_days,
                end_date_type: props.end_date_type !== undefined ? props.end_date_type : this.edits.end_date_type,
                props: props.props || this.edits.props,
                template: props.template || this.edits.template,
                privacy: props.privacy !== undefined ? props.privacy : this.edits.privacy,
                allow_sharing: props.allow_sharing !== undefined ? props.allow_sharing : this.edits.allow_sharing,
                template: props.template || this.edits.template
            }
            if(this.edits.end_date_type) {
                switch(this.edits.end_date_type) {
                    case 'date':
                    this.edits.end_days = null;
                    break;

                    case 'days':
                    this.edits.end_date = null;
                    break;
                }
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.name = this.edits.name || this.name;
            this.description = this.edits.description || this.description;
            this.dealership = this.edits.dealership || this.dealership;
            this.end_date = this.edits.end_date !== undefined ? this.edits.end_date : this.end_date;
            this.end_days = this.edits.end_days !== undefined ? this.edits.end_days : this.end_days;
            this.end_date_type = this.edits.end_date_type;
            this.props = this.edits.props || this.props;
            this.template = this.edits.template || this.template;
            this.affiliate = typeof(this.edits.affiliate) === 'boolean' ? this.edits.affiliate : this.affiliate;
            this.privacy = this.edits.privacy !== undefined ? this.edits.privacy : this.privacy;
            this.allow_sharing = typeof(this.edits.allow_sharing) === 'boolean' ? this.edits.allow_sharing : this.allow_sharing;
            this.template = this.edits.template || this.template
            resolve();
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/programs/', {
                    type: 'new',
                    ...this.toJSON(this.edits)
                })

                this.id = id;
                await this.close();
                utils.content.fetch('program');

                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/programs/', {
                    type: 'update',
                    ...this.toJSON(this.edits)
                })

                await this.close();
                utils.content.update(Abstract.create({
                    type: 'program',
                    object: this
                }));
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            id: this.id,
            name: target.name,
            description: target.description,
            dealership_id: target.dealership ? target.dealership.id : null,
            end_date: target.end_date,
            end_days: target.end_days,
            props: target.props,
            template: target.template,
            privacy: target.privacy,
            allow_sharing: target.allow_sharing,
            template: target.template ? target.template.id : null
        }
    }
}

const fetchProgram = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { program } = await Request.get(utils, '/programs/', {
                type: 'details',
                id: id
            });
            let obj = new ProgramClass().create(program);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new ProgramClass(),
    get: (utils, id) => fetchProgram(utils, id),
    create: props => new ProgramClass().create(props)
}
