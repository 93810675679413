import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import TextField, { getIcon } from 'views/TextField.js';

const DatePickerField = props => {

    const [active, setActive] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    const onFieldClick = () => {
        setActive(true);
        props.utils.datePicker.show({
            date: selectedDate,
            ...props,
            onDateChange: onDateChange,
            onRemoveDate: onRemoveDate,
            onClose: () => setActive(false)
        })
    }

    const onDateChange = date => {
        setSelectedDate(date);
        if(typeof(props.onDateChange) === 'function') {
            props.onDateChange(date);
        }
    }

    const onRemoveDate = () => {
        setSelectedDate(null);
        if(typeof(props.onRemoveDate) === 'function') {
            props.onRemoveDate();
        }
    }

    const formatDate = () => {
        if(!selectedDate) {
            return props.placeholder || 'Select a date...';
        }
        if(props.dateTime) {
            return moment(selectedDate).format('MMMM Do, YYYY [at] h:mma')
        }
        return moment(selectedDate).format('MMMM Do, YYYY');
    }

    const getLocalIcon = () => {
        if(props.insetLabel) {
            return null;
        }
        return getIcon(props.icon || 'calendar');
    }

    const getTextColor = () => {
        if(!selectedDate) {
            return Appearance.colors.subText();
        }
        return Appearance.colors.text();
    }

    useEffect(() => {
        setSelectedDate(props.selected ? moment(props.selected) : null);
    }, [props.selected]);

    return (
        <div
        onClick={onFieldClick}
        className={`dummy-field ${active ? 'active' : ''} ${window.theme} cursor-pointer`}
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexGrow: 1,
            position: 'relative',
            height: 35,
            border: `1px solid ${Appearance.colors.divider()}`,
            backgroundColor: Appearance.colors.textField(),
            ...props.style
        }}>
            {getLocalIcon() && (
                <div style={{
                    paddingRight: 8,
                    marginLeft: 4
                }}>
                    <i
                    className={getLocalIcon()}
                    style={{
                        marginRight: 8,
                        color: Appearance.colors.softBorder(),
                        fontSize: 13
                    }}/>
                </div>
            )}
            {props.insetLabel && (
                <div style={{
                    marginRight: 8,
                    paddingRight: 8,
                    borderRight: `1px solid ${Appearance.colors.softBorder()}`,
                    ...props.insetContainerStyle
                }}>
                    <span style={{
                        display: 'block',
                        fontSize: 10,
                        fontWeight: '600',
                        color: Appearance.colors.subText(),
                        ...props.insetLabelStyle
                    }}>{props.insetLabel}</span>
                </div>
            )}
            <span style={{
                fontSize: 12,
                fontWeight: 500,
                color: getTextColor(),
                width: '100%',
                flexGrow: 1,
                ...props.fieldStyle
            }}>{formatDate()}</span>
            {typeof(props.onValidate) === 'function' && props.onValidate(selectedDate) && (
                <img
                src={'images/checkmark-small-green.png'}
                style={{
                    width: 15,
                    height: 15,
                    objectFit: 'contain',
                    marginLeft: 8
                }} />
            )}
        </div>
    )
}
export default DatePickerField;
