import React, { useRef, useState, useEffect } from 'react';

import moment from 'moment';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import AddressLookupField from 'views/AddressLookupField.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Dealership from 'classes/Dealership.js';
import Demo from 'classes/Demo.js';
import { DemoDetails } from 'managers/Demos.js';
import DemoRequest from 'classes/DemoRequest.js';
import EditTarget from 'views/EditTarget.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer from 'structure/Layer.js';
import { Map } from 'views/MapElements.js';
import Notification from 'classes/Notification.js';
import Panel from 'structure/Panel.js';
import PickerField from 'views/PickerField.js';
import Lead from 'classes/Lead.js';
import { LeadDetails } from 'managers/Leads.js';
import Request from 'files/Request.js';
import Scheduler from 'views/Scheduler.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

export const getNotificationIconColor = notification => {
    if(notification.category.includes('CANCELLED')) {
        return Appearance.colors.red;
    }
    if(notification.category.includes('RESCHEDULED')) {
        return Appearance. colors.secondary();
    }
    return Appearance.colors.primary();
}

export const onNotificationAction = (utils, notification, onPress) => {
    let buttons = [];
    if(notification.payload) {
        if(notification.payload.lead_id) {
            buttons.push({
                key: 'lead_from_id',
                title: 'View Lead',
                style: 'default'
            })
        }
    }

    utils.alert.show({
        title: notification.title,
        message: notification.message,
        icon: {
            path: notification.from_user ? notification.from_user.avatar : 'images/push-notification-icon-white.png',
            style: {
                backgroundColor: getNotificationIconColor(notification)
            }
        },
        buttons: buttons.concat([{
            key: 'cancel',
            title: 'Okay',
            style: 'cancel'
        }]),
        onPress: async key => {
            if(typeof(onPress) === 'function') {
                onPress(notification);
            }
            if(key === 'lead_from_id') {
                try {
                    let lead = await Lead.get(utils, notification.payload.lead_id);
                    utils.layer.open({
                        id: `lead_details_${lead.id}`,
                        abstract: Abstract.create({
                            type: 'lead',
                            object: lead
                        }),
                        Component: LeadDetails
                    })
                } catch(e){
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue retrieving the information for this Lead. ${e.message || 'An unknown error occurred'}`
                    })
                }
            }
        }
    });
}

// Panels
export const AllNotifications = ({ index, options, utils }) => {

    const panelID = 'all_notifications';
    const [loading, setLoading] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            let { notifications, paging } = await Request.get(utils, '/notifications/', {
                type: 'all',
                limit: 10,
                offset: offset,
                search_text: searchText
            });

            setLoading(false);
            setPaging(paging);
            setNotifications(notifications.map(notification => Notification.create(notification)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the notifications list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNotificationClick = notification => {
        utils.layer.open({
            id: `notification_details_${notification.id}`,
            abstract: Abstract.create({
                type: 'notification',
                object: notification
            }),
            Component: NotificationDetails
        })
    }

    const getContent = () => {
        if(notifications.length === 0) {
            return (
                Views.entry({
                    title: `No Notifications Found`,
                    subTitle: `No notifications have been sent to your account`,
                    singleItem: true,
                    hideIcon: true
                })
            )
        }
        return notifications.map((notification, index) => {
            return (
                Views.entry({
                    key: index,
                    title: notification.title,
                    subTitle: notification.message,
                    icon: {
                        path: notification.from_user ? notification.from_user.avatar : 'images/push-notification-icon-white.png',
                        style: {
                            borderRadius: '50%',
                            overflow: 'hidden'
                        },
                        imageStyle: {
                            backgroundColor: getNotificationIconColor(notification)
                        }
                    },
                    bottomBorder: true,
                    onClick: onNotificationClick.bind(this, notification)
                })
            )
        })
    }

    useEffect(() => {
        fetchNotifications();
    }, [offset, searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchNotifications);
        utils.content.subscribe(panelID, [ 'notification' ], {
            onFetch: fetchNotifications
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchNotifications);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Notifications'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            search: {
                placeholder: 'Search by title or message...',
                onChange: text => setSearchText(text)
            },
            paging: {
                data: paging,
                limit: 10,
                offset: offset,
                onClick: next => setOffset(next)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const UserAvailabilityCalendar = ({ index, options, utils }) => {

    const panelID = 'user_availability_calendar';
    const [loading, setLoading] = useState(null);
    const [events, setEvents] = useState([]);
    const [resources, setResources] = useState([]);
    const [targetDate, setTargetDate] = useState(moment());
    const [panelWidth, setPanelWidth] = useState(0);

    const onAvailabilityAdded = data => {
        try {
            if(data.from_user === utils.user.get().user_id) {
                console.log('from_user');
                return;
            }
            setEvents(events => update(events, {
                $push: [{
                    id: data.id,
                    available: data.available,
                    start: data.start_date,
                    end: data.end_date,
                    resourceId: data.user_id,
                    title: `${Utils.ucFirst(data.time_of_day)} Availability`,
                    bgColor: data.available ? Appearance.colors.primary() : Appearance.colors.grey(),
                    user: data.user
                }]
            }))
        } catch(e) {
            console.log(e.message);
        }
    }

    const onAvailabilityUpdated = data => {
        try {
            setEvents(events => {
                return events.map(evt => {
                    if(evt.id === data.id) {
                        return {
                            ...evt,
                            ...data,
                            start: data.start_date,
                            end: data.end_date,
                            bgColor: data.available ? Appearance.colors.primary() : Appearance.colors.grey()
                        }
                    }
                    return evt;
                })
            })
        } catch(e) {
            console.log(e.message);
        }
    }

    const onAvailabilityDeleted = data => {
        try {
            setEvents(events => {
                return events.filter(evt => {
                    return evt.id !== data.id;
                })
            })
        } catch(e) {
            console.log(e.message);
        }
    }

    const onDeleteEvent = evt => {
        utils.alert.show({
            title: 'Delete Timeslot',
            message: 'Are you sure that you want to delete this timeslot?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteEventConfirm(evt);
                    return;
                }
            }
        })
    }

    const onDeleteEventConfirm = async evt => {
        try {
            await Request.post(utils, '/users/', {
                type: 'delete_availability',
                id: evt.id
            })
            setEvents(events => {
                return events.filter(prevEvent => prevEvent.id !== evt.id)
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this timeslot. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEventClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'available',
                title: `Set as ${evt.available ? 'Unavailable':'Available'}`,
                style: evt.available ? 'destructive' : 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'delete') {
                onDeleteEvent(evt);
                return;
            }
            if(key === 'available') {
                onSetAvailabilityStatus(evt);
                return;
            }
        })
    }

    const onEventRender = target => {
        let color = Appearance.colors.timeOfDay(target.time_of_day);
        return (
            <div
            className={`text-button mb-1`}
            style={{
                width: '100%',
                borderRadius: 5,
                overflow: 'hidden',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color)
            }}>
                <div style={{
                    padding: '8px 12px 8px 12px'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span
                        className={'d-block w-100'}
                        style={{
                            fontSize: 10,
                            color: 'white',
                            fontWeight: 600,
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{target.user.full_name}</span>
                        <span
                        className={'d-block w-100'}
                        style={{
                            fontSize: 10,
                            color: 'white',
                            fontWeight: 300,
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{`${moment(target.start_date).format('h:mma')} to ${moment(target.end_date).format('h:mma')}`}</span>
                    </div>
                </div>
            </div>
        )
    }

    const onNewEvent = async evt => {
        try {
            let { id, time_of_day } = await Request.post(utils, '/users/', {
                type: 'new_availability',
                user_id: evt.id,
                start_date: evt.start,
                end_date: evt.end
            });

            let resource = resources.find(resource => resource.id === evt.id);
            setEvents(events => update(events, {
                $push: [{
                     id: id,
                     available: true,
                     start: evt.start,
                     end: evt.end,
                     resourceId: evt.id,
                     title: `${Utils.ucFirst(time_of_day)} Availability`,
                     bgColor: Appearance.colors.primary(),
                     user: resource ? resource.user : null
                }]
            }))

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating the timeslot. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onRenderCallout = ({ evt, title, start, end, color }) => {
        return (
            <div style={{
                maxWidth: 350
            }}>
                {Views.entry({
                    title: evt.user.full_name,
                    subTitle: `Available from ${moment(start).format('h:mma')} to ${moment(end).format('h:mma')}`,
                    icon: {
                        path: evt.user.avatar
                    },
                    borderBottom: false,
                    rightContent: (
                        <img
                        className={'text-button'}
                        src={'images/red-x-icon.png'}
                        onClick={onDeleteEvent.bind(this, evt)}
                        style={{
                            width: 20,
                            height: 20,
                            objectFit: 'contain',
                            marginLeft: 12
                        }} />
                    )
                })}
            </div>
        )
    }

    const onRenderEvent = ({ agenda, evt, bgColor }) => {
        return (
            <div
            className={'pinstripes'}
            style={{
                backgroundColor: bgColor,
                borderRadius: 25,
                overflow: 'hidden',
                padding: agenda ? '8px 12px 8px 12px' : 10
            }}>
                {agenda && (
                    <span style={{
                        ...Appearance.textStyles.title(),
                        display: 'block',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        color: 'white'
                    }}>{`${moment(evt.start).format('h:mma')} to ${moment(evt.end).format('h:mma')}`}</span>
                )}
            </div>
        )
    }

    const onRenderResource = ({ agenda, item, className }) => {
        let resource = resources.find(resource => resource.id === item.slotId);
        return (
            <div
            className={className || ''}
            style={{
                textAlign: 'left',
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: agenda ? 8 : 0,
                paddingBottom: agenda ? 8 : 0
            }}>
                {Views.entry({
                    title: item.slotName,
                    subTitle: resource.user.phone_number,
                    icon: {
                        path: resource.user.avatar,
                        imageStyle: {
                            boxShadow: null
                        }
                    },
                    bottomBorder: false,
                    style: {
                        padding: 0
                    }
                })}
            </div>
        )
    }

    const onSetAvailabilityStatus = evt => {
        utils.alert.show({
            title: `Set as ${evt.available ? 'Unavailable' : 'Available'}`,
            message: `Are you sure that you want to set this timeslot as ${evt.available ? 'unavailable' : 'available'}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetAvailabilityStatusConfirm(evt);
                    return;
                }
            }
        })
    }

    const onSetAvailabilityStatusConfirm = async evt => {
        try {
            let nextAvailable = !evt.available;
            await Request.post(utils, '/users/', {
                type: 'set_availability_status',
                id: evt.id,
                available: nextAvailable
            })
            setEvents(events => {
                return events.map(prevEvent => {
                    if(prevEvent.id === evt.id) {
                        prevEvent.available = nextAvailable;
                        prevEvent.bgColor = nextAvailable ? Appearance.colors.primary() : Appearance.colors.grey()
                    }
                    return prevEvent;
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this timeslot. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateEvent = async evt => {
        try {
            await Request.post(utils, '/users/', {
                type: 'update_availability',
                id: evt.id,
                user_id: evt.user_id,
                start_date: evt.start,
                end_date: evt.end
            })
            setEvents(events => {
                return events.map(prevEvent => {
                    if(prevEvent.id === evt.id) {
                        prevEvent.start = evt.start;
                        prevEvent.end = evt.end;
                    }
                    return prevEvent;
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this timeslot. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getEvents = user => {
        return events.filter(entry => {
            return entry.user.user_id === user.user_id;
        })
    }

    const fetchEvents = async () => {
        try {
            let { cal_events } = await Request.get(utils, '/users/', {
                type: 'availability_calendar',
                start_date: targetDate.format('YYYY-MM-DD'),
                end_date: moment(targetDate).add(7, 'days').format('YYYY-MM-DD')
            });

            let user = utils.user.get();
            utils.sockets.off('users', `on_add_availability_${user.user_id}`, onAvailabilityAdded);
            utils.sockets.on('users', `on_add_availability_${user.user_id}`, onAvailabilityAdded);

            setResources([{
               id: user.user_id,
               name: user.full_name,
               user: user
            }]);

            setEvents(cal_events.reduce((array, item) => {
                let { events, user } = item;
                return array.concat(events.map(evt => ({
                     id: evt.id,
                     available: evt.available,
                     start: evt.start_date,
                     end: evt.end_date,
                     resourceId: evt.user_id,
                     title: `${Utils.ucFirst(evt.time_of_day)} Availability`,
                     bgColor: evt.available ? Appearance.colors.primary() : Appearance.colors.grey(),
                     user: User.create(item.user)
                 })))
            }, []))

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the availability calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchEvents();
    }, [targetDate]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchEvents);
        utils.content.subscribe(panelID, 'user', {
            onFetch: fetchEvents,
            onUpdate: fetchEvents
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchEvents);
            setResources(resources => {
                if(resources) {
                    resources.forEach(resource => {
                        utils.sockets.off('users', `on_add_availability_${resource.id}`, onAvailabilityAdded);
                        utils.sockets.off('users', `on_add_availability_recurring_${resource.id}`, fetchEvents);
                        utils.sockets.off('users', `on_update_availability_${resource.id}`, onAvailabilityUpdated);
                        utils.sockets.off('users', `on_update_availability_recurring_${resource.id}`, fetchEvents);
                        utils.sockets.off('users', `on_delete_availability_${resource.id}`, onAvailabilityDeleted);
                    })
                }
                return resources;
            })
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        utils={utils}
        name={'My Availability'}
        options={{
            loading: loading,
            onSizeChange: ({ width, height }) => setPanelWidth(width - 30)
        }}>
            <div style={{
                overflow: 'hidden',
                width: '100%'
            }}>
                <Scheduler
                width={panelWidth}
                utils={utils}
                events={events}
                resources={resources}
                onDateChange={date => setTargetDate(date)}
                onDelete={onDeleteEvent}
                onNew={onNewEvent}
                onClick={onEventClick}
                onRenderEvent={onRenderEvent}
                onRenderCallout={onRenderCallout}
                onRenderResource={onRenderResource}
                onUpdate={onUpdateEvent}
                options={{
                    resizable: true,
                    moveable: true,
                    crossResourceMove: false
                }} />
            </div>
        </Panel>
    )
}

export const WelcomeOverview = ({ index, options, utils }) => {

    const panelID = 'welcome_overview';
    const [loading, setLoading] = useState(null);
    const [demos, setDemos] = useState([]);
    const [content, setContent] = useState(null);

    const onAnnotationClick = id => {
        let demo = demos.find(prevDemo => prevDemo.id === id);
        if(demo) {
            utils.layer.open({
                id: `demo_details_${demo.id}`,
                abstract: Abstract.create({
                    type: 'demo',
                    object: demo
                }),
                Component: DemoDetails
            })
        }
    }

    const getAnnotations = () => {
        return demos.map(demo => {
            return {
                id: demo.id,
                title: demo.lead.full_name,
                subTitle: Utils.formatAddress(demo.lead.address),
                location: demo.lead.location
            }
        })
    }

    const getOverviewContent = () => {
        if(!content) {
            return null;
        }

        let items = [{
            title: `Leads`,
            value: content.leads.total,
            color: Appearance.colors.tertiary(),
            padding: 'pr-lg-1'
        },{
            title: `Set ${content.demos.total === 1 ? 'Demo':'Demos'}`,
            value: content.demos.total,
            color: Appearance.colors.primary(),
            padding: 'px-lg-1'
        },{
            title: `Upcoming ${content.demos.upcoming === 1 ? 'Demo':'Demos'}`,
            value: content.demos.upcoming,
            color: Appearance.colors.secondary(),
            padding: 'pl-lg-1'
        }]
        return (
            <div
            className={'row p-0 mx-0 mt-0 mb-2'}>
                {items.map((item, index) => {
                    return getOverviewItem(item, index);
                })}
            </div>
        )
    }

    const getOverviewItem = (item, index) => {
        return (
            <div
            key={index}
            className={`col-12 col-lg-4 px-0 py-1 py-lg-0 ${item.padding}`}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 15,
                    borderRadius: 10,
                    border: `2px solid ${item.color}`,
                    padding: '10px 15px 10px 15px',
                    background: Appearance.colors.softGradient(item.color),
                }}>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 50,
                        height: 50,
                        marginBottom: 8
                    }}>
                        <img
                        src={'images/badge-frame-white.png'}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain'
                        }} />
                        <span style={{
                            position: 'absolute',
                            fontSize: 18,
                            fontWeight: 700,
                            color: Appearance.colors.darkGrey
                        }}>{item.value}</span>
                    </div>
                    <span style={{
                        display: 'block',
                        fontSize: 14,
                        fontWeight: 600,
                        color: 'white',
                        lineHeight: 1
                    }}>{item.title}</span>
                </div>
            </div>
        )
    }

    const getTitle = () => {
        let user = utils.user.get();
        let hour = parseInt(moment().format('H'));
        if(hour < 12) {
            return `Good Morning, ${user.first_name}`;
        }
        if(hour < 17) {
            return `Good Afternoon, ${user.first_name}`;
        }
        return `Good Evening, ${user.first_name}`;
    }

    const fetchOverview = async () => {
        try {
            let { overview } = await Request.get(utils, '/users/', {
                type: 'limited_overview'
            });
            setContent(overview)
            setDemos(overview.demos.objects.map(demo => Demo.create(demo)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your account overview. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchOverview();
        utils.events.on(panelID, 'program_change', fetchOverview);
        utils.events.on(panelID, 'update_overview', fetchOverview);
        utils.content.subscribe(panelID, [ 'demo', 'demo_request', 'lead' ], {
            onFetch: fetchOverview,
            onUpdate: fetchOverview
        })
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'program_change');
            utils.events.off(panelID, 'update_overview');
        }

    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '6px 12px 6px 12px'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: 12
                }}>
                    <span style={{
                        fontSize: 18,
                        fontWeight: 800,
                        color: Appearance.colors.text(),
                        lineHeight: 1.25
                    }}>{getTitle()}</span>
                    <span style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: Appearance.colors.subText()
                    }}>{`Here is what's happening with your account today`}</span>
                </div>

                {getOverviewContent()}
                <Map
                isZoomEnabled={true}
                isScrollEnabled={true}
                annotations={getAnnotations()}
                onAnnotationClick={onAnnotationClick}
                useShadows={false}
                style={{
                    width: '100%',
                    height: 350,
                    border: `1px solid ${Appearance.colors.divider()}`
                }}/>
            </div>
        </Panel>
    )
}

// Layers
export const AddEditUser = ({ isNewTarget, level }, { abstract, options, utils }) => {

    const layerID = isNewTarget ? 'new_user' : `edit_user_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [program, setProgram] = useState(null);
    const [user, setUser] = useState(null);

    const onDoneClick = async () => {
        try {

            setLoading('done');
            await Utils.sleep(1);
            await validateRequiredFields(getFields);

            // create target
            if(isNewTarget) {
                await abstract.object.submit(utils, {
                    program_id: program ? program.id : null
                });

                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `${abstract.object.full_name}'s account has been created`,
                    onClick: () => setLayerState('close')
                });
                return;
            }

            // update target
            await abstract.object.update(utils);
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.object.full_name}'s account has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this account. ${e.message || 'An unknown error occurred'}`
            })
        }
        return;
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props)
            setUser(edits);
        } catch(e) {
            console.log(e.message);
        }
    }

    const getFields = () => {

        if(!user) {
            return [];
        }

        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'first_name',
                title: 'First Name',
                description: `What is the first name for this account's primary user?`,
                value: user.first_name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ first_name: text })
            },{
                key: 'last_name',
                title: 'Last Name',
                description: `What is the last name for this account's primary user?`,
                value: user.last_name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ last_name: text })
            }]
        },{
            key: 'account',
            title: 'Account',
            items: [{
                key: 'username',
                title: 'Username',
                description: `The username for this account will be used whenever logging into the Global Data mobile app or website.`,
                value: user.username,
                component: 'textfield',
                onChange: text => onUpdateTarget({ username: text }),
            },{
                key: 'password',
                title: 'Password',
                description: `The password for this account will be used whenever logging into the Global Data mobile app or website.`,
                required: isNewTarget,
                value: user.password,
                component: 'textfield',
                visible: isNewTarget,
                onChange: text => onUpdateTarget({ password: text }),
                props: {
                    isSecure: true
                }
            },{
                key: 'program',
                required: false,
                visible: isNewTarget && user.level === User.level.safety_associate,
                title: 'Program',
                description: `Would you like to enroll this Safety Associate in a Program? This can be done at a later date if needed.`,
                value: program,
                component: 'program_lookup',
                onChange: program => setProgram(program)
            }]
        },{
            key: 'contact',
            title: 'Contact Information',
            items: [{
                key: 'email_address',
                title: 'Email Address',
                description: `What is the email address for this account's primary user?`,
                value: user.email_address,
                component: 'textfield',
                onChange: text => onUpdateTarget({ email_address: text })
            },{
                key: 'phone_number',
                title: 'Phone Number',
                description: `What is the phone number for this account's primary user?`,
                value: user.phone_number,
                component: 'textfield',
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: {
                    format: 'phone_number'
                }
            },{
                key: 'address',
                title: 'Physical Address',
                description: `What is the physical address for this account's primary user?`,
                value: user.address,
                component: 'address_lookup',
                onChange: props => {
                    console.log(props);
                    onUpdateTarget(props)
                }
            }]
        }]

        return items;
    }

    const setupTarget = async () => {
        try {
            await abstract.object.open();
            let edits = await abstract.object.set({ level: User.level.safety_associate });
            if(isNewTarget) {
                edits = await abstract.object.set({ dealership: utils.dealership.get() });
            }
            setUser(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this account. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New User' : `Editing ${abstract.getTitle()}`}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const NotificationDetails = ({ abstract, index, options, utils }) => {

    const layerID = `notification_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const getFields = () => {

        let notification = abstract.object;
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: notification.id
            },{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(notification.date)
            },{
                key: 'title',
                title: 'Title',
                value: notification.title
            },{
                key: 'description',
                title: 'Message',
                value: notification.message
            }],
            style: {
                marginBottom: 0
            }
        }];
    }

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}>
            <FieldMapper fields={getFields()} />
        </Layer>
    )
}

export const UserDetails = ({ abstract, options, utils }) => {

    const layerID = `user_details_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [nextDemo, setNextDemo] = useState(null);
    const [dealership, setDealership] = useState(abstract.object.dealership);

    const getFields = () => {

        let user = abstract.object;
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'user_id',
                title: 'User ID',
                value: user.user_id
            },{
                key: 'name',
                title: 'First and Last Name',
                value: user.full_name
            },{
                key: 'level',
                title: 'Account Type',
                value: User.utils.getLevelText(user.level)
            }]
        },{
            key: 'contact',
            title: 'Contact Information',
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                visible:  user.address && user.location ? true : false,
                value: user.location
            },{
                key: 'email',
                title: 'Email Address',
                value: user.email_address
            },{
                key: 'phone',
                title: 'Phone Number',
                value: user.phone_number
            },{
                key: 'address',
                title: 'Physical Address',
                value: user.address ? Utils.formatAddress(user.address) : null
            }]
        },{
            key: 'ext_details',
            title: 'Extended Details',
            visible: user.level < User.level.customer,
            items: [{
                key: 'next_demo',
                title: 'Next Demo',
                value: nextDemo ? moment(nextDemo).format('MMMM Do, YYYY [at] h:mma') : 'Not available'
            },{
                key: 'last_login',
                title: 'Last Login',
                value: user.last_login ? moment(user.last_login).format('MMMM Do, YYYY [at] h:mma') : 'Not available'
            },{
                key: 'active',
                title: 'Active',
                value: user.active ? 'Yes' : 'No'
            }]
        }]

        return items;
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_user_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditUser.bind(this, {
                isNewTarget: false
            })
        })
    }

    const onChangeActiveStatus = () => {
        utils.alert.show({
            title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set ${abstract.object.full_name} as ${abstract.object.active ? 'inactive' : 'active'}?`,
            buttons: [{
                key: 'confirm',
                title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm();
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            await Request.post(utils, '/users/', {
                type: 'set_active_status',
                active: !abstract.object.active,
                user_id: abstract.getID()
            });

            abstract.object.active = !abstract.object.active;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This account has been set as ${abstract.object.active ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchNextDemo = async () => {
        try {
            let { date } = await Request.get(utils, '/users/', {
                type: 'next_demo',
                user_id: abstract.getID()
            });
            setNextDemo(date ? moment(date) : null);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue the next demo date for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchDealership = async () => {
        try {
            if(!abstract.object.dealership) {
                let dealership = await Dealership.get(utils, abstract.object.dealership_id);
                abstract.object.dealership = dealership;
                setDealership(dealership);
            }
        } catch(e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        fetchDealership();
        fetchNextDemo();
        utils.content.subscribe(layerID, 'demo', {
            onUpdate: fetchNextDemo
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for ${abstract.getTitle()}`}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>
            <FieldMapper fields={getFields()} />
        </Layer>
    )
}
