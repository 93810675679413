import React from 'react';

class AbstractClass {

    type = null;
    object = null;

    constructor(props) {
        return this;
    }

    create = (props = {}) => {
        this.type = props.type;
        this.object = props.object;
        return this;
    }

    getType = () => this.type;
    getTag = () => `${this.type}-${this.getID()}`;
    getID = () => [ 'user' ].includes(this.type) ? this.object.user_id : this.object.id;

    getTitle = () => {

        switch(this.type) {

            case 'call_log':
            return `Call Log for Lead #${this.object.lead_id}`;

            case 'dealership':
            return this.object.id ? `"${this.object.name}" Dealership` : 'New Dealership';

            case 'demo':
            return this.object.id ? `Demo for ${this.object.lead.full_name}` : 'New Demo';

            case 'demo_request':
            return this.object.id ? `Demo Request for ${this.object.lead.full_name}` : 'New Demo Request';

            case 'feedback_response':
            return `Customer Response from ${this.object.full_name}`;

            case 'feedback_template':
            return this.object.id ? `"${this.object.title}" Feedback Template` : 'New Feedback Template';

            case 'location':
            return this.object.id ? `Location "${this.object.name}"` : 'New Location';

            case 'notification':
            return this.object.title;

            case 'program':
            return this.object.id ? `${this.object.name}` : 'New Program';

            case 'lead':
            return this.object.id ? `Lead for ${this.object.full_name}` : 'New Lead';

            case 'lead_program':
            return this.object.id ? `Lead Program for ${this.object.full_name}` : 'New Lead Program';

            case 'template':
            return this.object.id ? `"${this.object.title}" Template` : 'New Template';

            case 'user':
            return this.object.user_id ? (this.object.full_name || 'User Account') : 'New User';

            case 'user_group':
            return this.object.id ? `"${this.object.title}" User Group` : 'New User Group';

            default:
            return 'Unknown Object';
        }
    }
}
export default {
    create: props => new AbstractClass().create(props)
};

export const buildTag = (props) => {

    let so = new AbstractClass();
    so.type = props.type;
    so.object = props.object || {
        ...(props.type !== 'users' ? {
            id: props.id
        }:{
            user_id: props.id
        })
    }
    return so.getTag();
}
