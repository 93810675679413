import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

import Appearance from 'styles/Appearance.js';

const CalendarEvent = ({ color, children, className, header, height, isRequest, offset, onClick, style }) => {

    const [animations, setAnimations] = useSpring(() => ({
        transform: 'scale(1)',
        config: { mass: 1, tension: 180, friction: 12 }
    }));

    const onMouseEnter = () => {
        setAnimations({
            transform: 'scale(1.05)'
        })
    }

    const onMouseLeave = () => {
        setAnimations({
            transform: 'scale(1)'
        })
    }

    const getBackgroundStyles = () => {
        let styles = [];
        if(isRequest) {
            styles.push(`repeating-linear-gradient(-45deg, rgba(255,255,255,0.25), rgba(255,255,255,0.25) 10px, rgba(255,255,255,0.1) 10px, rgba(255,255,255,0.1) 20px)`);
        }
        styles.push(Appearance.colors.softGradient(color));
        return styles.join(',');
    }

    return (
        <animated.div
        onClick={onClick}
        className={'cursor-pointer'}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{
            position: 'absolute',
            width: '100%',
            zIndex: 1,
            top: offset || 0,
            height: height || '100%',
            ...style,
            ...animations
        }}>
            <div
            className={className}
            style={{
                height: '100%',
                borderRadius: 10,
                border: `2px solid ${color}`,
                background: getBackgroundStyles(),
                overflow: 'hidden'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    backgroundColor: color,
                    padding: '4px 10px 4px 10px',
                    overflow: 'hidden'
                }}>
                    <div style={{
                        flexShrink: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingRight: 12,
                        minWidth: 0
                    }}>
                        <div style={{
                            minWidth: 5,
                            minHeight: 5,
                            width: 5,
                            height: 5,
                            borderRadius: 2.5,
                            backgroundColor: 'white',
                            marginRight: 8
                        }} />
                        <span style={{
                            display: 'block',
                            fontSize: 10,
                            fontWeight: 700,
                            color: 'white',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            minWidth: 0,
                            flexShrink: 1
                        }}>{header ? header.primary : ''}</span>
                    </div>
                    <span style={{
                        display: 'block',
                        fontSize: 10,
                        fontWeight: 500,
                        color: 'white',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        minWidth: 0
                    }}>{header ? header.secondary : ''}</span>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10
                }}>
                    {children}
                </div>
            </div>
        </animated.div>
    )
}
export default CalendarEvent;
