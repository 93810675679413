import React, { useRef, useState, useEffect } from 'react';

import { animated, useSpring } from 'react-spring';
import browserInfo from 'browser-info';
import update from 'immutability-helper';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Cookies from 'js-cookie';
import LottieView from 'views/Lottie.js';
import Program from 'classes/Program.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import QueryString from 'query-string';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

const Login = ({ onLogin, utils }) => {

    const credentials = useRef({});
    const [loading, setLoading] = useState(false);
    const [loginVisible, setLoginVisible] = useState(false);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const [loginCredentials, setLoginCredentials] = useSpring(() => ({
        opacity: 0,
        maxHeight: 0,
        transform: 'scale(0)',
        config: { mass: 1, tension: 180, friction: 12 }
    }))

    const [login, setLogin] = useState({
        scale: 0.75,
        opacity: 0
    });

    const onButtonClick = () => {
        if(!loginVisible) {
            setLoginVisible(true);
            setLoginCredentials({
                opacity: 1,
                maxHeight: 250,
                transform: 'scale(1)'
            })
            return;
        }
        onRunLogin();
    }

    const onKeyDown = e => {
        if(e.keyCode === 13) {
            e.preventDefault();
            onRunLogin();
        }
    }

    const onLoginClick = async () => {

        let _username = username || credentials.current.username;
        let _password = password || credentials.current.password;
        if(!_username || !_password) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please enter your username and password to continue'
            })
            return;
        }

        try {

            setLoading(true);
            await utils.loader.show();
            let { name, os } = browserInfo();

            let { groups, programs, user } = await Request.post(utils, '/users/', {
                type: 'login',
                username: _username,
                password: _password,
                browser: name,
                platform: os
            });

            if(user.level !== User.level.safety_associate) {
                setLoading(false);
                await utils.loader.hide();
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'It looks like your account is not able to use these services. Please login to Dealer and Administrator website to continue',
                    buttons: [{
                        key: 'confirm',
                        title: 'Take Me There',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Okay',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            window.location.href = `${API.server}`;
                            return;
                        }
                    }
                });
                return;
            }

            if(typeof(onLogin) === 'function') {

                document.removeEventListener('keydown', onKeyDown);
                setLogin(login => update(login, {
                    scale: {
                        $set: 0.75
                    },
                    opacity: {
                        $set: 0
                    }
                }))
            }

            await Utils.sleep(1);
            setLoading(false);
            onLogin({
                user: User.create(user),
                groups: groups ? groups.map(group => User.Group.create(group)) : [],
                programs: programs ? programs.map(program => Program.create(program)) : null
            });

        } catch(e) {
            setLoading(false);
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onRunLogin = () => {
        setLoginVisible(false);
        setLoginCredentials({
            opacity: 0,
            maxHeight: 0,
            transform: 'scale(0)'
        })
        onLoginClick();
    }

    const onSupportPress = () => {
        window.open(`${API.server}/reset-password/index.html`);
    }

    useEffect(() => {
        credentials.current = {
            username: username,
            password: password
        }
    }, [username, password]);

    useEffect(() => {
        if(loginVisible === true) {
            document.addEventListener('keydown', onKeyDown);
        }
        if(loginVisible === false) {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, [loginVisible]);

    useEffect(() => {
        setLogin(login => update(login, {
            scale: {
                $set: 1
            },
            opacity: {
                $set: 1
            }
        }))
        if(API.dev_env) {
            setTimeout(() => {
                credentials.current = {
                    username: 'stephen_stone',
                    password: 'redhouse10'
                }
                onRunLogin();
            }, 250);
        }
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, [])

    return (
        <VelocityComponent
        easing={[250, 20]}
        duration={750}
        animation={{
            scale: login.scale,
            opacity: login.opacity
        }}>
            <div style={{
                width: '100%',
                maxWidth: 400
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 30,
                    textAlign: 'center'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={require('files/lottie/logo-desktop.json')}
                    style={{
                        width: 250,
                        height: 250
                    }}/>
                    <span style={{
                        fontSize: 22,
                        fontWeight: 800,
                        color: Appearance.colors.text(),
                        lineHeight: 1.5
                    }}>{'Global Data'}</span>
                    <span style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: Appearance.colors.subText(),
                        lineHeight: 1.1
                    }}>{'Powered by Global Health and Safety'}</span>

                    <animated.div style={{
                        width: '100%',
                        paddingTop: 20,
                        paddingBottom: loginVisible ? 15 : 0,
                        ...loginCredentials
                    }}>
                        <div style={{
                            ...Appearance.styles.panel(),
                            padding: 20
                        }}>
                            <TextField
                            icon={'user'}
                            autoCapitalize={'none'}
                            placeholder={'Username'}
                            value={username}
                            useDelay={false}
                            onChange={text => setUsername(text)}
                            fieldStyle={{
                                color: Appearance.colors.text(),
                                fontSize: 12,
                                fontWeight: '500',
                            }}/>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width:'100%',
                                height: 25,
                                marginTop: 10,
                                marginBottom: 10
                            }}>
                                <TextField
                                icon={'lock'}
                                isSecure={true}
                                placeholder={'Password'}
                                value={password}
                                useDelay={false}
                                onChange={text => setPassword(text)}
                                fieldStyle={{
                                    color: Appearance.colors.text(),
                                    fontSize: 12,
                                    fontWeight: '500'
                                }}
                                containerStyle={{
                                    paddingRight: 10,
                                    width: '100%'
                                }} />

                                <img
                                className={'image-button'}
                                onClick={onSupportPress}
                                src={'images/help-button-grey.png'}
                                style={{
                                    width: 25,
                                    height: 25,
                                    objectFit: 'contain',
                                    boxShadow: window.theme === 'dark' ? '2.5px 2.5px 7.5px rgba(0,0,0,0.25), -2.5px -2.5px 5px rgba(255,255,255,0.15)' : '2.5px 2.5px 7.5px rgba(0,0,0,0.075), -2.5px -2.5px 5px rgba(255,255,255,0.35)',
                                    borderRadius: 12.5,
                                    marginLeft: 8
                                }}/>
                            </div>
                        </div>
                    </animated.div>

                    <Button
                    label={loginVisible ? 'Done' : 'Login'}
                    type={'transition'}
                    color={'primary'}
                    loading={loading}
                    onClick={onButtonClick}
                    transition={{
                        icon: 'key-icon-white.png'
                    }}/>
                </div>
            </div>
        </VelocityComponent>
    )
}

export default Login;
