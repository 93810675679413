import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';

import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import BookDemo from 'views/BookDemo.js';
import BoolToggle from 'views/BoolToggle.js';
import CalendarEvent from 'views/CalendarEvent.js';
import CallLog from 'classes/CallLog.js';
import DatePickerField from 'views/DatePickerField.js';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import Demo from 'classes/Demo.js';
import DemoRequest from 'classes/DemoRequest.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import DurationPickerField from 'views/DurationPickerField.js';
import EditTarget from 'views/EditTarget.js';
import Feedback from 'classes/Feedback.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';
import { Map } from 'views/MapElements.js';
import NewCalendarEvent from 'views/NewCalendarEvent.js';
import Panel from 'structure/Panel.js';
import PickerField from 'views/PickerField.js';
import Lead from 'classes/Lead.js';
import { LeadDetails, LeadLookup, AddEditLead } from 'managers/Leads.js';
import Request from 'files/Request.js';
import TextView from 'views/TextView.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

// Panels
export const DemosList = ({ index, options, utils }) => {

    const panelID = 'all_demos';
    const limit = 10;
    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [demos, setDemos] = useState([]);

    const [sortOrder, setSortOrder] = useState({
        key: 'name',
        forward: false
    });

    const getFields = (demo, index) => {

        let target = demo || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: utils.groups.apply([ 'first_name', 'last_name' ], User.Group.categories.leads, target.lead.full_name),
                    subTitle: utils.groups.apply('address', User.Group.categories.leads, Utils.formatAddress(target.lead.address)),
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onDemoClick.bind(this, demo)
                })
            )
        }

        let fields = [{
            key: 'full_name',
            title: 'Full Name',
            value: target.lead ? utils.groups.apply([ 'first_name', 'last_name' ], User.Group.categories.leads, target.lead.full_name) : null
        },{
            key: 'address',
            title: 'Address',
            value: target.lead ? utils.groups.apply('address', User.Group.categories.leads, target.lead.address ? target.lead.address.address : null) : null
        },{
            key: 'start_date',
            title: 'Date',
            value: target.start_date ? utils.groups.apply('start_date', User.Group.categories.demos, moment(target.start_date).format('MMMM Do, YYYY [at] h:mma')) : null
        },{
            key: 'status',
            title: 'Status',
            value: utils.groups.apply('status', User.Group.categories.demos, getDemoStatus(target))
        }];

        // Headers
        if(!demo) {
            return (
                <tr style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{field.title}</span>
                        </td>
                    )
                })}
                </tr>
            )
        }

        // Rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onDemoClick.bind(this, demo)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const fetchDemos = async () => {
        try {
            let { demos, paging } = await Request.get(utils, '/demos/', {
                type: 'all',
                offset: offset,
                limit: limit,
                search_text: searchText
            });

            setPaging(paging);
            setDemos(demos.map(demo => Demo.create(demo)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the Demos list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDemoClick = demo => {
        utils.layer.open({
            id: `demo_details_${demo.id}`,
            abstract: Abstract.create({
                type: 'demo',
                object: demo
            }),
            Component: DemoDetails
        });
    }

    const getContent = () => {
        if(demos.length === 0) {
            return (
                Views.entry({
                    title: 'No Demos Found',
                    subTitle: 'No Demos were found in the system',
                    singleItem: true,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return demos.map((demo, index) => {
                return getFields(demo, index)
            })
        }

        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {demos.map((demo, index) => {
                        return getFields(demo, index)
                    })}
                </tbody>
            </table>
        )
    }

    useEffect(() => {
        fetchDemos();
    }, [offset, searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'program_change', fetchDemos);
        utils.content.subscribe(panelID, [ 'demo' ], {
            onFetch: fetchDemos,
            onUpdate: abstract => {
                setDemos(demos => {
                    return demos.map(demo => {
                        return demo.id === abstract.getID() ? abstract.object : demo
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'program_change');
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Demos'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            search: {
                placeholder: 'Search by first or last name...',
                onChange: text => setSearchText(text)
            },
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: nextOffset => setOffset(nextOffset)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                overflow: 'hidden'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const DemoRequestsList = ({ index, options, utils }) => {

    const panelID = 'all_demo_requests';
    const limit = 10;
    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [demos, setDemos] = useState([]);

    const [sortOrder, setSortOrder] = useState({
        key: 'name',
        forward: false
    });

    const getFields = (demo, index) => {

        let target = demo || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: target.lead.full_name,
                    subTitle: Utils.formatAddress(target.lead.address),
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onDemoClick.bind(this, demo)
                })
            )
        }

        let fields = [{
            key: 'name',
            title: 'Name',
            value: target.lead ? target.lead.full_name : null
        },{
            key: 'address',
            title: 'Address',
            value: target.lead && target.lead.address ? target.lead.address.address : null
        },{
            key: 'date',
            title: 'Date',
            value: target.date ? moment(target.date).format('MMMM Do, YYYY [at] h:mma') : null
        },{
            key: 'status',
            title: 'Status',
            value: getDemoStatus(target)
        }];

        // Headers
        if(!demo) {
            return (
                <tr style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{field.title}</span>
                        </td>
                    )
                })}
                </tr>
            )
        }

        // Rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onDemoClick.bind(this, demo)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const fetchDemos = async () => {
        try {
            let { requests, paging } = await Request.get(utils, '/demos/', {
                type: 'all_requests',
                offset: offset,
                limit: limit,
                search_text: searchText
            });

            setPaging(paging);
            setDemos(requests.map(request => DemoRequest.create(request)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the Demo Requests list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDemoClick = demo => {
        utils.layer.open({
            id: `demo_request_details_${demo.id}`,
            abstract: Abstract.create({
                type: 'demo_request',
                object: demo
            }),
            Component: DemoRequestDetails
        })
    }

    const getContent = () => {
        if(demos.length === 0) {
            return (
                Views.entry({
                    title: 'No Demo Requests Found',
                    subTitle: 'No Demo Requests were found in the system',
                    singleItem: true,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return demos.map((demo, index) => {
                return getFields(demo, index)
            })
        }

        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {demos.map((demo, index) => {
                        return getFields(demo, index)
                    })}
                </tbody>
            </table>
        )
    }

    useEffect(() => {
        fetchDemos();
    }, [searchText, offset]);

    useEffect(() => {
        utils.events.on(panelID, 'program_change', fetchDemos);
        utils.content.subscribe(panelID, [ 'demo_request' ], {
            onFetch: fetchDemos,
            onUpdate: abstract => {
                setDemos(demos => {
                    return demos.map(demo => {
                        return demo.id === abstract.getID() ? abstract.object : demo
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'program_change');
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Demo Requests'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            search: {
                placeholder: 'Search by first or last name...',
                onChange: text => setSearchText(text)
            },
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: nextOffset => setOffset(nextOffset)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                overflow: 'hidden'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const DemosCalendar = ({ index, options, utils }) => {

    const panelID = 'demos_calendar';
    const blockHeight = 125;
    const timeLabelWidth = 75;
    const slots = [...new Array(19)];

    const [loading, setLoading] = useState(null);
    const [currentTime, setCurrentTime] = useState(moment());
    const [targetDate, setTargetDate] = useState(moment());
    const [events, setEvents] = useState([]);
    const [times, setTimes] = useState([]);
    const [weekdays, setWeekdays] = useState([]);
    const [showWeekends, setShowWeekends] = useState(false);

    const setupCurrentTimeMonitor = async () => {
        return setInterval(() => {
            setCurrentTime(moment());
        }, 60000)
    }

    const setTimeslots = () => {
        let date = moment(targetDate);
        let times = getTimeslots(date);
        setTimes(times);
    }

    const getTimeslots = date => {
        let start = moment(date).startOf('day').add(5, 'hours');
        return slots.map((_, index) => {
            let nextDate = moment(start).add(index, 'hours');
            return {
                date: nextDate,
                unix: {
                    start: nextDate.unix(),
                    end: moment(nextDate).add(1, 'hours').unix()
                }
            };
        });
    }

    const setWeekDays = () => {
        let start = moment(targetDate).startOf('week');
        let days = [...new Array(7)].map((_, index) => {
            return moment(start).add(index, 'days');
        }).filter(date => {
            if(!showWeekends) {
                return date.day() !== 0 && date.day() !== 6;
            }
            return true;
        });
        setWeekdays(days);
    }

    const onAddNewDemo = timeslot => {

        utils.alert.show({
            title: 'Create New Demo',
            message: `Before you create a new Demo we'll need to ask you some questions about your Lead. After you create your Lead we'll give you some options for scheduling a Demo.`,
            buttons: [{
                key: 'confirm',
                title: 'Get Started',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {

                    let lead = Lead.new();
                    lead.enrollment_user = utils.user.get();
                    lead.program_credit = utils.program.get();

                    utils.layer.open({
                        id: 'new_lead',
                        abstract: Abstract.create({
                            type: 'lead',
                            object: lead
                        }),
                        Component: AddEditLead.bind(this, {
                            isNewTarget: true,
                            onAddLead: lead => onBookDemo(lead, timeslot || {})
                        })
                    })
                }
            }
        })
    }

    const onBookDemo = (lead, defaultDate) => {
        utils.layer.open({
            id: `book_demo_${lead.id}`,
            abstract: Abstract.create({
                type: 'lead',
                object: lead
            }),
            Component: BookDemoFromLead.bind(this, {
                defaultDate: defaultDate
            })
        });
    }

    const onEventClick = target => {
        if(target.type === 'demo') {
            utils.layer.open({
                id: `demo_details_${target.obj.id}`,
                abstract: Abstract.create({
                    type: 'demo',
                    object: target.obj
                }),
                Component: DemoDetails
            });
            return;
        }
        utils.layer.open({
            id: `demo_request_details_${target.obj.id}`,
            abstract: Abstract.create({
                type: 'demo_request',
                object: target.obj
            }),
            Component: DemoRequestDetails
        });
    }

    const getCurrentTime = () => {

        if(!currentTime) {
            return null;
        }

        let start = moment().startOf('day').add(8, 'hours');
        let seconds = moment(currentTime).diff(start, 'seconds');
        let top = parseInt(blockHeight * (seconds / 3600));
        return (
            <div style={{
                position: 'absolute',
                left: 10,
                top: top,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%'
            }}>
                <div style={{
                    minWidth: 0,
                    backgroundColor: Appearance.colors.primary(),
                    padding: '2px 8px 2px 8px',
                    borderRadius: 5,
                    textAlign: 'center'
                }}>
                    <span style={{
                        display: 'block',
                        fontSize: 10,
                        fontWeight: 500,
                        color: 'white',
                        whiteSpace: 'nowrap'
                    }}>{currentTime.format('h:mm a')}</span>
                </div>
                <div style={{
                    flexGrow: 1,
                    width: '100%',
                    height: 2,
                    borderRadius: 1,
                    backgroundColor: Appearance.colors.primary()
                }} />
            </div>
        )
    }

    const getEventColor = calEvent => {
        let style = Demo.styles.status.find(style => style.status === calEvent.status.code);
        return style ? style.color : Appearance.colors.grey();
    }

    const getEvent = (time, index) => {

        let { start, end } = time.unix;
        let calEvent = events.find(prevCalEvent => {
            return prevCalEvent.start >= start && prevCalEvent.start < end;
        })

        // Matching event
        if(calEvent) {
            let { lead } = calEvent.obj;
            let color = getEventColor(calEvent);
            let offset = blockHeight * ((calEvent.start - start) / 3600);
            let calc = blockHeight * ((calEvent.end - calEvent.start) / 3600);
            let span = Math.ceil(calc / blockHeight);
            let height = calc + (span === 1 ? -10 : (span - 1 * 10));
            return (
                <CalendarEvent
                key={index}
                isRequest={calEvent.type === 'demo_request'}
                offset={offset}
                color={color}
                height={height}
                onClick={onEventClick.bind(this, calEvent)}
                header={{
                    primary: calEvent.status.text,
                    secondary: moment.unix(calEvent.start).format('h:mma')
                }}>
                    <span style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: 'white'
                    }}>{lead.full_name}</span>
                    <span style={{
                        fontSize: 10,
                        fontWeight: 500,
                        color: 'white',
                        opacity: 0.8
                    }}>{Utils.formatAddress(lead.address)}</span>
                </CalendarEvent>
            )
        }

        // Overlap
        let overlap = events.find(prevCalEvent => {
            return prevCalEvent.end > start && prevCalEvent.end < end;
        });
        if(overlap) {
            return (
                <div style={{
                    width: 0,
                    height: 0
                }}/>
            )
        }
        return null;
    }

    const onChangeWeekends = enabled => {
        // Set target date to non-weekend if applicable
        if(!enabled) {
            if(targetDate.day() === 0) {
                setTargetDate(moment(targetDate).add(1, 'days'));
            }
            if(targetDate.day() === 6) {
                setTargetDate(moment(targetDate).subtract(1, 'days'));
            }
        }
        setShowWeekends(enabled);
    }

    const fetchEvents = async () => {
        try {
            let { events } = await Request.get(utils, '/demos/', {
                type: 'all_calendar',
                user_id: utils.user.get().user_id,
                include_requests: true
            });

            setEvents(events.map(target => {
                let calEvent = target.obj;
                switch(target.type) {
                    case 'demo':
                    return {
                        id: calEvent.id,
                        type: target.type,
                        start: moment(calEvent.start_date).unix(),
                        end: moment(calEvent.end_date).unix(),
                        status: calEvent.status,
                        obj: Demo.create(calEvent)
                    }

                    case 'demo_request':
                    return {
                        id: calEvent.id,
                        type: target.type,
                        start: moment(calEvent.date).unix(),
                        end: moment(calEvent.date).add(1, 'hours').unix(),
                        status: calEvent.lead.status,
                        obj: DemoRequest.create(calEvent)
                    }
                }
            }))

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setWeekDays();
    }, [targetDate, showWeekends]);

    useEffect(() => {

        fetchEvents();
        setTimeslots();
        utils.events.on(panelID, 'program_change', fetchEvents);
        utils.content.subscribe(panelID, [ 'demo', 'demo_request', 'lead' ], {
            onFetch: fetchEvents,
            onUpdate: abstract => {
                setEvents(events => events.map(prevEvent => {
                    if(prevEvent.id === abstract.getID() && prevEvent.type === abstract.type) {
                        switch(abstract.type) {
                            case 'demo':
                            return {
                                id: abstract.getID(),
                                type: abstract.type,
                                start: moment(abstract.object.start_date).unix(),
                                end: moment(abstract.object.end_date).unix(),
                                status: abstract.object.status,
                                obj: abstract.object
                            }

                            case 'demo_request':
                            return {
                                id: abstract.getID(),
                                type: abstract.type,
                                start: moment(abstract.object.date).unix(),
                                end: moment(abstract.object.date).add(1, 'hours').unix(),
                                status: abstract.object.lead.status,
                                obj: abstract.object
                            }
                        }
                    }
                    return prevEvent
                }))
            }
        })

        let interval = setupCurrentTimeMonitor();
        return () => {
            clearInterval(interval);
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'program_change');
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Demos Calendar'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            buttons: [{
                key: 'new',
                title: 'New Demo',
                style: 'default',
                onClick: onAddNewDemo
            }]
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                paddingBottom: 15,
                marginBottom: 25,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <DatePickerField
                utils={utils}
                dateTime={false}
                icon={'calendar'}
                selected={targetDate}
                showWeekends={showWeekends}
                onDateChange={date => setTargetDate(date)}
                style={{
                    marginTop: 8,
                    marginBottom: 8
                }}
                {...(Utils.isMobile() ? null : {
                    blockStyle: 'week',
                    insetLabel: 'The Week of',
                    highlightDates: date => {
                        return date.unix() >= moment(targetDate).startOf('week') && date.unix() <= moment(targetDate).endOf('week');
                    },
                    insetLabelStyle: {
                        minWidth: 65
                    }
                })} />
                <BoolToggle
                width={150}
                isEnabled={showWeekends}
                enabled={'Show Weekends'}
                disabled={'Hide Weekends'}
                onChange={onChangeWeekends}/>
            </div>
            <div style={{
                flexGrow: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 0,
                paddingLeft: timeLabelWidth,
                marginTop: 12,
                marginBottom: 12
            }}>
                {weekdays.filter(day => {
                    if(Utils.isMobile()) {
                        return day.isSame(targetDate, 'day')
                    }
                    return true;
                }).map((day, index) => {
                    return (
                        <div
                        key={index}
                        style={{
                            flexGrow: 1,
                            width: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <span style={{
                                fontWeight: 700,
                                fontSize: 14,
                                color: Appearance.colors.text()
                            }}>{day.format('dddd')}</span>
                            <span style={{
                                fontWeight: 500,
                                fontSize: 12,
                                color: Appearance.colors.subText()
                            }}>{showWeekends ? day.format('MMM Do') : day.format('MMMM Do')}</span>
                        </div>
                    )
                })}
            </div>
            <div style={{
                position: 'relative'
            }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: timeLabelWidth
                    }}>
                        {times.map((time, index) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    height: blockHeight
                                }}>
                                    <span style={{
                                        fontWeight: 600,
                                        fontSize: 14,
                                        color: Appearance.colors.subText()
                                    }}>{time.date.format('h a')}</span>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexGrow: 1
                    }}>
                        {weekdays.filter(day => {
                            if(Utils.isMobile()) {
                                return day.isSame(targetDate, 'day')
                            }
                            return true;
                        }).map((day, index) => {
                            let timeslots = getTimeslots(day);
                            return (
                                <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}>
                                    {timeslots.map((time, index) => {
                                        return (
                                            <div
                                            key={index}
                                            style={{
                                                width: '100%',
                                                height: blockHeight,
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                                paddingTop: 10
                                            }}>
                                                <div style={{
                                                    ...Appearance.styles.unstyledPanel(),
                                                    position: 'relative',
                                                    height: '100%'
                                                }}>
                                                    {getEvent(time, index) || (
                                                        <NewCalendarEvent onClick={onAddNewDemo.bind(this, time)}/>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {getCurrentTime()}
            </div>
        </Panel>
    )
}

export const DemoLocations = ({ index, options, utils }) => {

    const panelID = 'demo_locations';
    const [loading, setLoading] = useState(null);
    const [demos, setDemos] = useState([]);

    const onAnnotationClick = id => {
        let demo = demos.find(prevDemo => prevDemo.id === id);
        if(demo) {
            utils.layer.open({
                id: `demo_details_${demo.id}`,
                abstract: Abstract.create({
                    type: 'demo',
                    object: demo
                }),
                Component: DemoDetails
            })
        }
    }

    const getAnnotations = () => {
        return demos.map(demo => {
            return {
                id: demo.id,
                title: demo.lead.full_name,
                subTitle: Utils.formatAddress(demo.lead.address),
                location: demo.lead.location
            }
        })
    }

    const fetchOverview = async () => {
        try {
            let { overview } = await Request.get(utils, '/users/', {
                type: 'limited_overview'
            });
            setDemos(overview.demos.objects.map(demo => Demo.create(demo)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your account overview. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchOverview();
        utils.events.on(panelID, 'program_change', fetchOverview);
        return () => {
            utils.events.off(panelID, 'program_change');
        }

    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Locations'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
        }}>
            <Map
            isZoomEnabled={true}
            isScrollEnabled={true}
            annotations={getAnnotations()}
            onAnnotationClick={onAnnotationClick}
            useShadows={false}
            style={{
                width: '100%',
                height: 350,
                border: `1px solid ${Appearance.colors.divider()}`
            }}/>
        </Panel>
    )
}

// Layers
export const DemoDetails = ({ abstract, index, options, utils }) => {

    const layerID = `demo_details_${abstract.getID()}`;
    const [bookedBy, setBookedBy] = useState(null);
    const [callLogs, setCallLogs] = useState([]);
    const [feedbackTemplate, setFeedbackTemplate] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [partner, setPartner] = useState(null);
    const [rideAlong, setRideAlong] = useState(null);
    const [trainee, setTrainee] = useState(null);

    const getFields = () => {

        let demo = abstract.object;
        let items = [{
            key: 'details',
            title: 'Basic Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: demo.id
            },{
                key: 'start_date',
                title: 'Start Date',
                value: demo.start_date ? moment(demo.start_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'end_date',
                title: 'End Date',
                value: demo.end_date ? moment(demo.end_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'status',
                title: 'Status',
                value: demo.status ? demo.status.text : null
            }]
        },{
            key: 'ext_details',
            title: 'Extended Details',
            items: [{
                key: 'booked_by',
                title: 'Booked By',
                value: bookedBy ? bookedBy.full_name : null
            },{
                key: 'partner',
                title: 'Partner',
                value: partner ? partner.full_name : 'No'
            },{
                key: 'ride_along',
                title: 'Ride Along',
                value: rideAlong ? rideAlong.full_name : 'No'
            },{
                key: 'trainee',
                title: 'Trainee',
                value: trainee ? trainee.full_name : 'No'
            },{
                key: 'feedback_template',
                title: 'Feedback Template',
                value: feedbackTemplate ? feedbackTemplate.title : 'Disabled',
                style: {
                    value: {
                        color: feedbackTemplate ? Appearance.colors.subText() : Appearance.colors.red
                    }
                }
            }]
        }]

        return items;
    }

    const getLeadFields = () => {

        let demo = abstract.object;
        return [{
            key: 'location',
            title: 'Location',
            visible: demo.lead.address && demo.lead.location ? true : false,
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                value: demo.lead.location
            },{
                key: 'address',
                title: 'Address',
                value: Utils.formatAddress(demo.lead.address)
            },{
                key: 'maps',
                title: 'Directions',
                button: {
                    text: 'Click to View',
                    color: 'primary',
                    onClick: () => {
                        let address = Utils.formatAddress(demo.lead.address);
                        window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`)
                    }
                }
            }]
        }];
    }

    const onCallLogClick = log => {
        utils.layer.open({
            id: `call_log_details_${log.id}`,
            abstract: Abstract.create({
                type: 'call_log',
                object: log
            }),
            Component: CallLogDetails
        })
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_demo_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditDemo
        })
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'new_call',
                title: 'Add New Call',
                style: 'default'
            },{
                key: 'reschedule_demo',
                title: 'Reschedule Demo',
                style: 'default'
            }].sort((a,b) => {
                return a.title.localeCompare(b.title)
            })
        }, key => {
            if(key === 'new_call') {
                utils.layer.open({
                    id: `new_call_log_${abstract.object.lead.id}`,
                    abstract: Abstract.create({
                        type: 'call_log',
                        object: CallLog.new()
                    }),
                    Component: AddEditCallLog.bind(this, {
                        isNewTarget: true,
                        lead: abstract.object.lead
                    })
                })
                return;
            }
            if(key === 'reschedule_demo') {
                utils.layer.open({
                    id: `reschedule_demo_${abstract.getID()}`,
                    abstract: Abstract.create({
                        type: 'demo',
                        object: abstract.object
                    }),
                    Component: RescheduleDemo
                });
                return;
            }
        })
    }

    const onUserClick = async userID => {
        try {
            let user = await User.get(utils, userID);
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the account information for this user. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getCallLogs = () => {
        return (
            <LayerItem title={'Calls and Emails'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {callLogs.length > 0
                        ?
                        callLogs.map((log, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: log.author ? log.author.full_name : 'Name not available',
                                    subTitle: moment(log.start_date).format('MMMM Do, YYYY [at] h:mma'),
                                    badge: [{
                                        text: log.direction,
                                        color: log.direction === 'outbound' ? Appearance.colors.grey() : Appearance.colors.primary()
                                    },{
                                        text: log.method,
                                        color: Appearance.colors.secondary()
                                    }],
                                    hideIcon: true,
                                    singleItem: callLogs.length === 1,
                                    firstItem: index === 0,
                                    lastItem: index === callLogs.length - 1,
                                    bottomBorder: true,
                                    onClick: onCallLogClick.bind(this, log)
                                })
                            )
                        })
                        :
                        Views.entry({
                            title: 'No calls found',
                            hideIcon: true,
                            bottomBorder: false
                        })}
                </div>
            </LayerItem>
        )
    }

    const getLead = () => {
        let { lead } = abstract.object;
        if(!lead) {
            return null;
        }
        return (
            <div style={{
                marginBottom: 20
            }}>
                <span style={{
                    ...Appearance.textStyles.subHeader(),
                    display: 'block',
                    marginBottom: 8
                }}>{'Lead'}</span>
                <div style={Appearance.styles.unstyledPanel()}>
                    {Views.entry({
                        title: lead.full_name,
                        subTitle: lead.phone_number,
                        hideIcon: true,
                        singleItem: true,
                        bottomBorder: false,
                        onClick: async () => {
                            try {
                                let lead = await Lead.get(utils, abstract.object.lead.id);
                                utils.layer.open({
                                    id: `lead_details_${lead.id}`,
                                    abstract: Abstract.create({
                                        type: 'lead',
                                        object: lead
                                    }),
                                    Component: LeadDetails
                                })
                            } catch(e) {
                                console.log(e.message);
                            }
                        }
                    })}
                </div>
            </div>
        )
    }

    const getUser = () => {
        let { user } = abstract.object;
        if(!user) {
            return null;
        }
        return (
            <div style={{
                marginBottom: 20
            }}>
                <span style={{
                    ...Appearance.textStyles.subHeader(),
                    display: 'block',
                    marginBottom: 8
                }}>{'Assigned'}</span>
                <div style={Appearance.styles.unstyledPanel()}>
                    {Views.entry({
                        title: user.full_name,
                        subTitle: user.phone_number,
                        icon: {
                            path: user.avatar
                        },
                        singleItem: true,
                        bottomBorder: false,
                        onClick: onUserClick.bind(this, user.user_id)
                    })}
                </div>
            </div>
        )
    }

    const fetchDetails = async () => {
        try {
            let {  booked_by, call_logs, feedback_template, partner, ride_along, trainee } = await Request.get(utils, '/demos/', {
                type: 'ext_details',
                id: abstract.getID()
            })
            if(booked_by) {
                abstract.object.booked_by = User.create(booked_by);
                setBookedBy(abstract.object.booked_by);
            }
            if(partner) {
                abstract.object.partner = User.create(partner);
                setPartner(abstract.object.partner);
            }
            if(ride_along) {
                abstract.object.ride_along = User.create(ride_along);
                setRideAlong(abstract.object.ride_along);
            }
            if(trainee) {
                abstract.object.trainee = User.create(trainee);
                setTrainee(abstract.object.trainee);
            }
            if(feedback_template) {
                abstract.object.feedback_template = Feedback.Template.create(feedback_template);
                setFeedbackTemplate(abstract.object.feedback_template);
            }
            setCallLogs(call_logs.map(log => {
                return CallLog.create(log);
            }));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the additional information for this demo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchDetails();
        utils.content.subscribe(layerID, [ 'call_log', 'user', 'demo' ], {
            onFetch: fetchDetails,
            onUpdate: _abstract => {
                if(_abstract.getTag() === abstract.getTag()) {
                    fetchDetails();
                }
            }
        })

        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`${abstract.getTitle()} Details`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>
            {getUser()}
            {getLead()}

            <FieldMapper
            utils={utils}
            fields={getLeadFields()}
            group={User.Group.categories.leads} />

            <FieldMapper
            utils={utils}
            fields={getFields()}
            group={User.Group.categories.demos} />

            {getCallLogs()}
        </Layer>
    )
}

export const SetDemoStatus = ({ abstract, options, utils }) => {

    const layerID = `set_demo_status_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [items, setItems] = useState(Demo.styles.status);

    const onSetStatus = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let { status } = await Request.post(utils, '/demos/', {
                type: 'set_status',
                id: abstract.getID(),
                status: selectedStatus
            })

            setLoading(false);
            abstract.object.status = status;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: 'The status for this Demo has been updated',
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this Demo. ${e.message || 'An unknown error occurred'}`,
            })
        }
    }

    return (
        <Layer
        id={layerID}
        title={'Set Demo Status'}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Save Changes',
            color: selectedStatus ? 'primary' : 'dark',
            onClick: onSetStatus
        }]}>
            <select
            className={`custom-select ${window.theme}`}
            defaultValue={'Choose a status...'}
            onChange={e => {
                let id = Utils.attributeForKey.select(e, 'id');
                setSelectedStatus(parseInt(id));
            }}
            style={{
                width: '100%'
            }}>
                <option disabled={true}>{'Choose a status...'}</option>
                {items.map((item, index) => {
                    return (
                        <option key={index} id={item.status}>{item.title}</option>
                    )
                })}
            </select>
        </Layer>
    )
}

export const RescheduleDemo = ({ abstract, index, options, utils }) => {

    const layerID = `reschedule_demo_${abstract.getID()}`;
    const [layerState, setLayerState]= useState(null);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(moment(abstract.object.start_date));
    const [endDate, setEndDate] = useState(moment(abstract.object.end_date));

    const onReschedule = async () => {
        if(!startDate || !endDate) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please choose a start date and end date before moving on'
            });
            return;
        }

        try {
            setLoading(true);
            await Utils.sleep(1);

            let { status } = await Request.post(utils, '/demos/', {
                type: 'reschedule',
                id: abstract.getID(),
                start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(endDate).format('YYYY-MM-DD HH:mm:ss')
            });

            setLoading(false);
            abstract.object.status = status;
            abstract.object.start_date = startDate;
            abstract.object.end_date = endDate;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `This Demo has been rescheduled for ${moment(startDate).format('MMMM Do, YYYY [at] h:mma')} to ${moment(endDate).format('MMMM Do, YYYY [at] h:mma')}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this Demo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    return (
        <Layer
        id={layerID}
        title={`Reschedule Demo`}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Save Changes',
            color: startDate && endDate ? 'primary' : 'dark',
            onClick: onReschedule
        }]}>
            <DualDatePickerField
            utils={utils}
            dateTime={true}
            dateFormat={'MMM Do, YYYY [at] h:mma'}
            selectedStartDate={startDate}
            selectedEndDate={endDate}
            onStartDateChange={date => setStartDate(date)}
            onEndDateChange={date => setEndDate(date)}
            style={{
                width: '100%'
            }}/>
        </Layer>
    )
}

export const RescheduleDemoRequest = ({ abstract, index, options, utils }) => {

    const layerID = `reschedule_demo_request_${abstract.getID()}`;
    const [layerState, setLayerState]= useState(null);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(moment(abstract.object.date));

    const onReschedule = async () => {
        if(!date) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please choose a date before moving on'
            });
            return;
        }

        try {
            setLoading(true);
            await Utils.sleep(1);

            let { status } = await Request.post(utils, '/demos/', {
                type: 'reschedule_request',
                id: abstract.getID(),
                date: moment(date).format('YYYY-MM-DD HH:mm:ss')
            });

            setLoading(false);
            abstract.object.status = status;
            abstract.object.cancelled = false;
            abstract.object.rescheduled = true;
            abstract.object.date = date;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `This Demo Request has been rescheduled for ${moment(date).format('MMMM Do, YYYY [at] h:mma')}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this Demo Request. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    return (
        <Layer
        id={layerID}
        title={`Reschedule Demo Request`}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Save Changes',
            color: date ? 'primary' : 'dark',
            onClick: onReschedule
        }]}>
            <DatePickerField
            utils={utils}
            dateTime={true}
            selected={date}
            onDateChange={date => setDate(date)} />
        </Layer>
    )
}

export const BookDemoFromLead = ({ defaultDate }, { abstract, options, utils }) => {

    const layerID = `book_demo_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const [user, setUser] = useState(null);
    const [date, setDate] = useState(defaultDate);

    const onBookDemo = ({ lead_status, start_date }) => {
        utils.alert.show({
            title: 'All Done!',
            message: `The demo for ${abstract.object.full_name} has been set for ${moment(start_date).format('MMMM Do, YYYY [at] h:mma')}`,
            onClick: () => setLayerState('close')
        });

        abstract.object.status = lead_status;
        utils.content.update(abstract);
    }

    return (
        <Layer
        id={layerID}
        title={`Book Demo for ${abstract.object.full_name}`}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}>
            <BookDemo
            utils={utils}
            defaultDate={defaultDate}
            lead={abstract.object}
            onBookDemo={onBookDemo}
            onDateChange={date => setDate(date)}
            onLoad={loading => setLoading(loading)}
            onUserChange={user => setUser(user)} />
        </Layer>
    )
}

export const BookDemoFromRequest = ({ abstract, options, utils }) => {

    const layerID = `book_demo_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const [user, setUser] = useState(null);
    const [date, setDate] = useState(null);

    const onBookDemo = ({ lead_status, start_date }) => {
        utils.alert.show({
            title: 'All Done!',
            message: `The demo for ${abstract.object.lead.full_name} has been set for ${moment(start_date).format('MMMM Do, YYYY [at] h:mma')}`,
            onClick: () => setLayerState('close')
        });

        abstract.object.lead.status = lead_status;
        utils.content.update(abstract);

        utils.content.update(Abstract.create({
            type: 'lead',
            object: abstract.object.lead
        }))
    }

    return (
        <Layer
        id={layerID}
        title={`Book Demo for ${abstract.object.lead.full_name}`}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}>
            <BookDemo
            utils={utils}
            onBookDemo={onBookDemo}
            request={abstract.object}
            lead={abstract.object.lead}
            defaultDate={abstract.object.date}
            onLoad={loading => setLoading(loading)}
            onDateChange={date => setDate(date)}
            onUserChange={user => setUser(user)} />
        </Layer>
    )
}

export const DemoRequestDetails = ({ abstract, options, utils }) => {

    const layerID = `demo_request_details_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [callLogs, setCallLogs] = useState([]);

    const onCancelDemoRequest = () => {
        utils.alert.show({
            title: 'Cancel Demo Request',
            message: `Are you sure that you want to cancel this Demo Request?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onCancelDemoRequestConfirm();
                }
            }
        })
    }

    const onCancelDemoRequestConfirm = async () => {

        try {
            setLoading(true);
            await Utils.sleep(1);

            let { status } = await Request.post(utils, '/demos/', {
                type: 'cancel_demo_request',
                id: abstract.getID()
            });

            setLoading(false);
            abstract.object.status = status;
            abstract.object.cancelled = true;
            abstract.object.rescheduled = false;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: 'This Demo Request has been cancelled'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling this demo request. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCallLogClick = log => {
        utils.layer.open({
            id: `call_log_details_${log.id}`,
            abstract: Abstract.create({
                type: 'call_log',
                object: log
            }),
            Component: CallLogDetails
        })
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'new_call',
                title: 'Add New Call',
                style: 'default'
            },{
                key: 'reschedule_request',
                title: 'Reschedule Demo Request',
                style: 'default'
            },{
                key: 'cancel_request',
                title: 'Cancel Demo Request',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'new_call') {
                utils.layer.open({
                    id: `new_call_log_${abstract.object.lead.id}`,
                    abstract: Abstract.create({
                        type: 'call_log',
                        object: CallLog.new()
                    }),
                    Component: AddEditCallLog.bind(this, {
                        isNewTarget: true,
                        lead: abstract.object.lead
                    })
                })
                return;
            }
            if(key === 'reschedule_request') {
                utils.layer.open({
                    id: `reschedule_demo_request_${abstract.getID()}`,
                    abstract: Abstract.create({
                        type: 'demo_request',
                        object: abstract.object
                    }),
                    Component: RescheduleDemoRequest
                });
                return;
            }
            if(key === 'cancel_request') {
                onCancelDemoRequest();
                return;
            }
        })
    }

    const getCallLogs = () => {
        return (
            <LayerItem title={'Call Logs'}>
                <div style={Appearance.styles.panel()}>
                    {callLogs.length > 0
                        ?
                        callLogs.map((log, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: log.author ? log.author.full_name : 'Name not available',
                                    subTitle: moment(log.date).format('MMMM Do, YYYY [at] h:mma'),
                                    badge: [{
                                        text: log.direction,
                                        color: log.direction === 'outbound' ? Appearance.colors.grey() : Appearance.colors.primary()
                                    },{
                                        text: log.method,
                                        color: Appearance.colors.secondary()
                                    }],
                                    hideIcon: true,
                                    singleItem: callLogs.length === 1,
                                    firstItem: index === 0,
                                    lastItem: index === callLogs.length - 1,
                                    bottomBorder: true,
                                    onClick: onCallLogClick.bind(this, log)
                                })
                            )
                        })
                        :
                        Views.entry({
                            title: 'No calls found',
                            hideIcon: true,
                            bottomBorder: false
                        })}
                </div>
            </LayerItem>
        )
    }

    const getFields = () => {

        let demo = abstract.object;
        let { lead } = abstract.object;

        let items = [{
            key: 'details',
            title: 'Quick Look',
            items: [{
                key: 'name',
                title: 'Name',
                value: `${lead.first_name} ${lead.last_name}`
            },{
                key: 'spouse',
                title: 'Spouse',
                value: lead.spouse_first_name && lead.spouse_last_name ? `${lead.spouse_first_name} ${lead.spouse_last_name}` : null
            },{
                key: 'email',
                title: 'Email Address',
                value: lead.email_address
            },{
                key: 'phone',
                title: 'Phone Number',
                value: lead.phone_number
            },{
                key: 'status',
                title: 'Lead Status',
                value: demo.lead && demo.lead.status ? demo.lead.status.text : null
            }]
        },{
            key: 'location',
            title: 'Location',
            visible: demo.lead.address && demo.lead.location ? true : false,
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                value: demo.lead.location
            },{
                key: 'address',
                title: 'Address',
                value: Utils.formatAddress(demo.lead.address)
            },{
                key: 'maps',
                title: 'Directions',
                button: {
                    text: 'Click to View',
                    color: 'primary',
                    onClick: () => {
                        let address = Utils.formatAddress(demo.lead.address);
                        window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`)
                    }
                }
            }]
        },{
            key: 'request',
            title: 'Request',
            items: [{
                key: 'id',
                title: 'ID',
                value: demo.id
            },{
                key: 'requested_by_user',
                title: 'Requested By',
                value: demo.requested_by_user ? demo.requested_by_user.full_name : 'Not Available'
            },{
                key: 'created',
                title: 'Created',
                value: moment(demo.created).format('MMMM Do, YYYY [at] h:mma')
            },{
                key: 'date',
                title: 'Scheduled For',
                value: moment(demo.date).format('MMMM Do, YYYY [at] h:mma')
            },{
                key: 'status',
                title: 'Status',
                value: demo.status ? demo.status.text : null
            }]
        }]

        return items;
    }

    const fetchDetails = async () => {
        try {
            let { call_logs } = await Request.get(utils, '/demos/', {
                type: 'request_details',
                id: abstract.getID()
            })
            setCallLogs(call_logs.map(log => {
                return CallLog.create(log);
            }));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the additional information for this demo request. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchDetails();
        utils.content.subscribe(layerID, [ 'call_log' ], {
            onFetch: type => {
                switch(type) {
                    case 'call_logs':
                    fetchDetails();
                    break;
                }
            }
        })

        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`${abstract.getTitle()} Details`}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        }]}>
            <FieldMapper fields={getFields()} />
            {getCallLogs()}
        </Layer>
    )
}

export const CallLogDetails = ({ abstract, options, utils }) => {

    const layerID = `call_log_details_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const getFields = () => {

        let callLog = abstract.object;
        let items = [{
            key: 'date',
            title: 'Date and Time',
            items: [{
                key: 'start_date',
                title: 'Call Date and Time',
                value: callLog.start_date ? moment(callLog.start_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'duration',
                title: 'Duration',
                value: callLog.start_date && callLog.end_date ? Utils.parseDuration(moment(callLog.end_date).unix() - moment(callLog.start_date).unix()) : null,
                visible: callLog.end_date ? true : false
            }]
        },{
            key: 'date',
            title: 'Follow Up Date and Time',
            items: [{
                key: 'follow_up_start_date',
                title: 'Call Date and Time',
                value: callLog.follow_up_start_date ? moment(callLog.follow_up_start_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'duration',
                title: 'Duration',
                value: callLog.follow_up_start_date && callLog.follow_up_end_date ? Utils.parseDuration(moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix()) : null,
                visible: callLog.follow_up_end_date ? true : false
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'direction',
                title: 'Direction',
                value: callLog.direction ? Utils.ucFirst(callLog.direction) : null
            },{
                key: 'method',
                title: 'Method',
                value: callLog.method ? Utils.ucFirst(callLog.method) : null
            }]
        },{
            key: 'additional',
            title: 'Additional Information',
            items: [{
                key: 'assign_to',
                title: 'Assignment',
                value: callLog.assign_to ? callLog.assign_to.full_name : null
            },{
                key: 'notes',
                title: 'Notes',
                value: callLog.notes
            }]
        }]

        return items;
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_call_log_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditCallLog.bind(this, {
                isNewTarget: false,
                lead: abstract.object.lead
            })
        })
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'delete') {
                onDeleteCallLog();
            }
        })
    }

    const onDeleteCallLog = () => {
        utils.alert.show({
            title: 'Delete Call',
            message: 'Are you sure that you want to delete this call? This action can not be undone',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCallLogConfirm();
                }
            }
        })
    }

    const onDeleteCallLogConfirm = async () => {
        try {

            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/demos/', {
                type: 'delete_call_log',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('call_log');

            utils.alert.show({
                title: 'All Done!',
                message: 'This call log has been deleted',
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this call log. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    return (
        <Layer
        id={layerID}
        title={`Details for ${abstract.getTitle()}`}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>
            <FieldMapper fields={getFields()} />
        </Layer>
    )
}

export const AddEditDemo = ({ abstract, index, options, utils }) => {

    const layerID = `edit_demo_${abstract.getID()}`;
    const [demo, setDemo] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDoneClick = async () => {
        try {
            setLoading('done');
            await validateRequiredFields(getFields);
            if(demo.start_date > demo.end_date) {
                throw new Error('It looks like your end date is before your start date. Please check your dates before moving on');
                return;
            }

            await Utils.sleep(1);
            await abstract.object.update(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The Demo for ${abstract.object.lead.full_name} has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this Demo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props)
            setDemo(edits);
        } catch(e) {
            console.log(e.message);
        }
    }

    const getFields = () => {

        if(!demo) {
            return [];
        }

        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'start_date',
                title: 'Start Date',
                description: 'The start date is used to show when a Demo is scheduled to start.',
                component: 'date_duration_picker',
                value: demo.start_date,
                onChange: date => onUpdateTarget({ start_date: date })
            },{
                key: 'end_date',
                title: 'End Date',
                description: 'The start date is used to show when a Demo is scheduled to end.',
                component: 'date_duration_picker',
                value: demo.end_date,
                onChange: date => onUpdateTarget({ end_date: date })
            }]
        }]
        return items;
    }

    const setupTarget = async () => {
        try {
            let edits = await abstract.object.open();
            setDemo(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this Demo. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Editing ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Save Changes',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditCallLog = ({ isNewTarget, lead }, { abstract, options, utils }) => {

    const layerID = isNewTarget ? `new_call_log_${lead.id}` : `edit_call_log_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [callLog, setCallLog] = useState(null);

    const getFields = () => {

        if(!callLog) {
            return null;
        }

        let items = [{
            key: 'date',
            title: 'Date and Time',
            items: [{
                key: 'start_date',
                title: 'Call Date and Time',
                description: 'When did this call occur?',
                component: 'date_duration_picker',
                date: callLog.start_date,
                value: callLog.start_date ? moment(callLog.start_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'end_date',
                title: 'Duration',
                component: 'duration_picker',
                visible: callLog.start_date ? true : false,
                target: callLog.start_date,
                selected: callLog.end_date,
                value: callLog.start_date && callLog.end_date ? Utils.parseDuration(moment(callLog.end_date).unix() - moment(callLog.start_date).unix()) : null,
                seconds: callLog.end_date ? moment(callLog.end_date).unix() - moment(callLog.start_date).unix() : null
            }]
        },{
            key: 'follow_up_date',
            title: 'Follow Up Date and Time',
            items: [{
                key: 'follow_up_start_date',
                required: false,
                title: 'Call Date and Time',
                description: 'When did this call occur?',
                component: 'date_duration_picker',
                date: callLog.follow_up_start_date,
                value: callLog.follow_up_start_date ? moment(callLog.follow_up_start_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'follow_up_end_date',
                required: false,
                title: 'Duration',
                component: 'duration_picker',
                visible: callLog.follow_up_start_date ? true : false,
                target: callLog.follow_up_start_date,
                selected: callLog.follow_up_end_date,
                value: callLog.follow_up_start_date && callLog.follow_up_end_date ? Utils.parseDuration(moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix()) : null,
                seconds: callLog.follow_up_end_date ? moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix() : null
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'direction',
                title: 'Direction',
                description: 'Did you make initial the outbound conversation or did the lead initiate the inbound conversation?',
                component: 'picker',
                items: [{
                    key: 'inbound',
                    title: 'Inbound'
                },{
                    key: 'outbound',
                    title: 'Outbound'
                }],
                selected: callLog.direction,
                value: callLog.direction ? Utils.ucFirst(callLog.direction) : null
            },{
                key: 'method',
                title: 'Method',
                description: 'How did you contact this lead?',
                component: 'picker',
                items: [{
                    key: 'email',
                    title: 'Email'
                },{
                    key: 'phone',
                    title: 'Phone'
                }],
                selected: callLog.method,
                value: callLog.method ? Utils.ucFirst(callLog.method) : null
            }]
        },{
            key: 'additional',
            title: 'Additional Information',
            items: [{
                key: 'assign_to',
                title: 'Assign To',
                required: false,
                description: 'Would you like to assign this call to someone in your Dealership?',
                component: 'user_lookup',
                props: {
                    levels: [
                        User.level.region_director,
                        User.level.division_director,
                        User.level.area_director,
                        User.level.dealer,
                        User.level.safety_advisor,
                        User.level.safety_associate
                    ]
                },
                value: callLog.assign_to ? callLog.assign_to.full_name : null
            },{
                key: 'notes',
                title: 'Notes',
                required: false,
                description: 'You can add any additional notes for this call below',
                component: 'textview',
                value: callLog.notes
            }]
        }]

        return items;
    }

    const onValueChange = async (key, value) => {
        try {
            let edits = await abstract.object.set({ [key]: value })
            setCallLog(edits);
        } catch(e) {
            console.log(e.message);
        }
    }

    const onEditClick = item => {

        let props = {
            title: item.title,
            description: item.description
        };

        let tmpValue = item.value;
        switch(item.component) {
            case 'textview':
            props.children = (
                <TextView
                value={tmpValue}
                onChange={text => tmpValue = text}
                containerStyle={{
                    width: '100%'
                }}/>
            )
            break;

            case 'date_picker':
            tmpValue = item.date || moment();
            utils.datePicker.show({
                date: tmpValue,
                dateTime: true,
                onDateChange: date => tmpValue = date,
                onClose: () => {
                    onValueChange(item.key, tmpValue);
                }
            })
            return;

            case 'duration_picker':
            tmpValue = item.selected;
            props.children = (
                <DurationPickerField
                seconds={item.seconds}
                onChange={item.onChange}
                style={{
                    width: '100%'
                }}
                onChange={seconds => {
                    tmpValue = moment(item.target).add(seconds, 'seconds');
                }}/>
            )
            break;

            case 'date_duration_picker':
            tmpValue = item.date || moment();
            props.children = (
                <DateDurationPickerField
                utils={utils}
                selected={tmpValue}
                placeholder={'Choose a date...'}
                onChange={date => tmpValue = date}
                style={{
                    width: '100%'
                }}/>
            )
            break;

            case 'picker':
            tmpValue = item.selected;
            props.children = (
                <PickerField
                utils={utils}
                items={item.items}
                collapsible={false}
                value={item.selected ? item.items.find(entry => {
                    return entry.key === item.selected;
                }) : null}
                onChange={item => tmpValue = item.key}/>
            )
            break;

            case 'user_lookup':
            tmpValue = item.selected;
            props.children = (
                <UserLookupField
                {...item.props}
                utils={utils}
                value={item.selected}
                onChange={user => tmpValue = user}/>
            )
            break;
        }

        utils.layer.open({
            id: 'edit_target',
            Component: EditTarget.bind(this, {
                ...props,
                onCommit: () => {
                    onValueChange(item.key, tmpValue);
                }
            })
        })
    }

    const onDoneClick = async () => {

        // Valdiate fields
        let items = getFields().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined;
        });
        if(required) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please fill out the "${required.title}" before moving on`
            });
            return;
        }

        // Submit call log
        if(isNewTarget) {
            try {
                setLoading(true);
                await abstract.object.submit(utils);

                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `Your new call has been saved for this lead`,
                    onClick: () => setLayerState('close')
                });

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue creating this call. ${e.message || 'An unknown error occurred'}`
                })
            }
            return;
        }

        // Update call log
        try {
            setLoading(true);
            await abstract.object.update(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your call for this lead has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this call. ${e.message || 'An unknown error occurred'}`
            })
        }
        return;
    }

    const setupTarget = async () => {
        try {
            let edits = await abstract.object.open();
            setCallLog(edits);

            if(isNewTarget) {
                abstract.object.lead = lead;
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this call log. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New Call Log` : `Editing ${abstract.getTitle()}`}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Create Call Log' : 'Save Changes',
            color: 'primary',
            onClick: onDoneClick
        }]}>
            <FieldMapper
            editable={true}
            fields={getFields()}
            onEditClick={onEditClick} />
        </Layer>
    )
}

// Components
export const getDemoStatus = demo => {
    if(!demo || !demo.status) {
        return null;
    }

    let match = Demo.styles.status.find(entry => entry.status === demo.status.code);
    if(!match) {
        return null;
    }
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            maxWidth: 95,
            textAlign: 'center',
            border: `1px solid ${match.color}`,
            background: Appearance.colors.softGradient(match.color),
            borderRadius: 5,
            overflow: 'hidden',
            paddingLeft: 5,
            paddingRight: 5
        }}>
            <span style={{
                ...Appearance.textStyles.subTitle(),
                display: 'block',
                color: 'white',
                fontWeight: '600',
                width: '100%'
            }}>{match.title}</span>
        </div>
    )
}
