import React, { useRef, useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';
import { VelocityComponent } from 'velocity-react';

const BoolToggle = ({ enabled, disabled, isEnabled, onChange, style, width }) => {

    const ref = useRef(null);
    const _width = width || 86;
    const [left, setLeft] = useState(0);
    const [status, setStatus] = useState(isEnabled);

    const onToggleClick = () => {
        setLeft(status ? 0 : (ref.current.clientWidth / 2));
        setStatus(!status);
        if(onChange && typeof(onChange) === 'function') {
            onChange(!status);
        }
    }

    useEffect(() => {
        if(ref && ref.current) {
            setLeft(status ? (ref.current.clientWidth / 2) : 0);
        }
    }, [ref])

    return (
        <div
        ref={ref}
        style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            width: _width * 2,
            height: 30,
            borderRadius: 15,
            border: `1px solid ${Appearance.colors.softBorder()}`,
            boxShadow: Appearance.boxShadow(),
            overflow: 'hidden',
            ...style
        }}>
            <VelocityComponent
            easing={[250, 20]}
            duration={500}
            animation={{
                left: left
            }}>
                <div style={{
                    position: 'absolute',
                    width: '50%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 15,
                    backgroundColor: Appearance.colors.primary()
                }}>
                    <span style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: 'white'
                    }}>{status ? enabled:disabled}</span>
                </div>
            </VelocityComponent>

            <div
            className={'cursor-pointer'}
            onClick={onToggleClick}
            style={{
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 12,
                paddingRight: 12
            }}>
                <span style={{
                    fontSize: 12,
                    fontWeight: 600,
                    color: status ? Appearance.colors.text() : 'white'
                }}>{disabled}</span>
            </div>
            <div
            className={'cursor-pointer'}
            onClick={onToggleClick}
            style={{
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 12,
                paddingRight: 12
            }}>
                <span style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: status ? 'white' : Appearance.colors.text()
                }}>{enabled}</span>
            </div>
        </div>
    )
}
export default BoolToggle;
