import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Draggable from 'react-draggable';
import { Map } from 'views/MapElements.js';
import ProgressBar from 'views/ProgressBar.js';
import ResizeObserver from 'react-resize-observer';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';
import Views, { AltBadge } from 'views/Main.js';

export const CalloutIndex = 4950;
export const FloatingMenuIndex = 4550;
export const EndIndex = 4990;
export const FrontIndex = EndIndex + 10;

export const LayerItem = ({ children, style, title }) => {
    return (
        <div style={{
            marginBottom: 20,
            ...style
        }}>
            <span style={{
                ...Appearance.textStyles.subHeader(),
                display: 'block',
                marginBottom: 8
            }}>{title}</span>
            {children}
        </div>
    )
}
const Layer = ({ buttons, children, header, index, id, title, options = {}, position, utils }) => {

    const ref = useRef(null);
    const headerRef = useRef(null);
    const buttonContainer = useRef(null);
    const toolbarContainer = useRef(null);

    const [onHover, setOnHover] = useState(null);
    const [zIndex, setZIndex] = useState(options.zIndex);
    const [layerPosition, setLayerPosition] = useState(position);
    const [dragging, setDragging] = useState(false);
    const [height, setHeight] = useState(0);

    const [top, setTop] = useState(window.innerHeight); // for mobile
    const [scale, setScale] = useState(0.75); // for tablet and desktop
    const [opacity, setOpacity] = useState(0); // for tablet and desktop

    const [windowState, setWindowState] = useState({
        action: options.sizing === 'fullscreen' ? 'fullscreen' : null,
        frame: null
    });
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const updateWindowDimensions = () => setSize({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => setLayerPosition(position ? {
        y: position.y,
        x: Utils.isMobile() ? 12 : position.x
    } : null), [position]);
    useEffect(() => setZIndex(options.zIndex), [options]);

    useEffect(() => {
        switch(options.layerState) {
            case 'close':
            if(opacity !== 0 && scale !== 0.75) {

                setOpacity(0);
                setScale(0.75);
                setTop(window.innerHeight);

                if(typeof(options.onClose) === 'function') {
                    setTimeout(options.onClose.bind(this, id), 500);
                }
            }
            break;
        }

    }, [options.layerState])

    useEffect(() => {

        if(!ref || !ref.current) {
            return
        }
        setTimeout(() => {

            setTop(0);
            setScale(1);
            setOpacity(1);
            setHeight(ref.current.clientHeight);

        }, (250 * ((/*animationDelay ||*/ 0) + 1)))

        window.addEventListener('onLayerAction', onLayerAction);
        window.addEventListener('resize', updateWindowDimensions);
        return () => {
            window.removeEventListener('onLayerAction', onLayerAction);
            window.removeEventListener('resize', updateWindowDimensions);
        }
    }, [ref]);

    const onLayerAction = ({ detail }) => {
        if(detail.layerID === id) {
            windowActions(detail.action);
        }
    }

    const onLayerPress = () => {
        setZIndex(FrontIndex);
        options.onSetLayerIndex(id);
    }

    const windowActions = action => {

        if(action === 'close') {
            setOpacity(0);
            setScale(0.75);
            setTop(window.innerHeight);

            if(typeof(options.onClose) === 'function') {
                setTimeout(options.onClose.bind(this, id), 500);
            }
            return;
        }

        setWindowState(windowState => update(windowState, {
            action: {
                $set: action === windowState.action ? null : action
            }
        }));
    }

    const dragStarted = (e) => {
        onLayerPress();
    }
    const dragMoved = (e) => {
        if(!dragging) {
            setDragging(true);
        }
    }
    const dragEnded = (e, node) => {

        // mobile
        if(Utils.isMobile()) {

            // default to page height incase mobile device does not return a Y value
            let y = ref.current ? ref.current.getBoundingClientRect().y : window.innerHeight;
            setTop(y - 36);
            setTimeout(() => {
                setDragging(false);
                if(Utils.isMobile() && y > window.innerHeight / 3) {
                    windowActions('close');
                } else {
                    setTop(0);
                }
            }, 50);
            return;
        }

        // tablet and desktop
        setDragging(false);
        setLayerPosition({
            y: node.y,
            x: Utils.isMobile() ? 12 : node.x
        });
        if(options && options.onReposition && typeof(options.onReposition) === 'function') {
            options.onReposition({
                id: id,
                position: {
                    x: node.x,
                    y: node.y
                }
            });
        }
    }

    const widthForSizing = () => {
        if(!options) {
            return 700;
        }
        switch(options.sizing) {
            case 'small':
            return 400;

            case 'medium':
            return 550;

            case 'extra_large':
            return 1000;

            case 'fullscreen':
            return size.width - 30;

            default:
            return 700
        }
    }

    const updateHeight = rect => {
        if(windowState.action === 'fullscreen' || windowState.action === 'minimize') {
            return;
        }
        setHeight(rect.height);
    }

    const headerContent = () => {

        return Utils.isMobile() ? (
            <div style={{
                position: 'relative'
            }}>
                <div
                className={dragging ? 'cursor-grabbing':'hover-grab'}
                style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    left: 0,
                    right: 0,
                    top: 0,
                    width: '100%'
                }}>
                    <div style={{
                        width: 30,
                        height: 30,
                        marginRight: 12
                    }} />
                    <div style={{
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        backgroundColor: window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white',
                        zIndex: 1,
                        boxShadow: '0px 5px 10px rgba(174,174,174,0.25)'
                    }}>
                        <div style={{
                            width: 45,
                            height: 4,
                            borderRadius: 2,
                            backgroundColor: Appearance.colors.grey(),
                            marginBottom: 2
                        }} />
                    </div>
                    <div
                    className={'not-draggable'}
                    style={{
                        zIndex: 1,
                        paddingTop: 5,
                        paddingRight: 5
                    }}>
                        <img
                        className={'text-button'}
                        onClick={windowActions.bind(this, 'close')}
                        src={'images/close-button-light-small.png'}
                        style={{
                            width: 25,
                            height: 25,
                            objectFit: 'contain',
                            marginLeft: 12
                        }} />
                    </div>
                </div>
                {options && options.loading
                    ?
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 2
                    }}>
                        <div style={{
                            overflow: 'hidden',
                            borderRadius: 2,
                            height: 2
                        }}>
                            <ProgressBar/>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        ) : (
            <div className={dragging ? 'cursor-grabbing':'cursor-grab'} style={{
                position: 'relative'
            }}>
                <div style={Appearance.styles.header()}>
                    {header}
                    <div
                    ref={headerRef}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <div
                        className={'not-draggable'}
                        style={{
                            width: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center'
                        }}>
                            <AltBadge
                            onClick={windowActions.bind(this, 'close')}
                            content={{
                                text: 'Close',
                                color: Appearance.colors.red
                            }}
                            style={{
                                width: 60
                            }}/>
                        </div>

                        <span style={{
                            flexGrow: 1,
                            color: Appearance.colors.text(),
                            fontSize: 14,
                            fontWeight: '700',
                            textAlign: 'center',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{title}</span>

                        <div
                        ref={toolbarContainer}
                        style={{
                            width: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'center'
                        }} />
                    </div>
                </div>
                {options && options.loading
                    ?
                    <div style={{
                        position: 'absolute',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        height: '2px'
                    }}>
                        <div style={{
                            overflow: 'hidden',
                            borderRadius: '2px',
                            height: '2px'
                        }}>
                            <ProgressBar/>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }

    const filterOptions = () => {
        if(!options.filter) {
            return null;
        }
        return (
            <div
            className={'not-draggable'}
            style={{
                padding: 15,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <TextField
                icon={'search'}
                placeholder={options.filter.placeholder || 'Search for something...'}
                onChange={options.filter.onChange} />
            </div>
        )
    }

    const getLayerWidth = () => {
        let width = widthForSizing();
        return size.width > width ? width : size.width - 60;
    };

    const getLayerHeight = () => {
        if(Utils.isMobile()) {
            return '100%';
        }
        if(windowState.action === 'fullscreen') {
            if(buttonContainer.current) {
                let spacing = 48;
                return size.height - toolbarContainer.current.clientHeight - buttonContainer.current.clientHeight - spacing;
            }
            return size.height - 45;
        }
        return size.height - 190 - (700 - widthForSizing());
    }

    useEffect(() => {
        if(windowState.action === 'close') {
            return;
        }
        if(typeof(options.onActionChange) === 'function') {
            options.onActionChange({
                action: windowState.action,
                height: getLayerHeight() - headerRef.current.clientHeight
            })
        }
    }, [windowState])

    return Utils.isMobile() ? (

        <Draggable
        key={id}
        axis={'y'}
        cancel={'.not-draggable'}
        onStart={dragStarted}
        onDrag={dragMoved}
        onStop={dragEnded}
        onTouchEnd={dragEnded}
        bounds={{
            top: 12
        }}
        defaultPosition={{
            x: 12,
            y: 12
        }}
        position={{
            x: 12,
            y: 12
        }}>
            <div
            id={id}
            style={{
                position: 'fixed',
                zIndex: zIndex
            }}>
                <VelocityComponent
                easing={[250, 20]}
                duration={dragging ? 0 : 750}
                animation={{
                    top: top
                }}>
                    <div
                    onClick={onLayerPress}
                    style={{
                        position: 'absolute',
                        width: window.innerWidth - 24,
                        height: window.innerHeight - 24,
                        zIndex: zIndex,
                        overflow: 'hidden',
                        boxShadow: '23px 23px 46px rgba(0,0,0,0.1)',
                        border: '3px solid ' + (window.theme === 'dark' ? 'rgba(25,25,25,1)':'white'),
                        borderRadius: 10
                    }}>
                        <div
                        ref={ref}
                        style={{
                            backgroundColor: Appearance.colors.layerBackground(),
                            padding: 0,
                            margin: 0,
                            height: '100%'
                        }}>
                            {headerContent()}
                            {filterOptions()}
                            <div
                            className={'not-draggable'}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                position: 'relative',
                                overflow: 'hidden'
                            }}>
                                {options.sticky ? options.sticky.top : null}
                                <div style={{
                                    flexGrow: 1,
                                    overflowY: 'scroll',
                                    padding: options.removePadding ? 0 : 12,
                                    maxHeight: '100%',
                                    paddingTop: Utils.isMobile() && options.removeMobilePadding !== true ? 40:0
                                }}>
                                    {children}
                                </div>

                                {options.sticky ? options.sticky.bottom : null}
                                {buttons
                                    ?
                                    <div style={{
                                        padding: 12,
                                        borderTop: `1px solid ${Appearance.colors.divider()}`
                                    }}>
                                        <div className={'row p-0 m-0'}>
                                            {buttons.filter(b => b.visible !== false).map((button, index, buttons) => {
                                                return (
                                                    <div
                                                    key={index}
                                                    className={'col-' + parseInt(12 / buttons.length) + ' py-0 ' + (buttons.length === 1 ? 'px-0':(index === 0 ? 'pl-0 pr-1':(index === buttons.length - 1 ? 'pr-1 pl-0' : 'px-1')))}>
                                                        <Button
                                                        type={'large'}
                                                        label={button.text}
                                                        color={button.color}
                                                        loading={button.loading}
                                                        onClick={button.onClick}/>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                            </div>
                        </div>
                    </div>
                </VelocityComponent>
            </div>
        </Draggable>

    ) : (

        <Draggable
        key={id}
        onStart={dragStarted}
        onDrag={dragMoved}
        onStop={dragEnded}
        cancel={'.not-draggable'}
        defaultPosition={layerPosition || {
            x: (size.width / 2) - (getLayerWidth() / 2),
            y: height ? ((size.height / 2) - (height / 2)) : 50
        }}
        position={windowState.action === 'fullscreen' ? {
            x: 15,
            y: 15
        } : (position || layerPosition || {
            x: (size.width / 2) - (getLayerWidth() / 2),
            y: height ? ((size.height / 2) - (height / 2)) : 50
        })}>
            <div
            id={id}
            style={{
                position: 'fixed',
                zIndex: zIndex
            }}>
                <VelocityComponent
                easing={[250, 20]}
                duration={500}
                animation={{
                    scale: scale,
                    opacity: opacity
                }}>
                    <div
                    onClick={onLayerPress}
                    style={{
                        position: 'fixed',
                        width: windowState.action === 'fullscreen' ? (size.width - 30) : getLayerWidth(),
                        height: windowState.action === 'fullscreen' ? '100vh' : 'auto',
                        maxHeight: windowState.action === 'fullscreen' ? (size.height - 30) : null,
                        zIndex: zIndex,
                        overflow: 'hidden',
                        boxShadow: '23px 23px 46px rgba(0,0,0,0.1)',
                        border: `3px solid ${window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white'}`,
                        borderRadius: 10,
                        backgroundColor: Appearance.colors.layerBackground(),
                    }}>
                        <ResizeObserver onResize={updateHeight}/>
                        <div
                        ref={ref}
                        style={{
                            flexGrow: 1,
                            padding: 0,
                            margin: 0
                        }}>
                            <div>
                                {headerContent()}
                                {filterOptions()}
                                {windowState.action != 'minimize'
                                    ?
                                    <div
                                    className={'not-draggable'}
                                    style={{
                                        position: 'relative'
                                    }}>
                                        {options && options.loading && (
                                            <div style={{
                                                position: 'absolute',
                                                top: '0px',
                                                left: '0px',
                                                right: '0px',
                                                height: '2px'
                                            }}>
                                                <div style={{
                                                    overflow: 'hidden',
                                                    borderRadius: '2px',
                                                    height: '2px'
                                                }}>
                                                    <ProgressBar/>
                                                </div>
                                            </div>
                                        )}
                                        {options.sticky ? options.sticky.top : null}
                                        <div style={{
                                            height: '100%',
                                            overflowY: 'scroll',
                                            padding: options.removePadding ? 0 : 12,
                                            maxHeight: getLayerHeight(),
                                        }}>
                                            {children}
                                        </div>
                                        {options.sticky ? options.sticky.bottom : null}
                                        {buttons && (
                                            <div
                                            ref={buttonContainer}
                                            style={{
                                                padding: 12,
                                                borderTop: `1px solid ${Appearance.colors.divider()}`
                                            }}>
                                                <div style={{
                                                    maxWidth: 700,
                                                    margin: 'auto'
                                                }}>
                                                    <div className={'row p-0 m-0'}>
                                                        {buttons.filter(b => b.visible !== false).map((button, index, buttons) => {
                                                            return (
                                                                <div
                                                                key={index}
                                                                className={'col-12 col-md-' + parseInt(12 / buttons.length) + ' py-0 ' + (index !== buttons.length - 1 ? 'pb-2':'') + ' pb-md-0 ' + (buttons.length === 1 ? 'px-0':(index === 0 ? 'pl-0 pr-1':(index === buttons.length - 1 ? 'pr-1 pl-0' : 'px-1')))}>
                                                                    <Button
                                                                    type={'large'}
                                                                    label={button.text}
                                                                    color={button.color}
                                                                    loading={button.loading}
                                                                    onClick={button.onClick}/>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </VelocityComponent>
            </div>
        </Draggable>
    )
}

export default Layer;
