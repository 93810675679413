import React, { useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import { LayerItem } from 'structure/Layer.js';
import { Map } from 'views/MapElements.js';

const FieldMapper = ({ editable, fields, filter, group, onClick, onEditClick, utils }) => {

    const [sections, setSections] = useState(fields);

    const onLockClick = () => {
        utils.alert.show({
            title: 'Restricted Information',
            message: 'Your Dealership has set this information as hidden for your account. Please speak with your Dealer if you have questions about this piece of information.'
        })
    }

    const onIncompleteAddressClick = () => {
        utils.alert.show({
            title: 'About this Map',
            message: 'The location provided is incomplete or invalid. Please provide a street address, city, state, and zipcode to take advantage of location based services'
        })
    }

    const getValue = item => {
        if(group && utils.groups.check(group, item.key) === false) {
            return (
                <img
                className={'text-button'}
                onClick={onLockClick}
                src={'images/lock-icon-small-red.png'}
                style={{
                    width: 20,
                    height: 20,
                    objectFit: 'contain'
                }} />
            )
        }
        return item.value || 'Not Added';
    }

    const getChild = (key, child) => {
        if(group && utils.groups.check(group, key) === false) {
            return (
                <img
                key={key}
                className={'text-button'}
                onClick={onLockClick}
                src={'images/lock-icon-small-red.png'}
                style={{
                    width: 20,
                    height: 20,
                    objectFit: 'contain'
                }} />
            )
        }
        return child;
    }

    const getView = (key, style, child) => {
        if(group && utils.groups.check(group, key) === false) {
            return (
                <div
                key={key}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...style
                }}>
                    <img
                    className={'text-button'}
                    onClick={onLockClick}
                    src={'images/lock-icon-small-red.png'}
                    style={{
                        width: 50,
                        height: 50,
                        objectFit: 'contain'
                    }} />
                </div>
            )
        }
        return child;
    }

    useEffect(() => {
        if(!filter) {
            return;
        }
        if(typeof(filter) === 'function') {
            setSections(fields.map(section => {
                return section.items.filter(filter);
            }))
            return;
        }
        setSections(fields.map(section => {
            section.items = section.items.filter(item => {
                if(section.title.toLowerCase().includes(filter.toLowerCase())) {
                    return true;
                }
                if(item.title) {
                    return item.title.toLowerCase().includes(filter.toLowerCase());
                }
                return false;
            });
            return section;
        }))

    }, [filter]);

    useEffect(() => {
        setSections(fields);
    }, [fields]);

    return sections && sections.length > 0 ? (
        sections.filter(section => {
            if(section.visible === false || section.items.length === 0) {
                return false;
            }
            return true;
        }).map((section, index, sections) => {
            return (
                <LayerItem
                key={index}
                title={section.title}
                style={section.style}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        borderRadius: 15
                    }}>
                        {section.items.filter(item => {
                            return item.visible !== false;
                        }).map((item, index, items) => {
                            switch(item.component) {
                                case 'image':
                                return (
                                    <div
                                    key={index}
                                    className={`view-entry ${window.theme}`}
                                    onClick={() => window.open(item.url)}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        padding: '8px 12px 8px 12px',
                                        borderTopLeftRadius: index === 0 ? 6 : 0,
                                        borderTopRightRadius: index === 0 ? 6 : 0,
                                        borderBottomLeftRadius: index === items.length - 1 ? 6 : 0,
                                        borderBottomRightRadius: index === items.length - 1 ? 6 : 0,
                                        borderBottom: item.title && index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                    }}>
                                        <img
                                        src={item.url}
                                        style={{
                                            width: 30,
                                            height: 30,
                                            objectFit: 'cover',
                                            borderRadius: 5,
                                            overflow: 'hidden',
                                            marginRight: 8
                                        }} />
                                        <div style={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <span style={{
                                                ...Appearance.textStyles.key(),
                                                ...(item.style ? item.style.key : null)
                                            }}>{item.title}</span>
                                            <span style={{
                                                ...Appearance.textStyles.value(),
                                                textAlign: 'left',
                                                ...(item.style ? item.style.value : null)
                                            }}>{item.value}</span>
                                        </div>
                                        <img
                                        src={'images/next-arrow-grey-small.png'}
                                        style={{
                                            width: 12,
                                            height: 12,
                                            objectFit: 'contain',
                                            marginLeft: 8,
                                            opacity: 0.75
                                        }} />
                                    </div>
                                )

                                case 'map':
                                if(!item.value) {
                                    return null;
                                }
                                if(item.isValid === false) {
                                    return getView(item.key, {
                                        width: '100%',
                                        height: 250,
                                        backgroundColor: Appearance.colors.divider(),
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8
                                    }, (
                                        <div
                                        key={index}
                                        className={'text-button'}
                                        onClick={onIncompleteAddressClick}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '100%',
                                            position: 'relative'
                                        }}>
                                            <img
                                            src={'images/map-placeholder.jpg'}
                                            style={{
                                                width: '100%',
                                                height: 250
                                            }}/>
                                            <img
                                            src={'images/red-x-icon.png'}
                                            style={{
                                                position: 'absolute',
                                                width: 40,
                                                height: 40
                                            }} />
                                        </div>
                                    ));
                                }
                                return getView(item.key, {
                                    width: '100%',
                                    height: 250,
                                    backgroundColor: Appearance.colors.divider(),
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8
                                }, (
                                    <Map
                                    key={index}
                                    useShadows={false}
                                    center={item.value}
                                    annotations={[{
                                        key: 'location',
                                        location: item.value
                                    }]}
                                    style={{
                                        width: '100%',
                                        height: 250
                                    }}/>
                                ));
                            }

                            let canEdit = editable || item.onClick;
                            let onClick = onEditClick || item.onClick;
                            let bottomBorder = index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null;
                            if(item.bottomBorder === false) {
                                bottomBorder = null;
                            } else if(bottomBorder === true) {
                                bottomBorder = `1px solid ${Appearance.colors.divider()}`;
                            }

                            if(item.button) {
                                return (
                                    <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        padding: '8px 12px 8px 12px',
                                        borderTopLeftRadius: index === 0 ? 6 : 0,
                                        borderTopRightRadius: index === 0 ? 6 : 0,
                                        borderBottomLeftRadius: index === items.length - 1 ? 6 : 0,
                                        borderBottomRightRadius: index === items.length - 1 ? 6 : 0,
                                        borderBottom: bottomBorder,
                                    }}>
                                        {canEdit && item.required !== false && item.value === null && (
                                            <div style={{
                                                width: 8,
                                                height: 8,
                                                minWidth: 8,
                                                minHeight: 8,
                                                borderRadius: 4,
                                                overflow: 'hidden',
                                                backgroundColor: Appearance.colors.red,
                                                marginRight: 8
                                            }} />
                                        )}
                                        <span style={{
                                            ...Appearance.textStyles.key(),
                                            width: '50%'
                                        }}>{item.title}</span>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '50%',
                                            justifyContent: 'flex-end'
                                        }}>
                                            {getChild(item.key, (
                                                <Button
                                                label={item.button.text}
                                                color={item.button.color}
                                                onClick={item.button.onClick} />
                                            ))}
                                        </div>
                                    </div>
                                )
                            }

                            return (
                                <div
                                key={index}
                                className={canEdit ? `view-entry ${window.theme}` : null}
                                onClick={onClick ? onClick.bind(this, item) : null}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: '8px 12px 8px 12px',
                                    borderTopLeftRadius: index === 0 ? 6 : 0,
                                    borderTopRightRadius: index === 0 ? 6 : 0,
                                    borderBottomLeftRadius: index === items.length - 1 ? 6 : 0,
                                    borderBottomRightRadius: index === items.length - 1 ? 6 : 0,
                                    borderBottom: bottomBorder,
                                }}>
                                    {canEdit && item.required !== false && !item.value && (
                                        <div style={{
                                            width: 8,
                                            height: 8,
                                            minWidth: 8,
                                            minHeight: 8,
                                            borderRadius: 4,
                                            overflow: 'hidden',
                                            backgroundColor: Appearance.colors.red,
                                            marginRight: 8
                                        }} />
                                    )}
                                    <span style={{
                                        ...Appearance.textStyles.key(),
                                        flexShrink: 1,
                                        ...(item.style ? item.style.key : null)
                                    }}>{item.title}</span>
                                    <span style={{
                                        ...Appearance.textStyles.value(),
                                        flexGrow: 1,
                                        ...(item.style ? item.style.value : null),
                                        ...(item.color ? {
                                            color: item.color
                                        } : null)
                                    }}>{getValue(item)}</span>
                                    {canEdit && (item.rightContent || (
                                        <img
                                        src={'images/next-arrow-grey-small.png'}
                                        style={{
                                            width: 12,
                                            height: 12,
                                            objectFit: 'contain',
                                            marginLeft: 8,
                                            opacity: 0.75
                                        }} />
                                    ))}
                                </div>
                            )
                        })}
                    </div>
                </LayerItem>
            )
        })
    ) : null;
}
export default FieldMapper;
