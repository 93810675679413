import React from 'react';
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Feedback from 'classes/Feedback.js';
import Lead from 'classes/Lead.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class DemoClass {

    id = null;
    user = null;
    lead = null;
    start_date = null;
    end_date = null;
    timezone = null;
    notes = null;
    status = null;
    booked_by = null;
    ride_along = null;
    trainee = null;
    partner = null;
    created = null;
    feedback_template_id = null;
    feedback_template = null; // internal use only
    cold_leads = null;
    affiliate_link = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.user = props.user ? User.create(props.user) : null;
        this.lead = props.lead ? Lead.create(props.lead) : null;
        this.start_date = props.start_date ? moment(props.start_date) : null;
        this.end_date = props.end_date ? moment(props.end_date) : null;
        this.timezone = props.timezone;
        this.notes = props.notes;
        this.status = props.status;
        this.booked_by = props.booked_by;
        this.ride_along = props.ride_along;
        this.trainee = props.trainee;
        this.partner = props.partner;
        this.created = props.created;
        this.feedback_template_id = props.feedback_template_id;
        this.cold_leads = props.cold_leads;
        this.affiliate_link = props.affiliate_link;

        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                user: this.user,
                start_date: this.start_date,
                end_date: this.end_date,
                partner: this.partner,
                ride_along: this.ride_along,
                trainee: this.trainee,
                feedback_template: this.feedback_template
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                user: props.user || this.edits.user,
                start_date: props.start_date || this.edits.start_date,
                end_date: props.end_date || this.edits.end_date,
                partner: props.partner || this.edits.partner,
                ride_along: props.ride_along || this.edits.ride_along,
                trainee: props.trainee || this.edits.trainee,
                feedback_template: props.feedback_template !== undefined ? props.feedback_template : this.edits.feedback_template
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.user = this.edits.user || this.user;
            this.start_date = this.edits.start_date || this.start_date;
            this.end_date = this.edits.end_date || this.end_date;
            this.partner = this.edits.partner || this.partner;
            this.ride_along = this.edits.ride_along || this.ride_along;
            this.trainee = this.edits.trainee || this.trainee;
            this.feedback_template = this.edits.feedback_template !== undefined ? this.edits.feedback_template : this.feedback_template;
            resolve();
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/demos/', {
                    type: 'update',
                    id: this.id,
                    ...this.toJSON(this.edits)
                })

                await this.close();
                utils.content.update(Abstract.create({
                    type: 'demo',
                    object: this
                }));
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            id: this.id,
            user_id: target.user ? target.user.user_id : null,
            start_date: target.start_date ? moment(target.start_date).format('YYYY-MM-DD HH:mm:ss') : null,
            end_date: target.end_date ? moment(target.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
            partner: target.partner ? target.partner.user_id : null,
            ride_along: target.ride_along ? target.ride_along.user_id : null,
            trainee: target.trainee ? target.trainee.user_id : null,
            feedback_template_id: target.feedback_template ? target.feedback_template.id : null
        }
    }
}

const fetchDemo = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { demo } = await Request.get(utils, '/demos/', {
                type: 'details',
                id: id
            });
            let obj = new DemoClass().create(demo);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    })
}

const statusCodes = {
    pending: -1,
    new: 1,
    set: 100,
    assigned: 125,
    confirmed: 150,
    sale: 200,
    recall: 250,
    do_not_call: 275,
    did_not_sell: 300,
    not_home: 400,
    not_interested: 450,
    incomplete: 500,
    wrong_number: 525,
    left_voicemail: 550,
    rescheduled: 700,
    cancelled: 800,
    turndown: 900,
    unqualified: 1000
};

export default {
    create: props => new DemoClass().create(props),
    get: (utils, id) => fetchDemo(utils, id),
    status: statusCodes,
    styles: {
        status: [{
            key: statusCodes.new,
            title: 'New',
            color: Appearance.colors.lighterGrey,
            status: statusCodes.new
        },{
            key: statusCodes.pending,
            title: 'Pending',
            color: Appearance.colors.lighterGrey,
            status: statusCodes.pending
        },{
            key: statusCodes.confirmed,
            title: 'Confirmed',
            color: Appearance.colors.primary(),
            status: statusCodes.confirmed
        },{
            key: statusCodes.assigned,
            title: 'Assigned',
            color: Appearance.colors.tertiary(),
            status: statusCodes.assigned
        },{
            key: statusCodes.set,
            title: 'Set',
            color: Appearance.colors.secondary(),
            status: statusCodes.set
        },{
            key: statusCodes.sale,
            title: 'Sale',
            color: Appearance.colors.green,
            status: statusCodes.sale
        },{
            key: statusCodes.recall,
            title: 'Recall',
            color: Appearance.colors.lightGrey,
            status: statusCodes.recall
        },{
            key: statusCodes.do_not_call,
            title: 'Do Not Call',
            color: Appearance.colors.darkRed,
            status: statusCodes.do_not_call
        },{
            key: statusCodes.did_not_sell,
            title: 'Did Not Sell',
            color: Appearance.colors.orange,
            status: statusCodes.did_not_sell
        },{
            key: statusCodes.not_home,
            title: 'Not Home',
            color: Appearance.colors.lightGrey,
            status: statusCodes.not_home
        },{
            key: statusCodes.not_interested,
            title: 'Not Interested',
            color: Appearance.colors.darkGrey,
            status: statusCodes.not_interested
        },{
            key: statusCodes.incomplete,
            title: 'Incomplete',
            color: Appearance.colors.yellow,
            status: statusCodes.incomplete
        },{
            key: statusCodes.wrong_number,
            title: 'Wrong Number',
            color: Appearance.colors.lightGrey,
            status: statusCodes.wrong_number
        },{
            key: statusCodes.left_voicemail,
            title: 'Left Voicemail',
            color: Appearance.colors.lightGrey,
            status: statusCodes.left_voicemail
        },{
            key: statusCodes.rescheduled,
            title: 'Rescheduled',
            color: '#7193BB',
            status: statusCodes.rescheduled
        },{
            key: statusCodes.cancelled,
            title: 'Cancelled',
            color: Appearance.colors.red,
            status: statusCodes.cancelled
        },{
            key: statusCodes.turndown,
            title: 'Turndown',
            color: Appearance.colors.darkGrey,
            status: statusCodes.turndown
        },{
            key: statusCodes.unqualified,
            title: 'Unqualified',
            color: Appearance.colors.darkRed,
            status: statusCodes.unqualified
        }]
    }
}
