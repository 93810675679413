import React, { useRef, useState, useEffect, PureComponent } from 'react'

import moment from 'moment';
import update from 'immutability-helper';
import 'react-big-scheduler/lib/css/style.css';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import DatePickerField from 'views/DatePickerField.js';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Scheduler, { SchedulerData, ViewTypes, CellUnits, DATE_FORMAT } from 'react-big-scheduler';
import Views from 'views/Main.js';

const SchedulerComponent = ({ events, minuteStep, onClick, onDateChange, onDelete, onNew, onRenderCallout, onRenderEvent, onRenderResource, onUpdate, options = {}, resources, showAgenda, style, utils, width }) => {

    const [agenda, setAgenda] = useState(false);
    const [targetDate, setTargetDate] = useState(moment());
    const [model, setModel] = useState({
        cal: null,
        timestamp: moment()
    });

    const onEventClick = (cal, evt) => {
        if(typeof(onClick) === 'function') {
            onClick(evt);
        }
    };

    const onDeleteEventClick = evt => {
        if(typeof(onDelete) === 'function') {
            onDelete(evt);
        }
    }

    const onRenderCalloutTemplate = (cal, evt, title, start, end, color) => {
        if(typeof(onRenderCallout) === 'function') {
            return onRenderCallout({
                evt: evt,
                title: title,
                start: start,
                end: end,
                color: color
            });
        }
        return (
            <div style={{
                maxWidth: 250
            }}>
                {Views.entry({
                    title: title,
                    subTitle: `From ${moment(start).format('h:mma')} to ${moment(end).format('h:mma')}`,
                    hideIcon: true,
                    borderBottom: false
                })}
            </div>
        )
    }

    const onRenderEventTemplate = (cal, evt, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
        if(typeof(onRenderEvent) === 'function') {
            return onRenderEvent({
                agenda: agenda,
                evt: evt,
                bgColor: bgColor
            });
        }
        return (
            <div style={{
                backgroundColor: bgColor,
                borderRadius: 25,
                overflow: 'hidden',
                padding: '5px 10px 5px 10px'
            }}>
                <span style={{
                    ...Appearance.textStyles.title(),
                    display: 'block',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    color: 'white'
                }}>{evt.title}</span>
            </div>
        )
    }

    const onRenderResourceTemplate = (cal, slot, slotClickedFunc, width, className) => {
        if(typeof(onRenderResource) === 'function') {
            return onRenderResource({
                agenda: agenda,
                item: slot,
                className: className
            });
        }
        return (
            <div className={className}>
                <span style={{
                    ...Appearance.textStyles.title()
                }}>{slot.slotName}</span>
            </div>
        )
    }

    const onTargetDateChange = date => {

        setTargetDate(date);
        if(typeof(onDateChange) === 'function') {
            onDateChange(date);
        }

        model.cal.setDate(date.format(DATE_FORMAT));
        model.cal.setEvents(events);
        setModel(model => update(model, {
            timestamp: {
                $set: moment()
            }
        }));
    }

    const onViewChange = (cal, view) => {
        cal.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        cal.setEvents(events);
        setModel(model => update(model, {
            cal: {
                $set: cal
            },
            timestamp: {
                $set: moment()
            }
        }));
    }

    const onSelectDate = (cal, date) => {
        cal.setDate(date);
        cal.setEvents(events);
        setModel(model => update(model, {
            cal: {
                $set: cal
            },
            timestamp: {
                $set: moment()
            }
        }));
    }

    const newEvent = (cal, slotId, slotName, start, end, type, item) => {
        if(typeof(onNew) === 'function') {
            onNew({
                id: slotId,
                start: start,
                end: end
            });
        }
    }

    const updateEventStart = (cal, evt, newStart) => {
        if(typeof(onUpdate) === 'function') {
            onUpdate({
                ...evt,
                start: newStart,
            })
        }
        cal.updateEventStart(evt, newStart);
        setModel(model => update(model, {
            cal: {
                $set: cal
            },
            timestamp: {
                $set: moment()
            }
        }));
    }

    const updateEventEnd = (cal, evt, newEnd) => {
        if(typeof(onUpdate) === 'function') {
            onUpdate({
                ...evt,
                end: newEnd
            })
        }
        cal.updateEventEnd(evt, newEnd);
        setModel(model => update(model, {
            cal: {
                $set: cal
            },
            timestamp: {
                $set: moment()
            }
        }));
    }

    const moveEvent = (cal, evt, slotId, slotName, start, end) => {
        if(typeof(onUpdate) === 'function') {
            onUpdate({
                ...evt,
                slotId: slotId,
                start: start,
                end: end
            })
        }
        cal.moveEvent(evt, slotId, slotName, start, end);
        setModel(model => update(model, {
            cal: {
                $set: cal
            },
            timestamp: {
                $set: moment()
            }
        }));
    }

    const isNonWorkingTime = (cal, time) => {
        const { localeMoment } = cal;
        if(cal.cellUnit === CellUnits.Hour){
            let hour = localeMoment(time).hour();
            if(hour < 8 || hour > 20) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {

        let cal = new SchedulerData(moment().format(DATE_FORMAT), ViewTypes.Day, agenda, false, {
            ...options,
            resourceName: 'First and Last Name',
            headerEnabled: false,
            dayCellWidth: 50,
            dayStartFrom: 8,
            dayStopTo: 20,
            minuteStep: minuteStep || 30,
            schedulerWidth: width || '100%',
            dayResourceTableWidth: 200,
            agendaResourceTableWidth: 250,
            eventItemLineHeight: 50,
            scrollToSpecialMomentEnabled: false,
            nonAgendaDayCellHeaderFormat: 'h:mma',
            nonWorkingTimeHeadBgColor: Appearance.colors.divider(),
            nonWorkingTimeBodyBgColor: Appearance.colors.divider(),
            views: []
        }, {
            isNonWorkingTimeFunc: isNonWorkingTime
        });
        cal.localeMoment.locale('en');
        cal.setResources(resources);
        cal.setEvents(events);
        setModel({ cal: cal });

    }, []);

    useEffect(() => {
        if(model.cal) {
            setModel(model => {
                model.cal.showAgenda = agenda;
                model.cal.config.schedulerWidth = width || '100%';
                return update(model, {
                    cal: {
                        $set: model.cal
                    },
                    timestamp: {
                        $set: moment()
                    }
                })
            })
        }
    }, [agenda, width]);

    useEffect(() => {
        if(events && model.cal) {
            model.cal.setEvents(events);
            setModel(model => update(model, {
                timestamp: {
                    $set: moment()
                }
            }))
        }
    }, [events]);

    useEffect(() => {
        if(resources && model.cal) {
            model.cal.setResources(resources);
            setModel(model => update(model, {
                timestamp: {
                    $set: moment()
                }
            }))
        }
    }, [resources]);

    const getContent = () => {
        if(!model || !model.cal) {
            return null;
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                overflow: 'scroll',
                ...style
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <DatePickerField
                    utils={utils}
                    selected={targetDate}
                    onDateChange={onTargetDateChange}
                    style={{
                        flexGrow: 1,
                        marginRight: showAgenda !== false ? 8 : 0
                    }}/>
                </div>
                <Scheduler
                nextClick={() => {}}
                prevClick={() => {}}
                schedulerData={model.cal}
                onSelectDate={onSelectDate}
                onViewChange={onViewChange}
                eventItemClick={onEventClick}
                updateEventStart={updateEventStart}
                updateEventEnd={updateEventEnd}
                moveEvent={moveEvent}
                newEvent={newEvent}
                eventItemTemplateResolver={onRenderEventTemplate}
                slotItemTemplateResolver={onRenderResourceTemplate}
                eventItemPopoverTemplateResolver={onRenderCalloutTemplate} />
            </div>
        )
    }
    return getContent()
}

export default DragDropContext(HTML5Backend)(SchedulerComponent);
