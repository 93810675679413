import React, { useRef, useState, useEffect } from 'react';
import update from 'immutability-helper';

import AddressLookupField from 'views/AddressLookupField.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import DatePickerField from 'views/DatePickerField.js';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import DurationPickerField from 'views/DurationPickerField.js';
import Layer, { FloatingLayerMenuIndex, LayerItem } from 'structure/Layer.js';
import LeadScriptEditor from 'views/LeadScriptEditor.js';
import LeadSubTypePickerField from 'views/LeadSubTypePickerField.js';
import LeadTypePickerField from 'views/LeadTypePickerField.js';
import ListField from 'views/ListField.js';
import PickerField from 'views/PickerField.js';
import LeadLookupField from 'views/LeadLookupField.js';
import TagLookupField from 'views/TagLookupField.js';
import TextField from 'views/TextField.js';
import UserLookupField from 'views/UserLookupField.js';

export const validateRequiredFields = async get => {
    return new Promise((resolve, reject) => {
        let items = get().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined;
        });
        if(required) {
            let error = new Error(`One or more required fields are incomplete. Please fill out the "${required.title.toLowerCase()}" before moving on`);
            reject(error);
            return;
        }
        resolve();
    })
}

const AltFieldMapper = ({ fields, utils }) => {

    const hoverEl = useRef(null);
    const [hover, setHover] = useState({});
    const [hoverSize, setHoverSize] = useState({
        width: 0,
        height: 0
    })

    const onComponentClick = item => {
        switch(item.component) {
            case 'lead_script_editor':
            utils.layer.open({
                id: 'lead_script_editor',
                Component: LeadScriptEditor.bind(this, {
                    utils: utils,
                    ...item
                })
            });
            break;
        }
    }

    const onMouseEnter = (key, evt) => {
        setHover({
            key: key,
            top: evt.target.offsetTop,
            left: evt.target.offsetLeft
        });
    }

    const onMouseLeave = () => {
        setHover({});
        setHoverSize({
            width: 0,
            height: 0
        })
    }

    const getComponent = item => {

        let { address, component, icon, date, items, onChange, onValidate, onRemove, props, title, users, value } = item;
        switch(component) {

            case 'address_lookup':
            return (
                <AddressLookupField
                utils={utils}
                icon={icon}
                value={value}
                geocode={true}
                address={address}
                onChange={place => {
                    if(typeof(onChange) === 'function') {
                        onChange({
                            location: place ? place.location : null,
                            address: place ? {
                                address: place.address,
                                city: place.city,
                                state: place.state,
                                zipcode: place.zipcode,
                                country: place.country
                            } : null
                        });
                    }
                }}
                {...props} />
            )

            case 'bool_toggle':
            return (
                <BoolToggle
                enabled={'Yes'}
                disabled={'No'}
                isEnabled={value}
                onChange={onChange}
                {...props}
                style={{
                    margin: null
                }} />
            );

            case 'date_duration_picker':
            return (
                <DateDurationPickerField
                utils={utils}
                selected={value}
                placeholder={'Choose a date...'}
                onChange={onChange}
                style={{
                    width: '100%'
                }}
                {...props} />
            )

            case 'date_picker':
            return (
                <DatePickerField
                utils={utils}
                icon={icon}
                value={value}
                onValidate={onValidate}
                onDateChange={onChange}
                {...props} />
            )

            case 'date_time_picker':
            return (
                <DateDurationPickerField
                utils={utils}
                icon={icon || 'calendar'}
                selected={value}
                onChange={onChange}
                {...props} />
            )

            case 'duration_picker':
            return (
                <DurationPickerField
                onChange={onChange}
                style={{
                    width: '100%'
                }}
                {...props} />
            )

            case 'lead_type_picker':
            return (
                <LeadTypePickerField
                utils={utils}
                value={value}
                onChange={onChange}
                {...props} />
            )

            case 'lead_script_editor':
            return (
                <div
                className={`dummy-field ${window.theme} text-button`}
                onClick={onComponentClick.bind(this, item)}
                style={{
                    width: '100%',
                    height: 35
                }}>
                    <span style={{
                        flexGrow: 1,
                        borderWidth: 0,
                        fontSize: 12,
                        fontWeight: 500,
                        color: value ? Appearance.colors.text() : Appearance.colors.subText(),
                        backgroundColor: Appearance.colors.transparent,
                    }}>{value ? 'Click to make changes...' : (props && props.placeholder ? props.placeholder : 'No script content added...')}</span>
                </div>
            )

            case 'lead_sub_type_picker':
            return (
                <LeadSubTypePickerField
                utils={utils}
                value={value}
                onChange={onChange}
                {...props} />
            )

            case 'list':
            return (
                <ListField
                items={items}
                value={value}
                onChange={onChange}
                {...props} />
            )

            case 'picker':
            return (
                <PickerField
                icon={icon}
                items={items}
                value={value}
                disableScroll={true}
                onChange={onChange}
                {...props} />
            )

            case 'lead_lookup':
            return (
                <LeadLookupField
                utils={utils}
                value={value}
                onChange={onChange}
                {...props} />
            )

            case 'tag_lookup':
            return (
                <TagLookupField
                utils={utils}
                defaultTags={value}
                onChange={onChange}
                {...props} />
            )

            case 'textfield':
            return (
                <TextField
                icon={icon}
                value={value}
                onValidate={onValidate}
                onChange={onChange}
                {...props} />
            )

            case 'textview':
            return (
                <TextField
                icon={icon}
                value={value}
                expandWithText={true}
                onValidate={onValidate}
                onChange={onChange}
                {...props} />
            )

            case 'user_lookup':
            return (
                <UserLookupField
                icon={icon}
                utils={utils}
                user={value}
                placeholder={'Search by first or last name...'}
                onChange={onChange}
                {...props} />
            )

            default:
            return null;
        }
    }

    useEffect(() => {
        if(hoverEl.current) {
            setHoverSize(props => update(props, {
                width: {
                    $set: hoverEl.current.clientWidth
                },
                height: {
                    $set: hoverEl.current.clientHeight
                }
            }))
        }
    }, [hover, hoverEl.current])

    return fields.filter(section => {
        return section.visible !== false
    }).map((section, index, sections) => {
        return (
            <LayerItem
            key={index}
            title={section.title}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%',
                    marginBottom: 15,
                    padding: 12,
                    paddingTop: 8, // accounts for text line-height
                    overflow: 'visible'
                }}>
                    {section.items.filter(item => {
                        return item.visible !== false;
                    }).map((item, index, items) => {
                        return (
                            <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginBottom: index !== items.length - 1 ? 12 : 0
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    {item.required !== false && !item.value && (
                                        <div style={{
                                            width: 5,
                                            height: 5,
                                            borderRadius: 2.5,
                                            overflow: 'hidden',
                                            backgroundColor: Appearance.colors.red,
                                            marginRight: 8
                                        }} />
                                    )}
                                    <span style={{
                                        ...Appearance.textStyles.subHeader(),
                                        display: 'block',
                                        marginBottom: 4
                                    }}>{item.title}</span>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%',
                                    position: 'relative'
                                }}>
                                    {getComponent(item)}
                                    {item.description && (
                                        <img
                                        className={'text-button'}
                                        src={'images/help-button-grey.png'}
                                        onMouseEnter={onMouseEnter.bind(this, item.key)}
                                        onMouseLeave={onMouseLeave}
                                        style={{
                                            width: 20,
                                            height: 20,
                                            minWidth: 20,
                                            minHeight: 20,
                                            marginLeft: 12,
                                            objectFit: 'contain'
                                        }} />
                                    )}
                                    {hover.key === item.key && (
                                        <div
                                        ref={hoverEl}
                                        style={{
                                            ...Appearance.styles.unstyledPanel(),
                                            position: 'absolute',
                                            top: hoverSize.height ? (hover.top + 10 - (hoverSize.height / 2)) : 0,
                                            left: hoverSize.width ? (hover.left - hoverSize.width - 10) : 0,
                                            padding: '8px 12px 8px 12px',
                                            width: 'auto',
                                            maxWidth: 250,
                                            zIndex: 9999,
                                            textAlign: 'right',
                                            opacity: hoverSize.width && hoverSize.height ? 1 : 0,
                                            boxShadow: window.theme === 'dark' ? '5px 5px 15px rgba(0,0,0,0.25), -5px -5px 15px rgba(85,85,85,0.25)' : '5px 5px 15px rgba(175,175,174,0.25), -5px -5px 15px rgba(255,255,255,0.75)'
                                        }}>
                                            <span style={{
                                                ...Appearance.textStyles.subTitle(),
                                                whiteSpace: 'wrap'
                                            }}>{item.description}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </LayerItem>
        )
    })
}

export default AltFieldMapper;
