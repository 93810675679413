import React from 'react';

import Appearance from 'styles/Appearance.js';
import { CircleProgress } from 'react-gradient-progress';

const RadialProgressCircle = ({ children, colors, size, strokeWidth, style, trackColor, value }) => {
    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            ...(size ? {
                width: size - 60,
                height: size - 60
            } : null),
            ...style
        }}>
            <CircleProgress
            fontSize={'0px'}
            percentage={value}
            strokeWidth={strokeWidth || 30}
            primaryColor={colors}
            secondaryColor={trackColor || Appearance.colors.divider()}
            width={size || 200}/>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: 30
            }}>
                {children}
            </div>
        </div>
    )
}
export default RadialProgressCircle;
